<template>
  <div class="Navigation flex fixed z-3 t0 l0 r0" :style="cssVars">
    <!-- desktop main nav -->
    <nav
      v-if="isDesktop"
      class="Navigation--desktop__top-bar flex-1 flex items-center py1"
      ref="deskTopBar"
    >
      <div class="Navigation--desktop__home-link col-2 flex items-center">
        <router-link
          v-if="!hideInitialHomeLink"
          class="Button--link body serif"
          to="/"
          >Phillida Reid</router-link
        >
      </div>
      <div v-if="!hideLinks" class="flex items-stretch justify-center flex-1">
        <router-link
          v-for="item in mainNavLinks"
          v-bind:key="item.to"
          class="Button--link small uppercase px1 sans-serif"
          v-bind:to="item.to"
        >
          {{ item.label }}
        </router-link>
      </div>
      <div class="col-2">&nbsp;</div>
    </nav>
    <div
      v-if="isDesktop"
      class="Navigation--desktop--scrolled overflow-hidden flex justify-center l0 r0"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
    >
      <div class="Navigation--desktop__home-link col-2 flex items-center">
        <router-link class="Button--link body serif" to="/"
          >Phillida Reid</router-link
        >
      </div>
      <div
        class="Navigation--desktop--scrolled__container flex-1 flex flex-col items-center"
      >
        <img
          ref="deskMenuIcon"
          class="Navigation--desktop--scrolled__icon mt_25 transition-all"
          src="../assets/icon/menu.svg"
        />
        <div
          v-if="!hideLinks"
          class="Navigation--desktop--scrolled__menu transition-all flex items-center justify-center"
        >
          <router-link
            v-for="item in mainNavLinks"
            v-bind:key="item.to"
            class="Button--link small uppercase px1 sans-serif"
            v-bind:to="item.to"
          >
            {{ item.label }}
          </router-link>
        </div>
      </div>
      <div class="col-2">&nbsp;</div>
    </div>

    <div v-if="!isDesktop" class="Navigation--mobile flex flex-col flex-1">
      <div
        class="Navigation--mobile__top-bar border-bottom transition-bg-color flex items-center justify-between wrapper-4"
      >
        <router-link class="Button--link body serif" to="/">
          Phillida Reid
        </router-link>
        <button v-if="!hideLinks" v-on:click="toggleNav">
          <MenuIcon v-bind:isOpen="isOpen" />
        </button>
      </div>
      <div
        class="Navigation--mobile__panel transition-opacity overflow-hidden flex flex-col flex-1"
      >
        <ul v-if="isOpen" class="list-style-none flex flex-col flex-1">
          <li
            class="flex flex-col"
            v-for="item in mainNavLinks"
            v-bind:key="item.to"
          >
            <router-link
              v-bind:to="item.to"
              class="Button--secondary p1 sans-serif"
            >
              {{ item.label }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MenuIcon from "./icons/MenuIcon";

export default {
  name: "Navigation",
  components: {
    MenuIcon,
  },
  props: {
    colorWhileCollapsed: Boolean,
    isTransparent: Boolean,
    hideInitialHomeLink: Boolean,
    hideLinks: Boolean,
    scrolledBg: Boolean,
  },
  data() {
    return {
      isHovering: false,
      scrolled: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScrollOrResize);
    window.addEventListener("resize", this.onScrollOrResize);
    this.scrolled =
      this.isDesktop &&
      this.$refs.deskTopBar &&
      this.$refs.deskTopBar.getBoundingClientRect().bottom < 0;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollOrResize);
    window.removeEventListener("resize", this.onScrollOrResize);
  },
  methods: {
    onScrollOrResize() {
      if (!this.isDesktop || !this.$refs.deskTopBar) return;
      const scrolled = this.$refs.deskTopBar.getBoundingClientRect().bottom < 0;
      if (scrolled && !this.scrolled) {
        this._minimizeDesktopNav();
      } else if (scrolled !== this.scrolled) {
        this._mazimizeDesktopNav();
      }
    },
    toggleNav() {
      this.$store.commit("setMainNavIsOpen", !this.isOpen);
    },
    _minimizeDesktopNav() {
      this.scrolled = true;
      this.$refs.deskMenuIcon.classList.add("animation-slide-up");
    },
    _mazimizeDesktopNav() {
      this.$refs.deskMenuIcon.classList.remove("animation-slide-up");
      this.scrolled = false;
    },
  },
  computed: {
    cssVars() {
      return {
        "--background-color":
          this.isTransparent && this.$mq === "desktop"
            ? "none"
            : this.$mq === "desktop" ||
              (this.$mq === "mobile" &&
                (this.isOpen || this.colorWhileCollapsed))
            ? this.$store.getters.timelapseColor || "white"
            : "white",
        "--navigation-bottom":
          this.$mq === "desktop" || !this.isOpen ? "initial" : 0,
        "--mobile-panel-height": this.isOpen ? 0 : "auto",
        "--mobile-panel-opacity": this.isOpen ? 1 : 0,
        "--desk-top-bar-border-width": this.isTransparent ? "0" : "1px",
        "--desk-scrolled-opacity": this.scrolled ? 1 : 0,
        "--desk-scrolled-height": this.scrolled ? "3.45vw" : 0,
        "--desk-scrolled-bg-color":
          this.scrolledBg ||
          (this.scrolled && !this.isTransparent && this.isHovering)
            ? this.$store.getters.timelapseColor || "white"
            : "transparent",
        "--desk-scrolled-border-color":
          this.scrolledBg ||
          (this.scrolled && !this.isTransparent && this.isHovering)
            ? "rgba(0,0,0,0.2)"
            : "transparent",
        "--desk-scrolled-position": this.scrolled ? "fixed" : "absolute",
        "--desk-scrolled-icon-opacity":
          this.scrolled && !this.isHovering ? 1 : 0,
        "--desk-scrolled-icon-height":
          this.scrolled && !this.isHovering ? "3.45vw" : 0,
        "--desk-scrolled-menu-opacity":
          this.scrolled && this.isHovering ? 1 : 0,
        "--desk-scrolled-menu-height":
          this.scrolled && this.isHovering ? "3.45vw" : 0,
      };
    },
    isDesktop() {
      return this.$mq === "desktop";
    },
    mainNavLinks() {
      return this.$store.getters.mainNavLinks;
    },
    isOpen() {
      return this.$store.getters.mainNavIsOpen;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/styleguide/_colors.scss";
@import "../styles/styleguide/_sizing.scss";
@import "../styles/styleguide/_transitions.scss";
.Navigation {
  bottom: var(--navigation-bottom);
  width: 100vw;

  &--desktop__top-bar {
    height: $desk-nav-top-bar-height;
    background-color: var(--background-color);
    border-bottom-style: solid;
    border-bottom-color: color("black-0_2");
    border-bottom-width: var(--desk-top-bar-border-width);
  }
  &--desktop__home-link {
    height: $desk-nav-top-bar-height;
    padding: 0 20 * $desk-sizing-coefficient;
  }
  &--desktop--scrolled {
    height: var(--desk-scrolled-height);
    opacity: var(--desk-scrolled-opacity);
    position: var(--desk-scrolled-position);
    background-color: var(--desk-scrolled-bg-color);
    transition: opacity map-get($durations, "medium"), background-color 50,
      border-bottom-width map-get($durations, "short");
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--desk-scrolled-border-color);

    &__icon {
      width: $desk-icon-xl;
      opacity: var(--desk-scrolled-icon-opacity);
      height: var(--desk-scrolled-icon-height);

      &-container {
        width: var(--desk-scrolled-icon-ctr-width);
      }
    }
    &__menu {
      opacity: var(--desk-scrolled-menu-opacity);
      height: $desk-nav-top-bar-height;
      width: var(--desk-scrolled-menu-width);
    }
  }
  &--mobile__top-bar {
    height: $mobile-nav-top-bar-height;
    background-color: var(--background-color);
  }
  &--mobile__panel {
    background-color: var(--background-color);
    height: var(--mobile-panel-height);
    opacity: var(--mobile-panel-opacity);
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .Navigation {
    position: absolute;
  }
}
</style>
