import format from "date-fns/format";
import parse from "date-fns/parse";

export function formatDate(dateString) {
  try {
    const date = parse(dateString, "yyyy-MM-dd", new Date());

    return format(date, "d LLLL yyyy");
  } catch (e) {
    console.error(e);
    return "";
  }
}

export function formatRange(start, end) {
  if (!(start && end)) {
    if (start) return formatDate(start);
    if (end) return formatDate(end);
    return "";
  }

  try {
    const startDate = parse(start, "yyyy-MM-dd", new Date());
    const endDate = parse(end, "yyyy-MM-dd", new Date());

    if (
      startDate &&
      endDate &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      return `${format(startDate, "d LLLL")} - ${formatDate(end)}`;
    }

    return `${format(startDate, "d LLLL yyyy")} - ${format(
      endDate,
      "d LLLL yyyy"
    )}`;
  } catch (e) {
    console.error(e);
    return "";
  }
}
