<template>
  <div class="ArtworkPlaque flex flex-col">
    <div>
      <RichText v-if="label" :field="label" class="serif" />
    </div>
    <Button
      v-if="openInNewTab"
      v-bind:class="buttonClassName"
      v-bind:onPress="onPress"
    >
      <div class="py1 md:py1_5 flex">More</div>
    </Button>
    <router-link
      v-else
      v-bind:to="{ path: artworkUrl }"
      v-bind:class="buttonClassName"
    >
      <div class="py1 md:py1_5 flex">More</div>
    </router-link>
  </div>
</template>

<script>
import get from "lodash/get";

import Button from "./Button";
import RichText from "./RichText";

export default {
  name: "ArtworkPlaque",
  components: {
    Button,
    RichText,
  },
  props: {
    artistUid: String,
    details: Array /* prismic rich text */,
    title: String,
    uid: String,
    year: String,
    baseUrl: {
      type: String,
      default: "",
    },
    buttonClassName: {
      type: String,
      default: "Button--tertiary",
    },
    openInNewTab: Boolean || undefined,
  },
  methods: {
    onPress() {
      if (!window) {
        console.debug("[ArtworkPlaque] No window");
        return;
      }
      window.open(`${window.location.origin}/works/${this.uid}`, "_blank");
    },
  },
  computed: {
    artworkUrl() {
      return `${this.baseUrl}/works/${this.uid}`;
    },
    lineOne() {
      const artistDetails = this.$store.getters.artistDetails(this.artistUid);
      return get(artistDetails, "fullName", "");
    },
    lineTwo() {
      if (this.year) {
        return `${this.title}, ${this.year}`;
      }
      return this.title;
    },
    label() {
      return [
        ...(this.lineOne
          ? [
              {
                spans: [],
                text: this.lineOne,
                type: "paragraph",
              },
            ]
          : []),
        {
          spans: [],
          text: this.lineTwo,
          type: "paragraph",
        },
        ...get(this, "details", []),
      ];
    },
  },
};
</script>
