<template>
  <div class="flex flex-1 flex-col" :style="cssVars">
    <ArtistsTable
      v-if="
        data.variant === 'artists' ||
        ($mq === 'mobile' && data.variant === 'artists-mini-mobile')
      "
      v-bind:variant="data.variant"
    />
    <ArtistsMiniDesktop v-else-if="data.variant === 'artists-mini-desktop'" />
    <FilteredUpdatesTable
      v-else-if="data.variant === 'programme'"
      tagName="Programme"
    />
    <FilteredUpdatesTable
      v-else-if="data.variant === 'online programme'"
      tagName="Online"
    />
    <UpdatesTable
      v-else-if="data.variant === 'recent updates'"
      v-bind:variant="data.variant"
    />
    <UpdatesTable
      v-else-if="data.variant === 'updates'"
      v-bind:variant="data.variant"
      isFilterable
      isInfinite
    />
  </div>
</template>

<script>
import ArtistsTable from "./ArtistsTable";
import ArtistsMiniDesktop from "./ArtistsMiniDesktop";
import FilteredUpdatesTable from "./FilteredUpdatesTable";
import UpdatesTable from "./UpdatesTable";
export default {
  name: "Table",
  components: {
    ArtistsTable,
    ArtistsMiniDesktop,
    FilteredUpdatesTable,
    UpdatesTable,
  },
  props: {
    data: {
      variant: String,
      perPage: Number,
      isInfinite: Boolean,
    },
  },
  computed: {
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
        "--timelapse-color-darkest":
          this.$store.getters.timelapseColorDarkest || "#aaa",
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/styleguide/_borders.scss";
@import "../../styles/styleguide/_position.scss";
@import "../../styles/styleguide/_sizing.scss";

.Table {
  background-color: var(--timelapse-color);

  &__hr {
    height: $mobile-table-hr-height;
    &--sm {
      height: $mobile-table-hr-sm-height;
    }
  }
  &__icon {
    height: $desk-icon-xs;
  }
  &__image {
    width: $mobile-table-image-width;
    &-fallback {
      background-color: var(--timelapse-color-darkest);
    }
  }

  &__row {
    @extend .body--table;
    @extend .border-bottom;
    box-sizing: content-box;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: $mobile-table-row-height;

    &:hover {
      background-color: var(--timelapse-color-darkened);
    }

    &--double {
      @extend .Table__row;
    }

    &--linked {
      @extend .Table__row;
      @extend .relative;

      &::after {
        @extend .absolute;
        @extend .rotate-45;
        content: " ";
        background-image: url("../../assets/icon/arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 15 * $mobile-sizing-coefficient;
        height: 15 * $mobile-sizing-coefficient;
        right: 15 * $mobile-sizing-coefficient;
        top: 20 * $mobile-sizing-coefficient;
      }
    }
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .Table {
    &__hr {
      height: $desk-table-hr-height;
      &--sm {
        height: $desk-table-hr-sm-height;
      }
    }
    &__image {
      width: $desk-table-image-width;
    }
    &__row {
      padding-top: 0;
      padding-bottom: 0;
      min-height: initial;
      height: $desk-table-row-height;

      &--linked {
        &::after {
          width: 15 * $desk-sizing-coefficient;
          height: 15 * $desk-sizing-coefficient;
          right: 20 * $desk-sizing-coefficient;
          top: 29 * $desk-sizing-coefficient;
        }
      }
    }
  }
}
</style>
