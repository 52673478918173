/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */

export default function (doc) {
  if (doc.isBroken) {
    return "/not-found";
  }

  if (doc.slug === "home-page") {
    return "/";
  }

  if (doc.type === "generic_page") {
    return "/page/" + doc.uid;
  }

  if (doc.type === "artist") {
    return "/artists/" + doc.uid;
  }

  if (doc.type === "artwork") {
    return "/works/" + doc.uid;
  }

  if (doc.type === "exhibition") {
    return "/exhibitions/" + doc.uid;
  }

  return "/not-found";
}
