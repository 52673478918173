export default `{
  shop_page {
    uid
    title
    artworks {
      artwork {
        title
        year
        details
        images
        artist
        thumbnail
      }
      caption
      width
    }
  }
}`;
