import get from "lodash/get";

export default function (value) {
  if (!Array.isArray(value) || value.length < 1) return false;
  try {
    return value.some((line) => {
      return get(line, "text", "").trim().length > 0;
    });
  } catch (err) {
    console.warn(err);
  }
  return true;
}
