<template>
  <div class="GenericPage flex flex-1 displace-nav flex-col">
    <Navigation
      v-if="!isLoading"
      v-bind:colorWhileCollapsed="colorWhileCollapsed"
      v-bind:scrolledBg="slug === 'update'"
    />
    <h1 class="sr-only">{{ title }}</h1>
    <main v-if="id || isLoading" class="GenericPage__body flex flex-1">
      <BlockSwitch v-if="blocks && blocks.length" v-bind:blocks="blocks" />
    </main>
    <NotFound v-if="!id && !isLoading" class="spacer-bottom-2" />
    <Footer />
  </div>
</template>

<script>
import Cms from "../cms";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import NotFound from "../components/NotFound";

export default {
  name: "GenericPage",
  components: {
    Navigation,
    NotFound,
    Footer,
  },
  props: {
    slug: String,
  },
  data() {
    return {
      id: "",
      isLoading: true,
      title: "",
      blocks: null,
    };
  },
  beforeMount() {
    this.fetchPageContent();
    this.$store.commit("setMainNavIsOpen", false);
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    pageSlug() {
      if (!this.slug) {
        if (this.$route.fullPath === "/") return "home";
      }
      return this.slug || "not-found";
    },
    colorWhileCollapsed() {
      return ["home", "artists", "programme", "update"].includes(this.pageSlug);
    },
  },
  watch: {
    pageSlug: function () {
      /* When the page slug changes, refresh the content */
      this.fetchPageContent();
      this.$store.commit("setMainNavIsOpen", false);
      this.scrollToTop();
    },
  },
  methods: {
    fetchPageContent() {
      this.isLoading = true;
      Cms.fetchPageContent(this.$prismic.client, this.pageSlug, (data) => {
        this.id = data.id;
        this.title = data.title || "";
        this.blocks = data.blocks;
        this.isLoading = false;
      });
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss" scoped>
.GenericPage {
  min-height: 100vh;
  &__body {
    min-height: calc(100vh - 10vw);
  }
}
</style>
