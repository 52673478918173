<template>
  <Button
    v-bind:onPress="onPress"
    v-bind:isActive="isSelected"
    v-bind:activeClassName="activeClassName"
    class="SelectableArtworkCell col-6 flex flex-col items-start justify-end wrapper-1"
  >
    <GentleLoader
      v-if="image"
      v-bind:preloadContent="preloadContent"
      class="SelectableArtworkCell__image-container flex flex-row justify-start"
    >
      <prismic-image class="SelectableArtworkCell__image" :field="image" />
    </GentleLoader>
    <div
      class="SelectableArtworkCell__text-container serif body color-black text-decoration-none text-left mt1"
    >
      <RichText v-if="label" :field="label" />
    </div>
  </Button>
</template>

<script>
import get from "lodash/get";

import Button from "../../Button";
import GentleLoader from "../../loaders/GentleLoader";
import RichText from "../../RichText";

export default {
  name: "SelectableArtworkCell",
  components: {
    Button,
    GentleLoader,
    RichText,
  },
  props: {
    activeClassName: String,
    artwork: {
      artistUid: String,
      title: String,
      year: String,
      details: Array /* prismic rich text */,
      images: Array /* of prismic image field */,
      thumbnail: /* prismic image field */ {
        url: String,
        alt: String,
        dimensions: {
          width: Number,
          height: Number,
        },
      },
    },
    isSelected: Boolean,
    onPress: Function,
  },
  methods: {
    preloadContent(didLoad) {
      if (this.image && this.image.url) {
        const loader = new window.Image();
        loader.src = "";
        loader.onload = didLoad;
        loader.src = this.image.url;
      } else {
        didLoad();
      }
    },
  },
  computed: {
    image() {
      let image = get(this.artwork, "thumbnail");
      if (!image) {
        image = get(this.artwork, "images[0]", {});
      }
      return {
        ...image,
        alt: image.alt || "A photo of the artwork",
      };
    },
    label() {
      const artistDetails = this.$store.getters.artistDetails(
        this.artwork.artistUid
      );
      return [
        {
          spans: [],
          text: get(artistDetails, "fullName", ""),
          type: "paragraph",
        },
        {
          spans: [],
          text: this.artwork.year
            ? `${this.artwork.title}, ${this.artwork.year}`
            : this.artwork.title,
          type: "paragraph",
        },
        ...get(this, "artwork.details", []),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/styleguide/_borders.scss";
@import "../../../styles/styleguide/_colors.scss";
@import "../../../styles/styleguide/_position.scss";
@import "../../../styles/styleguide/_sizing.scss";
.SelectableArtworkCell {
  min-height: $mobile-artwork-cell-height;
  &:nth-child(odd) {
    @extend .border-right;
  }
  &__image {
    max-height: $mobile-artwork-cell-img-height;
    max-width: 100%;
  }
  &__image-container {
    position: relative;
    &::after {
      @extend .circle;
      @extend .bg-color-white;
      opacity: 0;
      transition: opacity 0.125s ease-in-out;
      position: absolute;
      content: url("./plus.svg");
      width: 1rem;
      height: 1rem;
      padding: 0.25rem;
      background-size: cover;
      bottom: $desk-icon-md;
      right: $desk-icon-md;
    }
  }
  &__text-container {
    min-height: $mobile-artwork-cell-text-height;
  }

  &:hover {
    .SelectableArtworkCell__image-container::after {
      opacity: 1;
    }
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .SelectableArtworkCell {
    min-height: $desk-artwork-cell-height;
    width: 25%;
    border-right: 1px solid color("black-0_2");
    &:nth-child(4) {
      border-right: none;
    }
    &__image {
      max-height: $desk-artwork-cell-img-height;
    }
    &__text-container {
      min-height: $desk-artwork-cell-text-height;
    }
  }
}
</style>
