<template>
  <div
    class="UpdatesTable Table border-bottom spacer-bottom-2 flex-1 flex flex-col"
    :style="cssVars"
  >
    <div
      v-if="variant === 'recent updates' && $mq === 'mobile'"
      class="p1 border-bottom spacer-bottom-1"
    >
      <p class="subheading sans-serif">Updates</p>
    </div>
    <!-- Filter Buttons -->
    <div
      v-if="isFilterable && (this.$mq !== 'mobile' || !mainNavIsOpen)"
      class="UpdatesTable__hr Table__hr wrapper-2 flex justify-start md:justify-center items-center l0 r0 z3 overflow-hidden border-bottom"
    >
      <Button
        className="Button--primary px1_25"
        activeClassName="Button--primary--active"
        v-bind:isActive="!filterBy"
        v-bind:onPress="this.clearFilter"
      >
        All
      </Button>
      <Button
        v-for="tag in tags"
        v-bind:key="tag.id"
        className="UpdatesTable__button Button--primary ml_5"
        activeClassName="Button--primary--active ml_5"
        v-bind:isActive="filterBy === tag.id"
        v-bind:onPress="() => setFilter(tag.id)"
      >
        {{ tag.title }}
      </Button>
    </div>

    <!-- Sort Direction Buttons -->
    <div v-if="isFilterable" class="displace-table-hr">
      <span>&nbsp;</span>
    </div>
    <SortByTableHeading
      v-if="$mq === 'desktop' && variant === 'updates'"
      v-bind:setSortBy="setSortBy"
      v-bind:sortBy="sortBy"
      v-bind:sortDirection="sortDirection"
      class="UpdatesTable__hr--sm--fixed Table__hr--sm flex fixed l0 r0 z3 items-stretch border-bottom"
    />
    <!-- Table Body -->
    <div
      class="body flex items-center justify-center spacer-top-2"
      v-if="!isLoading && updates && updates.length === 0"
    >
      No updates at this time.
    </div>
    <div
      class="UpdatesTable__body transition-opacity"
      v-for="update in updates"
      v-bind:key="update.id"
    >
      <ExhibitionUpdateRow v-if="update.exhibitionUid" v-bind:update="update" />
      <UpdateRow
        v-else-if="update.type === 'update' && !!update.link"
        class="Table__row--linked"
        v-bind:update="update"
      />
      <UpdateRow v-else-if="update.type === 'update'" v-bind:update="update" />
      <p v-else>{{ update.title }}</p>
    </div>
    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div class="body mt1" slot="spinner">
        <span v-if="isInfinite && updates.length">Loading...</span>
      </div>
      <div class="body mt1" slot="no-more"></div>
      <div class="body mt1" slot="no-results"></div>
    </infinite-loading>

    <div
      v-if="variant === 'recent updates'"
      class="wrapper-2 flex justify-center md:spacer-top-1"
    >
      <router-link to="/update" class="Button--primary"
        >View All Updates</router-link
      >
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

import Cms from "../../cms";

import Button from "../Button";
import ExhibitionUpdateRow from "./rows/ExhibitionUpdateRow";
import SortByTableHeading from "./rows/SortByTableHeading";
import UpdateRow from "./rows/UpdateRow";

const Attributes = {
  ARTIST: "artists_label",
  TITLE: "title",
  PUBLISHER_OR_VENUE: "publisher_or_venue",
  DATE: "start_date",
};

const DELAY = 300;

export default {
  name: "UpdatesTable",
  components: {
    Button,
    ExhibitionUpdateRow,
    InfiniteLoading,
    UpdateRow,
    SortByTableHeading,
  },
  props: {
    variant: String,
    isFilterable: Boolean,
    isInfinite: Boolean,
  },
  data() {
    return {
      isLoading: true,
      filterBy: null,
      infiniteId: +new Date(),
      page: 0,
      pageSize: 25,
      hasMore: true,
      sortBy: Attributes.DATE,
      sortDirection: -1,
      updates: [],
    };
  },
  computed: {
    cssVars() {
      return {
        "--table-body-opacity": this.isLoading ? 0 : 1,
        "--table-min-height": this.variant === "updates" ? "100vh" : "initial",
        "--table-hr-position":
          this.variant === "updates" ? "fixed" : "relative",
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
      };
    },
    mainNavIsOpen() {
      return this.$store.getters.mainNavIsOpen;
    },
    tags() {
      return this.$store.getters.filterableTags;
    },
  },
  methods: {
    infiniteHandler($state) {
      const didLoad = (data) => {
        setTimeout(() => {
          this.page = data.page;
          this.hasMore = data.hasMore;
          this.updates = this.updates.concat(data.results);
          this.isLoading = false;
          if (data.hasMore && this.variant === "updates") {
            $state.loaded();
          } else if (this.variant === "updates") {
            $state.complete();
          }
        }, DELAY);
      };
      if (this.filterBy) {
        this.fetchUpdatesByTag(this.filterBy, didLoad);
      } else {
        this.fetchUpdates(didLoad);
      }
    },
    fetchUpdates(onLoad) {
      Cms.fetchUpdates(
        this.$prismic.client,
        this.sortBy,
        this.sortDirection,
        this.page + 1,
        this.pageSize,
        onLoad
      );
    },
    fetchUpdatesByTag(id, onLoad) {
      Cms.fetchUpdatesByTag(
        this.$prismic.client,
        id,
        this.sortBy,
        this.sortDirection,
        this.page + 1,
        this.pageSize,
        onLoad
      );
    },
    clearFilter() {
      this.filterBy = null;
      this._reloadContent();
    },
    setFilter(filterBy) {
      this.filterBy = filterBy;
      this._reloadContent();
    },
    setSortBy(sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection > 0) {
          this.sortDirection = -1;
        } else {
          this.sortDirection = 1;
        }
      } else {
        this.sortBy = sortBy;
        this.sortDirection = 1;
      }
      this._reloadContent();
    },
    _reloadContent() {
      this.isLoading = true;
      if (this.isInfinite) {
        this._resetInfiniteScroll();
      } else {
        this.page = 0;
        const didLoad = (data) => {
          setTimeout(() => {
            this.page = data.page;
            this.hasMore = data.hasMore;
            this.updates = data.results;
            this.isLoading = false;
          }, DELAY);
        };
        if (this.filterBy) {
          this.fetchUpdatesByTag(this.filterBy, didLoad);
        } else {
          this.fetchUpdates(didLoad);
        }
      }
    },
    _resetInfiniteScroll() {
      this.page = 0;
      this.updates = [];
      this.infiniteId += 1;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.UpdatesTable {
  min-height: var(--table-min-height);
  &__body {
    opacity: var(--table-body-opacity);
  }
  &__hr {
    top: $mobile-nav-top-bar-height;
    background: var(--timelapse-color);
    position: var(--table-hr-position);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .UpdatesTable {
    &__hr {
      overflow-x: hidden;
      top: $desk-nav-top-bar-height;

      &--sm--fixed {
        top: $desk-nav-top-bar-height + $desk-table-hr-height;
        position: var(--table-hr-position);
        background: var(--timelapse-color);
      }
    }
  }
}
</style>
