import Vue from "vue";
import VueMq from "vue-mq";
import VueRouter from "vue-router";
import PrismicVue from "prismic-vue";
import "es6-promise/auto"; // TODO: confirm if this is needed. https://vuex.vuejs.org/installation.html#promise

import App from "./App.vue";
import htmlSerializer from "./cms/html-serializer";
import linkResolver from "./cms/link-resolver";
import BlockSwitch from "./components/BlockSwitch";
import store from "./store";

Vue.use(VueRouter);
Vue.config.productionTip = false;

Vue.use(PrismicVue, {
  endpoint: process.env.VUE_APP_PRISMIC_ENDPOINT,
  linkResolver,
  htmlSerializer,
});

Vue.use(VueMq, {
  breakpoints: {
    mobile: 770,
    desktop: Infinity,
  },
});

Vue.component("BlockSwitch", BlockSwitch); // Globally register BlockSwitch to enable nested

new Vue({
  el: "#app",
  store: store,
  render: (h) => h(App),
});
