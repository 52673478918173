<template>
  <main
    class="NotFound flex flex-col items-center justify-start spacer-top-1 md:spacer-top-2 md:spacer-bottom-2 border-bottom flex-1"
    :style="cssVars"
  >
    <div class="md:col-4 flex flex-col items-center">
      <ImageLoader
        v-if="notFoundImage && notFoundImage.url"
        v-bind:image="notFoundImage"
        altFallback="An illustration"
        class="col-6 md:col-2"
      />
      <RichText :field="notFoundBody" class="NotFound__body text-center" />
    </div>
  </main>
</template>
<script>
import ImageLoader from "../components/loaders/ImageLoader";
import RichText from "../components/RichText";

export default {
  name: "NotFound",
  components: {
    ImageLoader,
    RichText,
  },
  props: {
    isTransparent: Boolean,
  },
  computed: {
    cssVars() {
      return {
        "--timelapse-color": this.isTransparent
          ? "none"
          : this.$store.getters.timelapseColor || "white",
      };
    },
    notFoundBody() {
      return this.$store.getters.notFoundBody;
    },
    notFoundImage() {
      return this.$store.getters.notFoundImage;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/styleguide/_sizing.scss";
.NotFound {
  background: var(--timelapse-color);
  min-height: 100vh;
}
</style>
