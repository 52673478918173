<template>
  <div class="SortByTableHeading">
    <Button
      class="flex-1 px1"
      aria-label="sort by artist"
      v-bind:onPress="() => setSortBy('artists_label')"
    >
      <TriangleIcon
        class="Table__icon"
        v-bind:isActive="sortBy === 'artists_label'"
        v-bind:isUp="sortBy === 'artists_label' && sortDirection === -1"
      />
    </Button>
    <Button
      class="flex-1 px1"
      aria-label="sort by title"
      v-bind:onPress="() => setSortBy('title')"
    >
      <TriangleIcon
        class="Table__icon"
        v-bind:isActive="sortBy === 'title'"
        v-bind:isUp="sortBy === 'title' && sortDirection === -1"
      />
    </Button>
    <div class="Table__image"><!-- just a spacer --></div>
    <Button
      class="flex-1 px1"
      aria-label="sort by pulisher/venue"
      v-bind:onPress="() => setSortBy('publisher_or_venue')"
    >
      <TriangleIcon
        class="Table__icon"
        v-bind:isActive="sortBy === 'publisher_or_venue'"
        v-bind:isUp="sortBy === 'publisher_or_venue' && sortDirection === -1"
      />
    </Button>
    <Button
      class="flex-1 pl_5 pr1_5"
      aria-label="sort by date"
      v-bind:onPress="() => setSortBy('start_date')"
    >
      <TriangleIcon
        class="Table__icon"
        v-bind:isActive="sortBy === 'start_date'"
        v-bind:isUp="sortBy === 'start_date' && sortDirection === 1"
      />
    </Button>
  </div>
</template>

<script>
import Button from "../../Button";
import TriangleIcon from "../../icons/TriangleIcon";

export default {
  name: "SortByTableHeading",
  components: {
    Button,
    TriangleIcon,
  },
  props: {
    setSortBy: Function,
    sortBy: String,
    sortDirection: Number,
  },
};
</script>
