<template>
  <router-link
    v-bind:to="url"
    class="Table__row flex justify-start items-stretch color-black text-decoration-none"
    :style="cssVars"
    ref="row"
  >
    <ImageLoader
      class="Table__image"
      v-bind:image="thumbnail"
      v-bind:altFallback="`An example of ${fullName}'s work`"
    />
    <div
      class="subtitle--table flex flex-1 items-center pl2"
      @mouseover="onHover"
      @mouseout="isHovering = false"
    >
      <p class="overflow-ellipsis">{{ fullName }}</p>
    </div>
    <div
      v-if="$mq === 'desktop'"
      class="subtitle--table flex items-center col-8 px2 relative"
      @mouseover="onHover"
      @mouseout="isHovering = false"
    >
      <p class="ArtistRow__bio overflow-ellipsis absolute">{{ shortBio }}</p>
      <ImageLoader
        v-if="$mq === 'desktop' && thumbnail && thumbnail.hover"
        ref="featureImage"
        class="ArtistRow__feature-image absolute z-1 l0 overflow-hidden events-none"
        v-bind:image="thumbnail.hover"
        v-bind:altFallback="`An example of ${fullName}'s work`"
        constrainHeight
      />
    </div>
  </router-link>
</template>

<script>
import get from "lodash/get";
import ImageLoader from "../../loaders/ImageLoader";

export default {
  name: "ArtistRow",
  components: {
    ImageLoader,
  },
  data() {
    return {
      isHovering: false,
      translateY: 0,
    };
  },
  props: {
    artist: {
      fullName: String,
      shortBio: String,
      url: String,
      thumbnail: /* prismic image field */ {
        url: String,
        alt: String,
        dimensions: {
          width: Number,
          height: Number,
        },
        hover: /* prismic image field */ {
          url: String,
          alt: String,
          dimensions: {
            width: Number,
            height: Number,
          },
        },
      },
    },
  },
  methods: {
    onHover() {
      this.calcTranslateY();
      this.isHovering = true;
    },
    calcTranslateY() {
      if (!this.$refs.featureImage) return;
      const featureImageHeight =
        this.$refs.featureImage.$el.getBoundingClientRect().height || 0;
      const minimumRowY = featureImageHeight / 2;
      const row = this.$refs.row.$el.getBoundingClientRect();
      const translateY = row.y < minimumRowY ? minimumRowY - row.y : 0;
      this.translateY = translateY;
    },
  },
  computed: {
    fullName() {
      return get(this.artist, "fullName", "");
    },
    shortBio() {
      return get(this.artist, "shortBio", "");
    },
    thumbnail() {
      return get(this.artist, "thumbnail");
    },
    url() {
      return get(this.artist, "url", "");
    },
    cssVars() {
      return {
        "--bio-z-index": this.isHovering ? "2" : "inherit",
        "--feature-image-opacity": this.isHovering ? 1 : 0.1,
        "--feature-image-width": this.isHovering ? "66.66vw" : 0,
        "--feature-image-transform": `translateY(${this.translateY}px)`,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/styleguide/_sizing.scss";
@media (min-width: map-get($breakpoints, "md")) {
  .ArtistRow {
    &__bio {
      z-index: var(--bio-z-index);
    }
    &__feature-image {
      opacity: var(--feature-image-opacity);
      max-width: var(--feature-image-width);
      height: 700 * $desk-sizing-coefficient;
      transform: var(--feature-image-transform);
    }
  }
}
</style>
