<template>
  <div
    class="MobileImageSlide flex flex-col justify-center items-center flex-1"
    :style="cssVars"
    @click="onClick"
  >
    <div v-if="isPlaying" class="MobileImageSlide__wrapper flex items-stretch">
      <div
        class="MobileImageSlide__container flex flex-col items-center justify-center col-12"
      >
        <ImageLoader
          v-if="
            columnData.image && columnData.image.url && !columnData.isFullbleed
          "
          v-bind:image="columnData.image"
          v-bind:isMobile="$mq === 'mobile'"
          class="MobileImageSlide__image--playing flex items-center"
          constrainHeight
        />
      </div>
    </div>
    <div v-else class="MobileImageSlide__wrapper flex items-stretch">
      <div
        class="MobileImageSlide__container flex flex-col items-center col-12"
      >
        <div class="wrapper-2">
          <ImageLoader
            v-bind:image="columnData.image"
            v-bind:isMobile="$mq === 'mobile'"
            v-if="columnData.image && columnData.image.url"
            constrainHeight
            class="MobileImageSlide__image flex mb1_25"
          />
          <ArtworkPlaque
            v-if="columnData.artworkUid"
            v-bind:artistUid="columnData.artistUid"
            v-bind:details="columnData.details"
            v-bind:title="columnData.title"
            v-bind:uid="columnData.artworkUid"
            v-bind:year="columnData.year"
            buttonClassName="Button--tertiary--white"
          />
          <RichText
            class="italic"
            v-else-if="columnData.caption"
            :field="columnData.caption"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArtworkPlaque from "../ArtworkPlaque";
import ImageLoader from "../loaders/ImageLoader";
import RichText from "../RichText";

import parseColumnData from "../../utils/parseColumnData";
const DEFAULT_COLUMN_WIDTH = 12;
const COLUMN_SPACING_X = "";
const COLUMN_SPACING_Y = "";

export default {
  name: "MobileImageSlide",
  components: {
    ArtworkPlaque,
    ImageLoader,
    RichText,
  },
  props: {
    columnNumber: {
      type: Number,
      default: 1,
    },
    data: {
      images: Array,
    },
    isPlaying: Boolean,
    onClick: {
      type: Function,
      default: () => {},
    },
    type: String,
  },
  computed: {
    columnIndex() {
      if (this.columnNumber) return this.columnNumber - 1;
      return 1;
    },
    columnData() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        this.columnIndex
      );
    },
    cssVars() {
      return {
        "--image-height": this.isPlaying ? "auto" : "40vh",
        "--wrapper-height": this.isPlaying ? "80vh" : "60vh",
        "--container-padding": this.columnData.isFullbleed ? "0" : "1rem",
        "--container-bg-image":
          this.columnData.isFullbleed && this.isPlaying
            ? `url(${this.columnData.image.url})`
            : "none",
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.MobileImageSlide {
  height: 100vh;
  background-image: var(--container-bg-image);
  background-position-x: 80%;
  background-repeat: no-repeat;
  background-size: cover;

  &__image {
    margin-bottom: $mobile-margin;
  }
}
</style>
