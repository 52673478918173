<template>
  <div class="ShopPage flex flex-1 displace-nav flex-col">
    <Navigation isTransparent v-if="!artworkUid" />
    <div v-if="artworkUid" class="overlay--fixed z3">
      <ArtworkDetailPage
        v-bind:uid="artworkUid"
        baseUrl="/shop"
        v-bind:isEnquiring="isEnquiring"
      />
    </div>
    <header
      class="ShopPage__header spacer-bottom-2 flex flex-col items-start wrapper-4 subtitle--table"
    >
      <div class="flex flex-col md:flex-row">
        <div class="md:col-4 mb1">
          <p>{{ title }}</p>
        </div>
      </div>
    </header>
    <main v-if="id || isLoading" class="ShopPage__body flex flex-1">
      <BlockSwitch
        v-if="blocks && blocks.length"
        baseUrl="/shop"
        v-bind:blocks="blocks"
      />
    </main>
    <NotFound v-if="!id && !isLoading" class="spacer-bottom-2" />
    <Footer />
  </div>
</template>

<script>
import Cms from "../cms";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import NotFound from "../components/NotFound";
import ArtworkDetailPage from "./ArtworkDetailPage";

export default {
  name: "ShopPage",
  components: {
    Navigation,
    NotFound,
    Footer,
    ArtworkDetailPage,
  },
  props: {
    artworkUid: String,
    isEnquiring: Boolean,
  },
  data() {
    return {
      id: "",
      isLoading: true,
      title: "",
      blocks: null,
    };
  },
  beforeMount() {
    this.fetchPageContent();
    this.$store.commit("setMainNavIsOpen", false);
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {},
  methods: {
    fetchPageContent() {
      this.isLoading = true;
      Cms.fetchShopPage(this.$prismic.client, "shop", (data) => {
        this.id = data.id;
        this.title = data.title || "";
        this.artworks = data.artworks;
        this.blocks = data.blocks;
        this.isLoading = false;
      });
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss" scoped>
.ShopPage {
  min-height: 100vh;
  &__body {
    min-height: calc(100vh - 10vw);
  }
}
</style>
