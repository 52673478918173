<template>
  <div
    class="PressUpdatesTable Table border-bottom spacer-bottom-2 flex flex-col"
    :style="cssVars"
  >
    <div v-if="updates && updates.length > 0" class="wrapper-3 border-bottom">
      <p class="detail sans-serif uppercase py_25">Press</p>
    </div>

    <!-- Table Body -->
    <div
      class="UpdatesTable__body transition-opacity"
      v-for="update in updates"
      v-bind:key="update.id"
    >
      <UpdateRow
        v-if="update.type === 'update' && !!update.link"
        class="Table__row--linked"
        v-bind:update="update"
      />
      <UpdateRow v-else-if="update.type === 'update'" v-bind:update="update" />
      <p v-else>{{ update.title }}</p>
    </div>
  </div>
</template>

<script>
import UpdateRow from "./rows/UpdateRow";

export default {
  name: "PressUpdatesTable",
  components: {
    UpdateRow,
  },
  props: {
    isLoading: Boolean,
    updates: Array,
  },
  computed: {
    cssVars() {
      return {
        "--table-body-opacity": this.isLoading ? 0 : 1,
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.Table {
  background-color: var(--timelapse-color);
  &__row:hover {
    background-color: var(--timelapse-color-darkened);
  }
}
.PressUpdatesTable {
  &__body {
    opacity: var(--table-body-opacity);
  }
}
</style>
