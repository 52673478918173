<template>
  <div class="VideoPlayer relative">
    <div class="VideoPlayer__wrapper">
      <video class="VideoPlayer__video" ref="videoElement" autoplay muted>
        <source
          v-for="source in data.sources"
          v-bind:key="source.key"
          v-bind:src="source.url"
          v-bind:type="`video/${source.dataType}`"
        />
        Your browser does not support the video tag.
      </video>
      <div class="overlay flex flex-col justify-center">
        <button
          v-if="isPaused && $mq === 'desktop'"
          class="VideoPlayer__button flex-1"
          @click="onPlay"
          v-bind:disabled="!isLoaded"
        >
          <img
            class="VideoPlayer__icon"
            src="../assets/icon/play-circled-white.svg"
          />
        </button>
        <button
          v-else-if="$mq === 'desktop'"
          class="VideoPlayer__button flex-1"
          @click="onPause"
          v-bind:disabled="!isLoaded"
        >
          <img
            class="VideoPlayer__icon"
            src="../assets/icon/pause-circled-white.svg"
          />
        </button>
        <button
          v-else
          class="VideoPlayer__button--visible flex-1"
          @click="onToggleMobile"
        >
          <img
            v-if="isPaused"
            class="VideoPlayer__icon"
            src="../assets/icon/play-circled-white.svg"
          />
        </button>
        <div v-if="isLoaded && $mq === 'desktop'" class="flex justify-center">
          <Button
            class="VideoPlayer__button Button--unmute mb_5"
            v-if="isMuted"
            v-bind:onPress="onToggleMuted"
          >
            Sound On
          </Button>
          <Button
            class="VideoPlayer__button Button--unmute mb_5"
            v-else
            v-bind:onPress="onToggleMuted"
          >
            Sound Off
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button";

const SNAPSHOT_INTERVAL = 0.125 * 1000;

export default {
  name: "VideoPlayer",
  components: {
    Button,
  },
  props: {
    data: {
      title: String,
      sources: {
        type: Array,
        default: [],
      },
    },
    onLoad: Function,
    onVideoPlayerStateChanged: Function,
  },
  mounted() {
    this.preloadVideo();
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    this.onPause();
  },
  data() {
    return {
      isLoaded: false,
      isPaused: false,
      isMuted: true,
      currentTime: 0,
      duration: 0,
      snapshotInterval: null,
    };
  },
  methods: {
    preloadVideo: function () {
      if (this.data && this.data.sources) {
        const loader = document.createElement("video");
        let source = this.data.sources[0];
        loader.src = source.url;
        loader.onloadedmetadata = () => {
          this.isLoaded = true;
          this.onLoad();
          this.onPlay();
        };
      }
    },
    _takeVideoPlayerSnapshot() {
      const videoElement = this.$refs.videoElement;
      if (videoElement) {
        this.isMuted = videoElement.muted;
        this.isPaused = videoElement.paused;
        this.duration = videoElement.duration;
        this.currentTime = videoElement.currentTime;

        if (this.currentTime === this.duration) {
          // restart
          this._clearSnapshotInterval();
          setTimeout(() => {
            videoElement.currentTime = 0;
            this.onPlay();
          }, SNAPSHOT_INTERVAL);
        }
      } else {
        this.isMuted = true;
        this.isPaused = true;
        this.currentTime = 0;
        this.duration = 0;
        this._clearSnapshotInterval();
      }

      this.onVideoPlayerStateChanged({
        isPaused: this.isPaused,
        currentTime: this.currentTime,
        duration: this.duration,
      });
    },
    _scheduleSnapshotInterval() {
      this.snapshotInterval = setInterval(
        this._takeVideoPlayerSnapshot,
        SNAPSHOT_INTERVAL
      );
    },
    _clearSnapshotInterval() {
      if (this.snapshotInterval) clearInterval(this.snapshotInterval);
    },
    onToggle() {
      if (this.isPaused) {
        this.onPlay();
      } else {
        this.onPause();
      }
    },
    onToggleMobile() {
      if (this.isPaused) {
        this.onUnmute();
        this.onPlay();
      } else {
        this.onMute();
        this.onPause();
      }
    },
    onPlay() {
      this.$refs.videoElement.play();
      this._takeVideoPlayerSnapshot();
      this._scheduleSnapshotInterval();
    },
    onPause() {
      this.$refs.videoElement.pause();
      this._takeVideoPlayerSnapshot();
      this._clearSnapshotInterval();
    },
    onToggleMuted() {
      const muted = this.$refs.videoElement.muted;
      if (muted) {
        this.onUnmute();
      } else {
        this.onMute();
      }
    },
    onMute() {
      this.$refs.videoElement.muted = true;
      this._takeVideoPlayerSnapshot();
    },
    onUnmute() {
      this.$refs.videoElement.muted = false;
      this._takeVideoPlayerSnapshot();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_position.scss";
@import "../styles/styleguide/_sizing.scss";
@import "../styles/styleguide/_transitions.scss";

.VideoPlayer {
  width: 100vw;
  &__wrapper {
    width: 100vw;
  }
  &__video {
    width: 100vw;
  }

  &__icon {
    width: 90 * $mobile-sizing-coefficient;
    height: 90 * $mobile-sizing-coefficient;
  }

  &__button {
    opacity: 0;
    @extend .transition-opacity;

    &--visible {
      opacity: 1;
    }
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .VideoPlayer {
    width: auto;
    height: $desk-video-player-height;

    &__wrapper {
      width: auto;
      height: $desk-video-player-height;
    }
    &__video {
      width: auto;
      height: $desk-video-player-height;
    }

    &__icon {
      width: 90 * $desk-sizing-coefficient;
      height: 90 * $desk-sizing-coefficient;
    }

    &:hover {
      .VideoPlayer__button {
        opacity: 1;
      }
    }
  }
}
</style>
