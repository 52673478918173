<template>
  <div
    class="SingleColumnBlock flex flex-col justify-center items-center container"
    v-bind:data-title="data.title"
  >
    <ArtworkBlock
      v-if="columnOne.artworkUid"
      v-bind:artistUid="columnOne.artistUid"
      v-bind:baseUrl="baseUrl"
      v-bind:class="columnOne.colClass"
      v-bind:caption="columnOne.caption"
      v-bind:captionClass="columnOne.captionClass"
      v-bind:details="columnOne.details"
      v-bind:image="columnOne.image"
      v-bind:title="columnOne.title"
      v-bind:uid="columnOne.artworkUid"
      v-bind:year="columnOne.year"
      v-bind:onSelect="onSelectArtwork"
    />
    <ImageBlock
      v-else
      v-bind:image="columnOne.image"
      v-bind:caption="columnOne.caption"
      v-bind:class="columnOne.colClass"
      v-bind:captionClass="columnOne.captionClass"
    />
  </div>
</template>

<script>
import ArtworkBlock from "./ArtworkBlock";
import ImageBlock from "./ImageBlock";

import parseColumnData from "../utils/parseColumnData";

const DEFAULT_COLUMN_WIDTH = 12;
const COLUMN_SPACING_X = "wrapper-4";
const COLUMN_SPACING_Y = "spacer-bottom-2";

export default {
  name: "SingleColumnBlock",
  components: {
    ArtworkBlock,
    ImageBlock,
  },
  props: {
    data: {
      title: String,
      images: Array,
    },
    baseUrl: String,
    onSelectArtwork: Function,
  },
  computed: {
    columnOne() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        0
      );
    },
  },
};
</script>
