export default {
  query: '[any(document.type,["artist"])]',
  options: `{
    artist {
      uid
      cv
      first_name
      last_name
      short_bio
      thumbnail
    }
  }`,
};
