<template>
  <div
    class="FilteredUpdatesTable Table border-bottom spacer-bottom-2 flex flex-1 flex-col"
    :style="cssVars"
    ref="table"
  >
    <!-- Sort Direction Buttons -->
    <div
      v-if="$mq === 'desktop'"
      class="Table__hr--sm flex items-stretch border-bottom"
    >
      <Button
        class="flex-1 px1"
        aria-label="sort by artist"
        v-bind:onPress="() => _setSortBy('artists_label')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'artists_label'"
          v-bind:isUp="sortBy === 'artists_label' && sortDirection === -1"
        />
      </Button>
      <Button
        class="flex-1 px1"
        aria-label="sort by title"
        v-bind:onPress="() => _setSortBy('title')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'title'"
          v-bind:isUp="sortBy === 'title' && sortDirection === -1"
        />
      </Button>
      <div class="Table__image"><!-- just a spacer --></div>
      <Button
        class="flex-1 px1"
        aria-label="sort by pulisher/venue"
        v-bind:onPress="() => _setSortBy('publisher_or_venue')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'publisher_or_venue'"
          v-bind:isUp="sortBy === 'publisher_or_venue' && sortDirection === -1"
        />
      </Button>
      <Button
        class="flex-1 pl_5 pr1_5"
        aria-label="sort by date"
        v-bind:onPress="() => _setSortBy('start_date')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'start_date'"
          v-bind:isUp="sortBy === 'start_date' && sortDirection === 1"
        />
      </Button>
    </div>

    <!-- Table Body -->
    <p
      class="body flex flex-1 justify-center spacer-top-1 spacer-bottom-1"
      v-if="!isLoading && updates && updates.length === 0"
    >
      No programme updates at this time.
    </p>
    <div
      class="UpdatesTable__body transition-opacity"
      v-for="update in updates"
      v-bind:key="update.id"
    >
      <ExhibitionUpdateRow v-if="update.exhibitionUid" v-bind:update="update" />
      <UpdateRow
        v-else-if="update.type === 'update' && !!update.link"
        class="Table__row--linked"
        v-bind:update="update"
      />
      <UpdateRow v-else-if="update.type === 'update'" v-bind:update="update" />
      <p v-else>{{ update.title }}</p>
    </div>
    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div slot="spinner"><span v-if="!isLoading">Loading...</span></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import get from "lodash/get";
import InfiniteLoading from "vue-infinite-loading";

import Button from "../Button";
import Cms from "../../cms";
import ExhibitionUpdateRow from "./rows/ExhibitionUpdateRow";
import UpdateRow from "./rows/UpdateRow";
import TriangleIcon from "../icons/TriangleIcon";

export default {
  name: "FilteredUpdatesTable",
  components: {
    Button,
    ExhibitionUpdateRow,
    InfiniteLoading,
    UpdateRow,
    TriangleIcon,
  },
  props: {
    tagName: String,
  },
  data() {
    return {
      infiniteId: +new Date(),
      isLoading: true,
      page: 0,
      pageSize: 25,
      sortBy: "start_date",
      sortDirection: -1,
      updates: [],
    };
  },
  methods: {
    fetchUpdates(onLoad) {
      if (!this.tagId) return;
      Cms.fetchUpdatesByTag(
        this.$prismic.client,
        this.tagId,
        this.sortBy,
        this.sortDirection,
        this.page + 1,
        this.pageSize,
        onLoad
      );
    },
    infiniteHandler($state) {
      const didLoad = (data) => {
        this.page = data.page;
        this.perPage = data.perPage;
        this.hasMore = data.hasMore;
        this.updates = this.updates.concat(data.results);
        this.isLoading = false;
        if (data.hasMore) {
          $state.loaded();
        } else {
          $state.complete();
        }
      };
      if (this.filterBy) {
        this.fetchUpdatesByTag(this.filterBy, didLoad);
      } else {
        this.fetchUpdates(didLoad);
      }
    },
    setSortBy(sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection > 0) {
          this.sortDirection = -1;
        } else {
          this.sortDirection = 1;
        }
      } else {
        this.sortBy = sortBy;
        this.sortDirection = 1;
      }
      this._resetInfiniteScroll();
    },
    _setSortBy(param) {
      this.setSortBy(param);
    },
    _resetInfiniteScroll() {
      this.page = 0;
      this.updates = [];
      this.infiniteId += 1;
      if (this.$refs.table) {
        this.$refs.table.scrollIntoView(true);
      }
    },
  },
  watch: {
    tagId: function () {
      this._resetInfiniteScroll();
    },
  },
  computed: {
    cssVars() {
      return {
        "--table-body-opacity": this.isLoading ? 0 : 1,
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
    tagId() {
      return get(this.$store.getters.tagByName(this.tagName), "id");
    },
  },
};
</script>

<style lang="scss">
.Table {
  background-color: var(--timelapse-color);
  &__row:hover {
    background-color: var(--timelapse-color-darkened);
  }
}
.FilteredUpdatesTable {
  &__body {
    opacity: var(--table-body-opacity);
  }
}
</style>
