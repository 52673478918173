<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import VueRouter from "vue-router";

import ArtistPage from "./pages/ArtistPage.vue";
import ArtworkDetailPage from "./pages/ArtworkDetailPage.vue";
import ContactPage from "./pages/ContactPage.vue";
import ExhibitionPage from "./pages/ExhibitionPage.vue";
import GenericPage from "./pages/GenericPage.vue";
import HeroTestPage from "./pages/HeroTestPage.vue";
import PolicyPage from "./pages/PolicyPage";
import PrivateViewingPage from "./pages/PrivateViewingPage.vue";
import ShopPage from "./pages/ShopPage.vue";

const FIVE_MINUTES = 5 * 60 * 1000;
const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: GenericPage },
    { path: "/artists/:uid", component: ArtistPage, props: true },
    {
      path: "/artists/:uid/works/:artworkUid",
      component: ArtistPage,
      props: (route) => ({
        ...route.params,
        showArtwork: true,
      }),
    },
    {
      path: "/artists/:uid/works/:artworkUid/enquire",
      component: ArtistPage,
      props: (route) => ({
        ...route.params,
        showArtwork: true,
        isEnquiring: true,
      }),
    },
    { path: "/contact", component: ContactPage },
    { path: "/exhibitions/:uid", component: ExhibitionPage, props: true },
    {
      path: "/exhibitions/:uid/slideshow",
      component: ExhibitionPage,
      props: (route) => ({
        ...route.params,
        showSlideshow: true,
        slide: route.query.slide,
        ...(route.query.mobile ? { mobile: route.query.mobile } : {}),
        ...(route.query.columnNumber
          ? { columnNumber: route.query.columnNumber }
          : {}),
      }),
    },
    {
      path: "/exhibitions/:uid/works/:artworkUid",
      component: ExhibitionPage,
      props: (route) => ({
        ...route.params,
        showArtwork: true,
      }),
    },
    {
      path: "/exhibitions/:uid/works/:artworkUid/enquire",
      component: ExhibitionPage,
      props: (route) => ({
        ...route.params,
        isEnquiring: true,
        showArtwork: true,
      }),
    },
    { path: "/privacy-policy", component: PolicyPage },
    {
      path: "/private-viewings/:uid",
      component: PrivateViewingPage,
      props: true,
    },
    {
      path: "/private-viewings/:uid/slideshow",
      component: PrivateViewingPage,
      props: (route) => ({
        ...route.params,
        showSlideshow: true,
        slide: route.query.slide,
        ...(route.query.mobile ? { mobile: route.query.mobile } : {}),
        ...(route.query.columnNumber
          ? { columnNumber: route.query.columnNumber }
          : {}),
      }),
    },
    {
      path: "/private-viewings/:uid/works/:artworkUid",
      component: PrivateViewingPage,
      props: (route) => ({
        ...route.params,
        showArtwork: true,
      }),
    },
    {
      path: "/works/:uid",
      component: ArtworkDetailPage,
      props: true,
    },
    {
      path: "/works/:uid/enquire",
      component: ArtworkDetailPage,
      props: (route) => ({
        ...route.params,
        isEnquiring: true,
      }),
    },
    { path: "/shop", component: ShopPage, props: true },
    {
      path: "/shop/works/:artworkUid",
      component: ShopPage,
      props: true,
    },
    {
      path: "/shop/works/:artworkUid/enquire",
      component: ShopPage,
      props: (route) => ({
        ...route.params,
        isEnquiring: true,
      }),
    },
    { path: "/admin/reference/hero", component: HeroTestPage },
    { path: "/:slug", component: GenericPage, props: true },
    { path: "*", component: GenericPage, props: true },
  ],
});

export default {
  name: "App",
  router,
  beforeMount() {
    this.fetchGlobalSettings();
    this.fetchArtists();
  },
  created() {
    this.initColorTimelapse();
    this.fetchTags();
    this.fetchVenues();
  },
  beforeDestroy() {
    clearInterval(this.colorTimelapse);
  },
  data() {
    return {
      colorTimelapse: null, // TODO: move to store?
    };
  },
  methods: {
    initColorTimelapse() {
      this.$store.commit("updateTimelapseColor");
      this.colorTimelapse = setInterval(() => {
        this.$store.commit("updateTimelapseColor");
      }, FIVE_MINUTES);
    },
    fetchGlobalSettings() {
      this.$store.commit("fetchAndPersistGlobalSettings", this.$prismic.client);
    },
    fetchArtists() {
      this.$store.commit("fetchAndPersistArtists", this.$prismic.client);
    },
    fetchTags() {
      this.$store.commit("fetchAndPersistTags", this.$prismic.client);
    },
    fetchVenues() {
      this.$store.commit("fetchAndPersistVenues", this.$prismic.client);
    },
  },
};
</script>

<style lang="scss">
@import "./styles/index.scss";
#app {
  @extend .flex;
}
</style>
