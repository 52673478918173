<template>
  <div
    class="TripleImageSlide flex justify-center items-center py1 px1 flex-1"
    :style="cssVars"
  >
    <div
      v-if="isPlaying"
      class="TripleImageSlide__wrapper flex items-stretch"
      @click="onClick"
    >
      <div
        class="TripleImageSlide__container flex flex-col items-center justify-center col-4 pl2 pr1"
      >
        <ImageLoader
          v-bind:image="columnOne.image"
          v-if="columnOne.image && columnOne.image.url"
          v-bind:class="`TripleImageSlide__image--playing flex items-center ${columnOne.colClass}`"
          constrainHeight
        />
      </div>
      <div
        class="TripleImageSlide__container flex flex-col items-center justify-center col-4 px1"
      >
        <ImageLoader
          v-bind:image="columnTwo.image"
          v-if="columnTwo.image && columnTwo.image.url"
          v-bind:class="`TripleImageSlide__image--playing flex items-center ${columnTwo.colClass}`"
          constrainHeight
        />
      </div>
      <div
        class="TripleImageSlide__container flex flex-col items-center justify-center col-4 pl1 pr2"
      >
        <ImageLoader
          v-bind:image="columnThree.image"
          v-if="columnThree.image && columnThree.image.url"
          v-bind:class="`TripleImageSlide__image--playing flex items-center ${columnThree.colClass}`"
          constrainHeight
        />
      </div>
    </div>
    <div
      v-else
      class="TripleImageSlide__wrapper flex items-stretch justify-center"
      @click="onClick"
    >
      <div class="TripleImageSlide__container flex flex-col wrapper-1">
        <div class="pl2">
          <ImageLoader
            v-bind:image="columnOne.image"
            v-if="columnOne.image && columnOne.image.url"
            constrainHeight
            class="TripleImageSlide__image flex justify-end mb1_25"
          />
          <ArtworkPlaque
            v-if="columnOne.artworkUid"
            v-bind:artistUid="columnOne.artistUid"
            v-bind:details="columnOne.details"
            v-bind:title="columnOne.title"
            v-bind:uid="columnOne.artworkUid"
            v-bind:year="columnOne.year"
            buttonClassName="Button--tertiary--white"
            v-bind:openInNewTab="true"
          />
          <RichText
            class="italic mt1"
            v-else-if="columnOne.caption"
            :field="columnOne.caption"
          />
        </div>
      </div>
      <div class="TripleImageSlide__container flex flex-col wrapper-1">
        <div class="px1">
          <ImageLoader
            v-bind:image="columnTwo.image"
            v-if="columnTwo.image && columnTwo.image.url"
            constrainHeight
            class="TripleImageSlide__image flex justify-end mb1_25"
          />
          <ArtworkPlaque
            v-if="columnTwo.artworkUid"
            v-bind:artistUid="columnTwo.artistUid"
            v-bind:details="columnTwo.details"
            v-bind:title="columnTwo.title"
            v-bind:uid="columnTwo.artworkUid"
            v-bind:year="columnTwo.year"
            buttonClassName="Button--tertiary--white"
            v-bind:openInNewTab="true"
          />
          <RichText
            class="italic mt1"
            v-else-if="columnTwo.caption"
            :field="columnTwo.caption"
          />
        </div>
      </div>
      <div class="TripleImageSlide__container flex flex-col wrapper-1">
        <div class="pr2">
          <ImageLoader
            v-bind:image="columnThree.image"
            v-if="columnThree.image && columnThree.image.url"
            constrainHeight
            class="TripleImageSlide__image flex justify-end mb1_25"
          />
          <ArtworkPlaque
            v-bind:artistUid="columnThree.artistUid"
            v-if="columnThree.artworkUid"
            v-bind:details="columnThree.details"
            v-bind:title="columnThree.title"
            v-bind:uid="columnThree.artworkUid"
            v-bind:year="columnThree.year"
            buttonClassName="Button--tertiary--white"
            v-bind:openInNewTab="true"
          />
          <RichText
            class="italic mt1"
            v-else-if="columnThree.caption"
            :field="columnThree.caption"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLoader from "../loaders/ImageLoader";
import ArtworkPlaque from "../ArtworkPlaque";
import RichText from "../RichText";

import parseColumnData from "../../utils/parseColumnData";
const DEFAULT_COLUMN_WIDTH = 4;
const COLUMN_SPACING_X = "px1";
const COLUMN_SPACING_Y = "";
export default {
  name: "TripleImageSlide",
  components: {
    ArtworkPlaque,
    ImageLoader,
    RichText,
  },
  props: {
    isPlaying: Boolean,
    type: String,
    data: {
      images: Array,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    columnOne() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        0
      );
    },
    columnTwo() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        1
      );
    },
    columnThree() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        2
      );
    },
    cssVars() {
      return {
        "--image-height": this.isPlaying ? "auto" : "40vh",
        "--wrapper-height": this.isPlaying ? "80vh" : "60vh",
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.TripleImageSlide {
  height: 100vh;

  &__wrapper {
    height: var(--wrapper-height);
  }
  &__image {
    height: var(--image-height);
    max-width: 100%;
  }
  &__image--playing {
    max-width: 100%;
  }
}
</style>
