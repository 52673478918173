export function placeholderImage(width, height) {
  return `http://placekitten.com/${width}/${height}`;
}

export function prismicDummyImage(width, height) {
  return {
    url: placeholderImage(width, height),
    dimensions: { height, width },
  };
}
