<template>
  <router-link
    v-bind:to="artworkUrl"
    class="ArtworkCell col-6 flex flex-col items-start justify-end wrapper-1 serif body color-black text-decoration-none text-left"
  >
    <GentleLoader
      v-if="image"
      v-bind:preloadContent="preloadContent"
      class="ArtworkCell__image-container"
    >
      <prismic-image
        v-bind:class="
          image.isLandscape
            ? 'ArtworkCell__image--landscape'
            : 'ArtworkCell__image'
        "
        :field="image"
      />
    </GentleLoader>
    <div class="ArtworkCell__text-container mt1">
      <RichText v-if="label" :field="label" />
    </div>
  </router-link>
</template>

<script>
import get from "lodash/get";

import GentleLoader from "../../loaders/GentleLoader";
import RichText from "../../RichText";

export default {
  name: "ArtworkCell",
  components: {
    GentleLoader,
    RichText,
  },
  props: {
    artwork: {
      artistUid: String,
      title: String,
      year: String,
      details: Array /* prismic rich text */,
      images: Array /* of prismic image field */,
      thumbnail: /* prismic image field */ {
        url: String,
        alt: String,
        dimensions: {
          width: Number,
          height: Number,
        },
      },
    },
    baseUrl: String,
  },
  methods: {
    preloadContent(didLoad) {
      if (this.image && this.image.url) {
        const loader = new window.Image();
        loader.src = "";
        loader.onload = didLoad;
        loader.src = this.image.url;
      } else {
        didLoad();
      }
    },
  },
  computed: {
    _baseUrl() {
      if (this.baseUrl) return this.baseUrl;
      return "";
    },
    artworkUrl() {
      return `${this._baseUrl}/works/${get(this.artwork, "uid")}`;
    },
    image() {
      let image = get(this.artwork, "thumbnail");
      if (!image) {
        image = get(this.artwork, "images[0]", {});
      }
      return {
        ...image,
        alt: image.alt || "A photo of the artwork",
        isLandscape:
          get(image, "dimensions.width", 0) >
          get(image, "dimensions.height", 0),
      };
    },
    label() {
      const artistDetails = this.$store.getters.artistDetails(
        this.artwork.artistUid
      );
      return [
        {
          spans: [],
          text: get(artistDetails, "fullName", ""),
          type: "paragraph",
        },
        {
          spans: [],
          text: this.artwork.year
            ? `${this.artwork.title}, ${this.artwork.year}`
            : this.artwork.title,
          type: "paragraph",
        },
        ...get(this, "artwork.details", []),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/styleguide/_borders.scss";
@import "../../../styles/styleguide/_colors.scss";
@import "../../../styles/styleguide/_position.scss";
@import "../../../styles/styleguide/_sizing.scss";
.ArtworkCell {
  min-height: $mobile-artwork-cell-height;
  &:nth-child(odd) {
    @extend .border-right;
  }
  &__image {
    max-height: $mobile-artwork-cell-img-height;
    max-width: 100%;
  }
  &__image--landscape {
    max-height: $mobile-artwork-cell-img-height;
    width: $mobile-artwork-cell-img-width;
  }
  &__image-container {
    position: relative;
    &::after {
      @extend .circle;
      @extend .bg-color-white;
      opacity: 0;
      transition: opacity 0.125s ease-in-out;
      position: absolute;
      content: url("./plus.svg");
      width: 1rem;
      height: 1rem;
      padding: 0.25rem;
      background-size: cover;
      bottom: $desk-icon-md;
      right: $desk-icon-md;
    }
  }
  &__text-container {
    min-height: $mobile-artwork-cell-text-height;
  }

  &:hover {
    .ArtworkCell__image-container::after {
      opacity: 1;
    }
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ArtworkCell {
    min-height: $desk-artwork-cell-height;
    width: calc(100% / 6);
    border-right: 1px solid color("black-0_2");
    &:nth-child(1) {
      padding-left: 3 * $desk-margin;
    }
    &:nth-child(6) {
      border-right: none;
      padding-right: 3 * $desk-margin;
    }
    &__image {
      max-height: $desk-artwork-cell-img-height;
    }
    &__image--landscape {
      max-height: $desk-artwork-cell-img-height;
      width: $desk-artwork-cell-img-width;
    }
    &__text-container {
      min-height: $desk-artwork-cell-text-height;
    }
  }
}
</style>
