<template>
  <form
    class="NewsletterSignupForm flex flex-col"
    v-bind:action="url"
    ref="form"
    v-on:submit="onSubmit"
    method="post"
    id="mc-embedded-subscribe-form"
    name="mc-embedded-subscribe-form"
    :style="cssVars"
  >
    <div v-if="!showSuccessMessage" class="flex flex-col">
      <label for="FNAME" class="Form__field flex flex-col">
        <span class="sr-only">First Name</span>
        <input
          name="FNAME"
          class="Form__input--sm"
          v-model="FNAME.value"
          v-bind:disabled="isMakingRequest"
          placeholder="First Name"
        />
      </label>
      <label for="LNAME" class="Form__field flex flex-col">
        <span class="sr-only">Last Name</span>
        <input
          name="LNAME"
          class="Form__input--sm"
          v-model="LNAME.value"
          v-bind:disabled="isMakingRequest"
          placeholder="Last Name"
        />
      </label>
      <label for="EMAIL" class="Form__field flex">
        <span class="sr-only">Email</span>
        <input
          name="EMAIL"
          class="Form__input--sm flex-1"
          v-model="EMAIL.value"
          v-bind:disabled="isMakingRequest"
          placeholder="Email"
        />
        <input
          class="Form__submit border-left"
          type="submit"
          v-bind:disabled="isMakingRequest"
          value="Subscribe"
        />
      </label>
      <label for="MMERGE3" class="sr-only">
        <span
          >I am happy for Phillida Reid to use my personal data to send me
          emails</span
        >
        <input
          type="radio"
          value="I am happy for Phillida Reid to use my personal data to send me emails"
          name="MMERGE3"
          id="mce-MMERGE3-0"
          checked
        />
      </label>
    </div>
    <div
      v-if="showSuccessMessage || showSubmissionError || showValidationError"
      class="NewsletterSignupForm__message-box wrapper-2 border-bottom"
    >
      <p v-if="showSuccessMessage" class="Form__help-text">
        Thank you.<br />
        Now please check your inbox.<br />Follow the link in the email to
        activate your subscription.
      </p>
      <p v-else-if="showSubmissionError" class="Form__help-text">
        Something went wrong, please try again.
      </p>
      <p v-else-if="showValidationError" class="Form__help-text">
        Please fill out all required fields to subscribe.
      </p>
    </div>
  </form>
</template>

<script>
import fetchJsonp from "fetch-jsonp";
import querystring from "querystring";

import validate from "../../utils/validators";
const FieldNames = ["FNAME", "LNAME", "EMAIL", "MMERGE3"];

export default {
  name: "NewsletterSignupForm",
  data() {
    return {
      isMakingRequest: false,
      showValidationError: false,
      showSubmissionError: false,
      showSuccessMessage: false,
      FNAME: {
        value: "",
        hasError: false,
        validator: "string",
      },
      LNAME: {
        value: "",
        hasError: false,
        validator: "string",
      },
      EMAIL: {
        value: "",
        hasError: false,
        validator: "string",
      },
      MMERGE3: {
        value:
          "I am happy for Southard Reid to use my personal data to send me emails", // MUST be Southard Reid due to Mailchimp
        hasError: false,
        validator: "string",
      },
    };
  },
  computed: {
    url() {
      const mailchimpCredentials = this.$store.getters.mailchimpCredentials;
      return `//southardreid.us3.list-manage.com/subscribe/post?u=${mailchimpCredentials.u}&amp;id=${mailchimpCredentials.id}`;
    },
    jsonPUrl() {
      return `//southardreid.us3.list-manage.com/subscribe/post-json?c=?&`;
    },
    cssVars() {
      return {
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = this._validateAndEvaluateFormFields();
      if (formData) {
        const mailchimpCredentials = this.$store.getters.mailchimpCredentials;
        formData.u = mailchimpCredentials.u;
        formData.id = mailchimpCredentials.id;
        const data = `${querystring.stringify(formData)}`;
        try {
          fetchJsonp(this.jsonPUrl + data);
        } catch (e) {
          // Troubleshooting trick: follow `path` in browser for true error message
          // Weird API issue where it requires jsonP going out (get around CORS) but responds in regular JSON
          this._onError(e);
          return;
        }
        this._onSuccess();
      }
    },
    _onError(error) {
      console.error("[NewsletterSignupForm] Error:", error);
      this.showSubmissionError = true;
      this.isMakingRequest = false;
    },
    _onSuccess() {
      this.showSuccessMessage = true;
      this.isMakingRequest = false;
    },
    _validateAndEvaluateFormFields() {
      const formData = {};
      let fieldName;
      let hasError = false;

      for (var i = 0; i < FieldNames.length; i++) {
        fieldName = FieldNames[i];
        if (validate(this[fieldName].value, this[fieldName].validator)) {
          formData[fieldName] = this[fieldName].value;
          if (this[fieldName].hasError) this[fieldName].hasError = false;
        } else {
          if (!hasError) hasError = true;
          if (!this[fieldName].hasError) this[fieldName].hasError = true;
        }
      }

      if (!hasError) {
        return formData;
      } else {
        this.showValidationError = true;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/styleguide/_sizing.scss";
@import "../../styles/styleguide/_transitions.scss";
.NewsletterSignupForm {
  input:focus {
    background: var(--timelapse-color-darkened);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--timelapse-color-darkened) inset !important;
  }

  &__message-box {
    min-height: $mobile-spacer;
  }

  .Form__submit {
    transition: color $transition-short, background-color $transition-short;
    &:hover,
    &:focus {
      background-color: var(--timelapse-color-darkened);
    }
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .NewsletterSignupForm {
    &__message-box {
      min-height: $desk-spacer;
      border-bottom: none;
    }
  }
}
</style>
