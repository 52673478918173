<template>
  <div class="VideoBlock flex flex-1 justify-center">
    <div class="flex flex-col">
      <VideoPlayer
        ref="videoPlayer"
        class="VideoBlock__video"
        v-bind:data="videoPlayerData"
        v-bind:onLoad="onLoad"
        v-bind:onVideoPlayerStateChanged="onVideoPlayerStateChanged"
      />
      <VideoProgress
        v-if="isLoaded && $mq === 'desktop'"
        v-bind:percentPlayed="percentPlayed"
        class="VideoBlock__progress mt_5"
      />
      <div v-if="hasCaption" class="px1 md:px0 text-left">
        <RichText :field="data.caption" class="serif mt1 md:mt1_5" />
      </div>
      <div
        v-if="artwork"
        class="flex flex-col md:flex-row md:justify-between px1 md:px0 mt1_25 md:mt1_5"
      >
        <ArtworkPlaque
          v-bind:artistUid="artwork.artistUid"
          v-bind:baseUrl="baseUrl"
          v-bind:details="artwork.details"
          v-bind:onSelect="onSelectArtwork"
          v-bind:title="artwork.title"
          v-bind:uid="artwork.uid"
          v-bind:year="artwork.year"
        />
        <Button
          v-if="artwork && onSelectArtwork"
          class="VideoBlock__button Button--plus"
          activeClassName="Button--plus--active"
          v-bind:isActive="isSelected"
          v-bind:onPress="() => onSelectArtwork(artwork.uid)"
        >
          Select
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";

import ArtworkPlaque from "./ArtworkPlaque";
import Button from "./Button";
import RichText from "./RichText";
import VideoPlayer from "./VideoPlayer";
import VideoProgress from "./VideoProgress";

import hasRichText from "../utils/hasRichText";

export default {
  name: "VideoBlock",
  components: {
    ArtworkPlaque,
    Button,
    RichText,
    VideoPlayer,
    VideoProgress,
  },
  props: {
    baseUrl: String,
    onSelectArtwork: Function || undefined,
    data: {
      title: String,
      sources: {
        type: Array,
        default: [],
      },
      caption: Array || undefined, // Prismic rich text
      artwork: undefined || {
        uid: String,
        data: {
          title: String,
          year: String,
          details: Array, // Prismic rich text
          artist: {
            uid: String,
          },
        },
      },
    },
  },
  data() {
    return {
      isLoaded: false,
      percentPlayed: 0,
    };
  },
  methods: {
    onLoad() {
      this.isLoaded = true;
    },
    onVideoPlayerStateChanged({ currentTime, duration }) {
      const progress = currentTime / duration;
      this.percentPlayed = 100 * progress;
    },
  },
  computed: {
    artwork() {
      if (this.data.artwork) {
        return {
          uid: this.data.artwork.uid,
          artistUid: get(this.data.artwork, "data.artist.uid"),
          details: get(this.data.artwork, "data.details", []),
          title: get(this.data.artwork, "data.title"),
          year: get(this.data.artwork, "data.year"),
        };
      }
      return null;
    },
    video() {
      return get(this.$refs, "videoPlayer.$refs.videoElement", {});
    },
    hasCaption() {
      return hasRichText(this.data.caption);
    },
    isPaused() {
      return get(this.$refs, "videoPlayer.$refs.videoElement.paused", true);
    },
    videoPlayerData() {
      return {
        sources: get(this.data, "sources", []).reduce((arr, source) => {
          if (get(source, "internal_file.url")) {
            arr.push({
              key: `int_${source.data_type}_${source.internal_file.size}`,
              url: get(source, "internal_file.url"),
              dataType: source.data_type,
            });
          } else if (get(source, "external_file")) {
            arr.push({
              key: `ext_${source.data_type}`,
              url: source.external_file,
              dataType: source.data_type,
            });
          }
          return arr;
        }, []),
      };
    },
  },
};
</script>

<style lang="scss">
@import "../styles/styleguide/_sizing.scss";
.VideoBlock {
  max-height: 100vh;
  &__video {
    max-width: 100vw;
  }
  &__button {
    margin-top: $mobile-margin;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .VideoBlock {
    max-height: initial;
    &__video {
      max-width: initial;
    }
    &__button {
      margin-top: $desk-margin;
    }
  }
}
</style>
