<template>
  <button v-bind:class="_className" v-on:click.stop="_onPress">
    <slot />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    isActive: Boolean,
    className: String,
    activeClassName: String,
    onPress: Function,
  },
  computed: {
    _className() {
      if (this.isActive && this.activeClassName) return this.activeClassName;
      if (this.className) return this.className;
      return "Button";
    },
  },
  methods: {
    _onPress() {
      this.onPress();
    },
  },
};
</script>
