export default function (arr, chunkSize) {
  return arr.reduce((chunkedArray, item) => {
    if (
      chunkedArray.length === 0 ||
      chunkedArray[chunkedArray.length - 1].items.length === chunkSize
    ) {
      chunkedArray = chunkedArray.concat([
        {
          id: `chunk-${item.id || item.uid}`,
          items: [item],
        },
      ]);
    } else {
      chunkedArray[chunkedArray.length - 1].items.push(item);
    }
    return chunkedArray;
  }, []);
}
