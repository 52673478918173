<template>
  <div
    class="ArtistUpdatesTable Table border-bottom spacer-bottom-2 flex flex-col"
    :style="cssVars"
  >
    <div v-if="$mq === 'mobile'" class="p1 border-bottom spacer-bottom-1">
      <p class="subheading sans-serif">Updates</p>
    </div>

    <!-- Sort Direction Buttons -->
    <div
      v-if="$mq === 'desktop'"
      class="Table__hr--sm flex items-stretch border-bottom"
    >
      <Button
        class="flex-1 px1"
        aria-label="sort by artist"
        v-bind:onPress="() => _setSortBy('artists_label')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'artists_label'"
          v-bind:isUp="sortBy === 'artists_label' && sortDirection === -1"
        />
      </Button>
      <Button
        class="flex-1 px1"
        aria-label="sort by title"
        v-bind:onPress="() => _setSortBy('title')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'title'"
          v-bind:isUp="sortBy === 'title' && sortDirection === -1"
        />
      </Button>
      <div class="Table__image"><!-- just a spacer --></div>
      <Button
        class="flex-1 px1"
        aria-label="sort by pulisher/venue"
        v-bind:onPress="() => _setSortBy('publisher_or_venue')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'publisher_or_venue'"
          v-bind:isUp="sortBy === 'publisher_or_venue' && sortDirection === -1"
        />
      </Button>
      <Button
        class="flex-1 pl_5 pr1_5"
        aria-label="sort by date"
        v-bind:onPress="() => _setSortBy('start_date')"
      >
        <TriangleIcon
          class="Table__icon"
          v-bind:isActive="sortBy === 'start_date'"
          v-bind:isUp="sortBy === 'start_date' && sortDirection === 1"
        />
      </Button>
    </div>

    <!-- Table Body -->
    <p
      class="body flex flex-1 items-center justify-center spacer-top-1 spacer-bottom-1"
      v-if="!isLoading && updates && updates.length === 0"
    >
      No updates at this time.
    </p>
    <div
      class="UpdatesTable__body transition-opacity"
      v-for="update in updates"
      v-bind:key="update.id"
    >
      <ExhibitionUpdateRow v-if="update.exhibitionUid" v-bind:update="update" />
      <UpdateRow
        v-else-if="update.type === 'update' && !!update.link"
        class="Table__row--linked"
        v-bind:update="update"
      />
      <UpdateRow v-else-if="update.type === 'update'" v-bind:update="update" />
      <p v-else>{{ update.title }}</p>
    </div>
  </div>
</template>

<script>
import Button from "../Button";
import ExhibitionUpdateRow from "./rows/ExhibitionUpdateRow";
import UpdateRow from "./rows/UpdateRow";
import TriangleIcon from "../icons/TriangleIcon";

export default {
  name: "ArtistUpdatesTable",
  components: {
    Button,
    ExhibitionUpdateRow,
    UpdateRow,
    TriangleIcon,
  },
  props: {
    isLoading: Boolean,
    filterBy: String,
    sortBy: String,
    sortDirection: Number,
    updates: Array,
    setSortBy: Function,
  },
  methods: {
    _setSortBy(param) {
      this.setSortBy(param);
    },
  },
  computed: {
    cssVars() {
      return {
        "--table-body-opacity": this.isLoading ? 0 : 1,
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
  },
};
</script>

<style lang="scss">
.Table {
  background-color: var(--timelapse-color);
  &__row:hover {
    background-color: var(--timelapse-color-darkened);
  }
}
.ArtistUpdatesTable {
  &__body {
    opacity: var(--table-body-opacity);
  }
}
</style>
