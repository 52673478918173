<template>
  <div
    class="PrivateViewingPasswordForm flex-1 flex flex-col items-center justify-center"
  >
    <Navigation isTransparent hideLinks />
    <form
      v-on:submit="didSubmit"
      method="post"
      class="flex flex-col flex-1 justify-center md:justify-center md:col-4 wrapper-2 spacer-bottom-1"
    >
      <div class="flex flex-col">
        <h1 class="subtitle text-center">Private Viewing</h1>
        <p class="subheading my1">
          Please enter the password shared with you via email to proceed.
        </p>
        <label for="password" class="Form__field flex flex-col">
          <span class="sr-only">Password</span>
          <input
            name="password"
            class="Form__input--serif"
            v-model="password"
            v-bind:disabled="isMakingRequest"
            placeholder="Password"
            type="password"
          />
        </label>
        <p v-if="hasError" class="Form__help-text mt_5 text-center">
          Incorrect password. Please try again.
        </p>
      </div>
      <input
        class="Button--primary--black flex self-center justify-center mt2"
        type="submit"
        v-bind:disabled="isMakingRequest"
        value="Enter"
      />
    </form>
  </div>
</template>

<script>
import Navigation from "../Navigation";
export default {
  name: "PrivateViewingPasswordForm",
  components: {
    Navigation,
  },
  data() {
    return {
      password: "",
    };
  },
  props: {
    hasError: Boolean,
    isMakingRequest: Boolean,
    onSubmit: Function,
    uid: String,
  },
  watch: {
    uid() {
      this.password = "";
    },
  },
  methods: {
    didSubmit(e) {
      e.preventDefault();
      this.onSubmit(this.password);
    },
  },
};
</script>

<style></style>
