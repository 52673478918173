<template>
  <div class="ArtistPage displace-nav flex flex-1 flex-col container">
    <div v-if="showArtwork" class="overlay--fixed z3">
      <ArtworkDetailPage
        v-bind:uid="artworkUid"
        v-bind:baseUrl="`/artists/${this.uid}`"
        v-bind:isEnquiring="isEnquiring"
      />
    </div>
    <Navigation v-else isTransparent />
    <header
      class="ArtistPage__header spacer-bottom-2 flex flex-col items-start wrapper-4 subtitle--table"
    >
      <div class="flex flex-col md:flex-row">
        <div class="md:col-4 mb1">
          <p>{{ fullName }}</p>
        </div>
        <div class="md:col-8">
          <p v-if="$mq === 'desktop'" class="overflow-ellipsis">
            {{ shortBio }}
          </p>
          <p v-else>{{ shortBio }}</p>
        </div>
      </div>
      <a
        v-if="cv"
        v-bind:href="cv"
        target="_blank"
        rel="noreferrer"
        class="Button--primary mt2 md:mt0 py_25 px1_25 small sans-serif uppercase"
      >
        Download CV
      </a>
    </header>
    <main class="ArtistPage__body flex flex-col flex-1">
      <BlockSwitch
        v-if="this.blocks && this.blocks.length"
        v-bind:baseUrl="`/artists/${this.uid}`"
        v-bind:blocks="blocks"
      />
      <NotFound v-if="!this.id && !this.isLoading" />
    </main>
    <ArtistUpdatesTable
      v-bind:isLoading="updates.isLoading"
      v-bind:sortBy="updates.sortBy"
      v-bind:sortDirection="updates.sortDirection"
      v-bind:updates="updates.data"
      v-bind:setSortBy="setSortBy"
    />
    <Footer />
  </div>
</template>

<script>
import get from "lodash/get";

import Cms from "../cms";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import NotFound from "../components/NotFound";
import ArtistUpdatesTable from "../components/table/ArtistUpdatesTable";
import ArtworkDetailPage from "./ArtworkDetailPage";

const Attributes = {
  ARTIST: "artists_label",
  TITLE: "title",
  PUBLISHER_OR_VENUE: "publisher_or_venue",
  DATE: "start_date",
};

export default {
  name: "ArtistPage",
  components: {
    Navigation,
    NotFound,
    Footer,
    ArtistUpdatesTable,
    ArtworkDetailPage,
  },
  props: {
    artworkUid: String,
    isEnquiring: Boolean,
    showArtwork: Boolean,
    uid: String,
  },
  data() {
    return {
      id: "",
      isLoading: true,
      blocks: null,
      fullName: "",
      updates: {
        isLoading: true,
        sortBy: Attributes.DATE,
        sortDirection: -1,
        data: [],
      },
    };
  },
  beforeMount() {
    this.fetchPageContent();
    this.$store.commit("setMainNavIsOpen", false);
  },
  mounted() {
    this.scrollToTop();
  },
  beforeRouteUpdate() {
    this.fetchPageContent();
    this.$store.commit("setMainNavIsOpen", false);
  },
  computed: {
    shortBio() {
      const artistDetails = this.$store.getters.artistDetails(this.uid);
      return get(artistDetails, "shortBio");
    },
    cv() {
      const artistDetails = this.$store.getters.artistDetails(this.uid);
      return get(artistDetails, "cv");
    },
  },
  methods: {
    fetchPageContent() {
      this.isLoading = true;
      Cms.fetchArtistPageContent(this.$prismic.client, this.uid, (data) => {
        this.id = data.id;
        this.blocks = data.blocks;
        this.isLoading = false;
        this.fullName = data.fullName;
        this.fetchUpdates();
      });
    },
    // Updates Methods
    fetchUpdates() {
      Cms.fetchUpdatesByArtist(
        this.$prismic.client,
        this.id,
        this.updates.sortBy,
        this.updates.sortDirection,
        1,
        200,
        this._didLoadUpdates
      );
    },
    setSortBy(sortBy) {
      if (this.updates.isLoading) return;
      if (this.updates.sortBy === sortBy) {
        if (this.updates.sortDirection > 0) {
          this.updates.sortDirection = -1;
        } else {
          this.updates.sortDirection = 1;
        }
      } else {
        this.updates.sortBy = sortBy;
        this.updates.sortDirection = 1;
      }
      this._reloadContent();
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    _reloadContent() {
      this.updates.isLoading = true;
      this.fetchUpdates();
    },
    _didLoadUpdates(data) {
      setTimeout(() => {
        this.updates.data = data.results;
        this.updates.isLoading = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.ArtistPage {
  &__header {
    padding-top: 2rem;
  }
  &__body {
    min-height: 100vh;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ArtistPage__header {
    padding-top: 0;
    padding-bottom: 3rem;
  }
}
</style>
