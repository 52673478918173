export default class Swipeable {
  constructor(
    container,
    onSwipe,
    onScrollY,
    tolerance = 25,
    enableMouseEvents = false
  ) {
    this.x0 = null;
    this.y0 = null;
    this.onSwipe = onSwipe;
    this.container = container;
    this.tolerance = tolerance;
    this.enableMouseEvents = enableMouseEvents;
    this.onScrollY = onScrollY;
    // Bind Event Listeners To container
    this.container.addEventListener("touchstart", this.lock.bind(this), false);
    this.container.addEventListener("touchend", this.move.bind(this), false);
    /* This will cause page change in some browsers */
    this.container.addEventListener("touchmove", this.suppressEvent, false);
    if (this.enableMouseEvents) {
      this.container.addEventListener("mousedown", this.lock.bind(this), false);
      this.container.addEventListener("mouseup", this.move.bind(this), false);
    }
  }
  suppressEvent(e) {
    e.preventDefault();
  }
  lock(e) {
    this.x0 = this.unify(e).clientX;
    this.y0 = this.unify(e).clientY;
  }
  move(e) {
    if (this.x0 || this.x0 === 0) {
      const difference = this.unify(e).clientX - this.x0;
      const direction = Math.sign(difference);
      if (direction * difference >= this.tolerance) {
        this.onSwipe(direction, difference);
      }
      this.x0 = null;
    }
    if (this.y0 || this.y0 === 0) {
      const difference = this.unify(e).clientY - this.y0;
      const direction = Math.sign(difference);
      if (direction * difference >= this.tolerance) {
        this.scrollY(difference);
      }
      this.y0 = null;
    }
  }
  unify(e) {
    return e.changedTouches ? e.changedTouches[0] : e;
  }
  teardown() {
    this.container.removeEventListener("touchstart", this.lock);
    this.container.removeEventListener("touchend", this.move);
    this.container.removeEventListener("touchmove", this.suppressEvent);
    if (this.enableMouseEvents) {
      this.container.removeEventListener("mousedown", this.lock);
      this.container.removeEventListener("mouseup", this.move);
    }
  }
  scrollY(difference) {
    if (typeof this.onScrollY === "function") {
      this.onScrollY(-1 * difference);
    } else {
      window.scroll(0, -1 * difference);
    }
  }
}
