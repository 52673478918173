<template>
  <div class="TriangleIcon flex" :style="cssVars">
    <img v-if="isUp" src="../../assets/icon/caret-up.svg" />
    <img v-else class="h100" src="../../assets/icon/caret-down.svg" />
  </div>
</template>

<script>
export default {
  name: "TriangleIcon",
  props: {
    isActive: Boolean,
    isUp: Boolean,
  },
  computed: {
    cssVars() {
      return {
        "--opacity": this.isActive ? 1 : 0.2,
      };
    },
  },
};
</script>

<style scoped>
.TriangleIcon {
  width: 1rem;
  opacity: var(--opacity);
}
img {
  height: 100%;
}
</style>
