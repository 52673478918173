import get from "lodash/get";

function parseWidth(widthKey, defaultColumnWidth) {
  if (typeof widthKey === "string") {
    const width = parseInt(widthKey);
    if (Number.isInteger(width) && width > 0 && width <= defaultColumnWidth) {
      return width;
    }
  }
  return defaultColumnWidth;
}

export default function parseColumnData(
  data,
  spacingX,
  spacingY,
  defaultColumnWidth,
  index
) {
  const image = get(data, `images[${index}]`);
  const widthKey = get(image, "width");

  if (get(image, "artwork.type") === "artwork") {
    return {
      artistUid: get(image, "artwork.data.artist.uid"),
      artworkUid: get(image, "artwork.uid"),
      details: get(image, "artwork.data.details", []),
      image: get(image, "image"),
      title: get(image, "artwork.data.title", ""),
      year: get(image, "artwork.data.year", ""),
      caption: get(image, "caption"),
      captionClass: get(image, "width") === "fullbleed" ? "px1 md:px2_5" : "",
      colClass: `md:col-${parseWidth(
        widthKey,
        defaultColumnWidth
      )} ${spacingY} ${widthKey === "fullbleed" ? "" : spacingX}`,
      isFullbleed: widthKey === "fullbleed",
    };
  } else {
    return {
      image: get(image, "image"),
      caption: get(image, "caption"),
      captionClass: get(image, "width") === "fullbleed" ? "px1 md:px2_5" : "",
      colClass: `md:col-${parseWidth(
        widthKey,
        defaultColumnWidth
      )} ${spacingY} ${widthKey === "fullbleed" ? "" : spacingX}`,
      isFullbleed: widthKey === "fullbleed",
    };
  }
}
