<template>
  <div class="flex flex-col flex-1 spacer-top-1 spacer-bottom-1 p1">
    <div class="flex flex-col flex-1" @click="onClick">
      <h1 class="title serif">{{ title }}</h1>
      <h2 v-if="subtitle" class="title serif italic">{{ subtitle }}</h2>
      <div class="spacer-top-1">
        <p v-if="copy" class="subheading sans-serif">{{ copy }}</p>
        <p v-if="detail" class="subheading serif">{{ detail }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleSlide",
  props: {
    title: String,
    subtitle: String,
    copy: String,
    detail: String,
    onClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
