<template>
  <div
    class="ImageSlide flex flex-col justify-center items-center flex-1"
    :style="cssVars"
  >
    <div
      v-if="isPlaying"
      class="ImageSlide__wrapper flex items-stretch"
      @click="onClick"
    >
      <div
        class="ImageSlide__container flex flex-col items-center justify-center col-12 pl2 pr1"
      >
        <ImageLoader
          v-if="
            columnOne.image && columnOne.image.url && !columnOne.isFullbleed
          "
          v-bind:image="columnOne.image"
          v-bind:class="`ImageSlide__image--playing flex items-center ${columnOne.colClass}`"
          constrainHeight
        />
      </div>
    </div>
    <div v-else class="ImageSlide__wrapper flex items-stretch" @click="onClick">
      <div class="ImageSlide__container flex flex-col items-center col-12">
        <div>
          <ImageLoader
            v-bind:image="columnOne.image"
            v-if="columnOne.image && columnOne.image.url"
            constrainHeight
            class="ImageSlide__image flex justify-end mb1_25"
          />
          <ArtworkPlaque
            v-if="columnOne.artworkUid"
            v-bind:artistUid="columnOne.artistUid"
            v-bind:details="columnOne.details"
            v-bind:title="columnOne.title"
            v-bind:uid="columnOne.artworkUid"
            v-bind:year="columnOne.year"
            buttonClassName="Button--tertiary--white"
            v-bind:openInNewTab="true"
          />
          <RichText
            class="ImageSlide__caption italic"
            v-else-if="columnOne.caption"
            :field="columnOne.caption"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArtworkPlaque from "../ArtworkPlaque";
import ImageLoader from "../loaders/ImageLoader";
import RichText from "../RichText";

import parseColumnData from "../../utils/parseColumnData";
const DEFAULT_COLUMN_WIDTH = 12;
const COLUMN_SPACING_X = "";
const COLUMN_SPACING_Y = "";
export default {
  name: "ImageSlide",
  components: {
    ArtworkPlaque,
    ImageLoader,
    RichText,
  },
  props: {
    isPlaying: Boolean,
    type: String,
    data: {
      images: Array,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    columnOne() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        0
      );
    },
    cssVars() {
      return {
        "--image-height": this.isPlaying ? "auto" : "40vh",
        "--wrapper-height": this.isPlaying ? "80vh" : "60vh",
        "--container-padding": this.columnOne.isFullbleed ? "0" : "1rem",
        "--container-bg-image":
          this.columnOne.isFullbleed && this.isPlaying
            ? `url(${this.columnOne.image.url})`
            : "none",
      };
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.ImageSlide {
  height: 100vh;
  background-image: var(--container-bg-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__caption {
    margin-top: $desk-margin;
  }

  &__wrapper {
    height: var(--wrapper-height);
  }
  &__image {
    height: var(--image-height);
    max-width: 100%;
  }
  &__image--playing {
    max-width: 100%;
  }
  &__image--fullbleed {
    min-height: 100vh;
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .ImageSlide {
    &__image--playing {
      img {
        max-height: 80vh !important;
      }
    }
  }
}
</style>
