<template>
  <div class="VideoProgress bg-color-black-0_2" :style="cssVars">
    <div class="VideoProgress__bar bg-color-black" />
  </div>
</template>

<script>
export default {
  name: "VideoProgress",
  props: {
    percentPlayed: Number,
  },
  computed: {
    cssVars() {
      return {
        "--progress-width": this.percentPlayed
          ? `${this.percentPlayed}%` // `${this.percentPlayed / 2}vw`
          : 0,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../styles/styleguide/_sizing.scss";
.VideoProgress {
  height: 1px;
  &__bar {
    height: 1px;
    width: var(--progress-width);
  }
}
</style>
