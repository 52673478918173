import Prismic from "prismic-javascript";

const _columnBlockFields = `{
  images {
    artwork {
      title
      year
      details
      is_enquirable
      artist
      thumbnail
    }
    caption
    image
    width
  }
}`;
const _textBlockFields = `{
  body
}`;
const _videoBlockFields = `{
  title
  sources
}`;

export default {
  query: (uid, password) => {
    return [
      Prismic.Predicates.any("document.type", ["private_viewing"]),
      Prismic.Predicates.at("my.private_viewing.uid", uid),
      Prismic.Predicates.at("my.private_viewing.password", password),
    ];
  },
  options: `{
    private_viewing {
      title
      client_name
      client_email
      client_phone_number
      pdf
      artworks {
        artwork {
          title
          year
          details
          images
          is_enquirable
          artist
          thumbnail
        }
      }
      content_blocks {
        content_block {
          ...on single_column_block ${_columnBlockFields}
          ...on double_column_block ${_columnBlockFields}
          ...on triple_column_block ${_columnBlockFields}
          ...on text_block ${_textBlockFields}
          ...on video_block ${_videoBlockFields}
        }
      }
    }
  }`,
};
