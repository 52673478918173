<template>
  <div
    class="ImageLoader flex flex-col justify-start items-start overflow-hidden"
    :style="cssVars"
  >
    <prismic-image
      v-if="image && image.url"
      class="ImageLoader__image transition-opacity"
      :field="imageField"
    />
    <img v-else src="not-found" />
  </div>
</template>

<script>
export default {
  name: "ImageLoader",
  props: {
    altFallback: String,
    constrainHeight: Boolean,
    fullHeight: Boolean,
    fullWidth: Boolean,
    isMobile: Boolean,
    skipPreload: Boolean,
    image: /* prismic image field */ {
      url: String,
      alt: String,
      dimensions: {
        width: Number,
        height: Number,
      },
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    this.preloadImage();
  },
  watch: {
    image() {
      this.preloadImage();
    },
  },
  methods: {
    preloadImage: function () {
      if (this.skipPreload) {
        this._didLoad();
        return;
      }
      if (this.imageField && this.imageField.url) {
        const loader = new window.Image();
        loader.src = "";
        loader.onload = this._didLoad;
        loader.src = this.imageField.url;
      }
    },
    _didLoad: function () {
      this.isLoaded = true;
    },
  },
  computed: {
    cssVars() {
      return {
        "--image-opacity": this.isLoaded ? 1 : 0,
        "--width": this.fullWidth ? "100%" : "auto",
        "--max-width": "100%",
        "--height": this.fullHeight ? "100%" : "auto",
        "--max-height": this.constrainHeight ? "100%" : "auto",
      };
    },
    imageField() {
      if (this.isMobile && this.image && this.image.url) {
        return {
          ...this.image,
          alt: this.image.alt || this.altFallback,
          url: `${this.image.url}&w=500`,
        };
      }
      if (this.image && (this.image.alt || !this.altFallback)) {
        return this.image;
      }
      return {
        ...this.image,
        alt: this.altFallback,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.ImageLoader__image {
  max-width: var(--max-width);
  width: var(--width);
  max-height: var(--max-height);
  height: var(--height);
  opacity: var(--image-opacity);
}
</style>
