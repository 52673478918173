<template>
  <div class="VideoSlide flex justify-center items-center" :style="cssVars">
    <VideoPlayer
      ref="videoPlayer"
      class="VideoSlide__video"
      v-bind:data="videoPlayerData"
      v-bind:isPlaying="isPlaying"
      v-bind:onClick="onClick"
      v-bind:onLoad="onLoad"
      v-bind:onLoadedMetadata="onLoadedMetadata"
    />
  </div>
</template>

<script>
import get from "lodash/get";

import VideoPlayer from "../VideoPlayer";

export default {
  name: "VideoSlide",
  components: {
    VideoPlayer,
  },
  props: {
    isPlaying: Boolean,
    type: String,
    data: {
      sources: Array,
      title: String,
    },
    didReceiveDuration: Function,
    onClick: Function,
  },
  data() {
    return {
      isLoaded: false,
      isPaused: false,
      percentPlayed: 0,
    };
  },
  methods: {
    onLoad() {
      get(this.$refs, "videoPlayer", {}).onPlay();
    },
    onVideoPlayerStateChanged({ currentTime, duration, isPaused }) {
      const progress = currentTime / duration;
      this.percentPlayed = 100 * progress;
      this.isPaused = isPaused;
    },
  },
  computed: {
    video() {
      return get(this.$refs, "videoPlayer.$refs.videoElement", {});
    },
    cssVars() {
      return {
        "--image-height": this.isPlaying ? "auto" : "40vh",
        "--wrapper-height": this.isPlaying ? "80vh" : "60vh",
      };
    },
    videoPlayerData() {
      return {
        sources: get(this.data, "sources", []).reduce((arr, source) => {
          if (get(source, "internal_file.url")) {
            arr.push({
              key: `int_${source.data_type}_${source.internal_file.size}`,
              url: get(source, "internal_file.url"),
              dataType: source.data_type,
            });
          } else if (get(source, "external_file")) {
            arr.push({
              key: `ext_${source.data_type}`,
              url: source.external_file,
              dataType: source.data_type,
            });
          }
          return arr;
        }, []),
      };
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.VideoSlide {
  height: 100vh;

  &__video {
    max-width: 100vw;
  }

  @media (min-width: map-get($breakpoints, "md")) {
    max-width: "initial";
  }
}
</style>
