import get from "lodash/get";
import hasRichText from "./hasRichText";

export function makeSlides(artwork) {
  const slides = get(artwork, "images").reduce((slides, image) => {
    if (image && image.url) {
      slides.push({
        index: slides.length,
        type: "image",
        data: image,
        mobileData: {
          ...image,
          url: `${image.url}&w=500`,
        },
      });
    }
    return slides;
  }, []);

  if (get(artwork, "video.id")) {
    slides.push({
      index: slides.length,
      type: "video",
      data: artwork.video,
    });
  }

  if (hasRichText(get(artwork, "description"))) {
    slides.push({
      index: slides.length,
      type: "text",
      data: artwork.description,
    });
  }
  return slides;
}
