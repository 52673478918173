<template>
  <div
    class="PrivateViewingSlideshow overlay--fixed z3 overflow-hidden color-white bg-color-black flex flex-col flex-1"
    ref="page"
  >
    <router-link
      v-if="!playback.isPlaying || interactions.mouseRecentlyMoved"
      v-bind:to="baseUrl"
      aria-label="end slideshow"
      class="flex flex-col absolute t0 r0 z4"
    >
      <img
        class="PrivateViewingSlideshow__icon transition-opacity"
        src="../assets/icon/close-thin-white.svg"
      />
    </router-link>

    <PrivateViewingSlideSwitch
      v-bind:activeSlide="activeSlide"
      v-bind:columnNumber="parsedColumnNumber"
      v-bind:privateViewing="privateViewing"
      v-bind:isPlaying="playback.isPlaying"
      v-bind:isTransitioning="isTransitioning"
      v-bind:onToggle="onToggle"
      v-bind:setSlideDuration="setSlideDuration"
      v-bind:uid="uid"
    />

    <SlideshowControls
      v-bind:slides="slides"
      v-bind:activeSlide="activeSlide"
      v-bind:showButtons="interactions.mouseRecentlyMoved"
      v-bind:baseUrl="baseUrl"
      v-bind:onPause="onPause"
      v-bind:onPlay="onPlay"
      v-bind:isPlaying="playback.isPlaying"
      v-bind:progress="playbackProgress"
      class="PrivateViewingSlideshow__progress absolute b0 z4"
    />
    <router-link
      v-if="$mq === 'desktop' && !playback.isPlaying && activeSlideIndex > 0"
      :to="{
        path: `${baseUrl}/slideshow`,
        query: { slide: activeSlideIndex - 1 },
      }"
      class="PrivateViewingSlideshow__arrow-button transition-opacity flex flex-col absolute l0 justify-center items-start p1_5"
    >
      <img class="rotate-270" src="../assets/icon/arrow-long-white.svg" />
    </router-link>
    <router-link
      v-if="
        $mq === 'desktop' &&
        !playback.isPlaying &&
        activeSlideIndex + 1 < slides.length
      "
      :to="{
        path: `${baseUrl}/slideshow`,
        query: { slide: activeSlideIndex + 1 },
      }"
      class="PrivateViewingSlideshow__arrow-button flex flex-col absolute r0 justify-center items-end p1_5"
    >
      <img class="rotate-90" src="../assets/icon/arrow-long-white.svg" />
    </router-link>
  </div>
</template>

<script>
import get from "lodash/get";
import throttle from "lodash/throttle";

import Swipeable from "../utils/swipeable";

import PrivateViewingSlideSwitch from "../components/slides/PrivateViewingSlideSwitch";
import SlideshowControls from "../components/SlideshowControls";

const TITLE_SLIDE_INTERVAL = 5 * 1000;
const DEFAULT_SLIDE_INTERVAL = 10 * 1000;
const MOUSE_MOVEMENT_INTERVAL = 2 * 1000;
const DESKTOP_PLAYBACK_UPDATE_INTERVAL = 0.125 * 1000;
const MOBILE_PLAYBACK_UPDATE_INTERVAL = 1000;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const SPACEBAR = 32;
const ESCAPE = 27;

export default {
  name: "PrivateViewingSlideshow",
  components: {
    PrivateViewingSlideSwitch,
    SlideshowControls,
  },
  props: {
    columnNumber: String,
    mobile: String,
    slide: String,
    uid: String,
  },
  data() {
    return {
      playback: {
        progress: 0 /* between 0 and 100 */,
        isPlaying: false,
        updatePlaybackInterval: null,
      },
      interactions: {
        mouseRecentlyMoved: false,
        mouseRecentlyMovedAt: -1,
        mouseMoveTimeout: null,
        onMouseMoveThrottled: null,
        swipeable: {},
      },
      isTransitioning: true,
      slideChangeTimeout: null,
      slideDuration: DEFAULT_SLIDE_INTERVAL,
    };
  },
  mounted() {
    this.$store.commit("setMainNavIsOpen", false);
    this.redirectIfMediaMismatch();
    window.addEventListener("keydown", this.handleKeydown);
    this._initInteractions();
    this.onPlay();
    this._onTransitionSlide();
    this.interactions.swipeable = new Swipeable(
      this.$refs.page,
      this._didSwipe
    );
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
    this._cancelInteractions();
    this._cancelUpdatePlaybackInterval();
    this._cleareSlideChangeTimeout();
    this.interactions.swipeable.teardown();
  },
  watch: {
    slide() {
      this._onTransitionSlide();
    },
  },
  computed: {
    activeSlideIndex() {
      const parsed = parseInt(this.slide);
      if (parsed && Number.isInteger(parsed)) return parsed;
      return 0;
    },
    activeSlide() {
      return this.slides[this.activeSlideIndex];
    },
    baseUrl() {
      return `/private-viewings/${this.uid}`;
    },
    privateViewing() {
      return this.$store.getters.privateViewing(this.uid);
    },
    parsedColumnNumber() {
      if (this.columnNumber) {
        const parsed = parseInt(this.columnNumber);
        if (Number.isInteger(parsed) && parsed > 0) return parsed;
      }
      return 1;
    },
    playbackProgress() {
      return (100 * this.playback.progress) / this.slideDuration;
    },
    playbackUpdateInterval() {
      return this.$mq === "desktop"
        ? DESKTOP_PLAYBACK_UPDATE_INTERVAL
        : MOBILE_PLAYBACK_UPDATE_INTERVAL;
    },
    slides() {
      return this.$mq === "desktop"
        ? get(this.privateViewing, "slides.desk", [])
        : get(this.privateViewing, "slides.mobile", []);
    },
  },
  methods: {
    handleKeydown(event) {
      if (event.keyCode === LEFT_ARROW) this.showPreviousSlide();
      if (event.keyCode === RIGHT_ARROW) this.showNextSlide();
      if (event.keyCode === SPACEBAR) {
        if (this.playback.isPlaying) {
          this.onPause();
        } else {
          this.onPlay();
        }
      }
      if (event.keyCode === ESCAPE) this._onEndSlideshow();
    },
    onMouseMove() {
      const now = new Date().getTime();
      this.interactions.mouseRecentlyMoved = true;
      this.interactions.mouseRecentlyMovedAt = now;
      this.interactions.mouseMoveTimeout = setTimeout(() => {
        if (this.interactions.mouseRecentlyMovedAt === now) {
          this.interactions.mouseRecentlyMoved = false;
        }
      }, MOUSE_MOVEMENT_INTERVAL);
    },
    onPause() {
      this.playback.isPlaying = false;
      if (this.playback.updatePlaybackInterval)
        clearInterval(this.playback.updatePlaybackInterval);
    },
    onPlay() {
      this.playback.isPlaying = true;
      this.playback.updatePlaybackInterval = setInterval(
        this._updatePlayback,
        this.playbackUpdateInterval
      );
    },
    onToggle() {
      if (this.playback.isPlaying) {
        this.onPause();
      } else {
        this.onPlay();
      }
      this.onMouseMove();
    },
    redirectIfMediaMismatch() {
      const isMobile = this.$mq === "mobile";
      const wasMobile = this.mobile === "true";
      const slideIndex = parseInt(this.slide);
      const mismatch = isMobile !== wasMobile;
      if (!mismatch) return;

      let slideId;
      let match;
      const mobileSlides = get(this.exhibition, "slides.mobile", []);
      const deskSlides = get(this.exhibition, "slides.desk", []);

      if (!Number.isInteger(slideIndex)) return;
      if (this.$mq === "mobile") {
        slideId = get(deskSlides, `${slideIndex}.id`, "title");
        match = mobileSlides.find((slide) => slide.id === slideId);
      } else {
        slideId = get(mobileSlides, `${slideIndex}.id`, "title");
        match = deskSlides.find((slide) => slide.id === slideId);
      }

      const matchSlideIndex = get(match, "index", 0);
      if (this.activeSlideIndex === matchSlideIndex) return;

      this.$router.replace({
        path: `${this.baseUrl}/slideshow`,
        query: {
          slide: matchSlideIndex,
          mobile: this.$mq === "mobile",
        },
      });
    },
    showPreviousSlide() {
      const previousSlide = this.slides[this.activeSlideIndex - 1];
      if (previousSlide && previousSlide.index !== this.activeSlideIndex) {
        const query = previousSlide.columnNumber
          ? {
              slide: previousSlide.index,
              columnNumber: previousSlide.columnNumber,
              mobile: this.$mq === "mobile",
            }
          : {
              slide: previousSlide.index,
              mobile: this.$mq === "mobile",
            };
        this.$router.replace({
          path: `${this.baseUrl}/slideshow`,
          query,
        });
      }
    },
    showNextSlide() {
      const nextSlide = this.slides[this.activeSlideIndex + 1];
      if (nextSlide && nextSlide.index !== this.activeSlideIndex) {
        const query = nextSlide.columnNumber
          ? {
              slide: nextSlide.index,
              columnNumber: nextSlide.columnNumber,
              mobile: this.$mq === "mobile",
            }
          : {
              slide: nextSlide.index,
              mobile: this.$mq === "mobile",
            };
        this.$router.replace({
          path: `${this.baseUrl}/slideshow`,
          query,
        });
      } else {
        this.playback.progress = this.slideDuration;
      }
    },
    setSlideDuration(duration) {
      if (duration) this.slideDuration = duration * 1000;
    },
    _cancelInteractions() {
      window.removeEventListener(
        "mousemove",
        this.interactions.onMouseMoveThrottled
      );
      this._cancelOnMouseMoveTimeout();
      window.removeEventListener("resize", this.redirectIfMediaMismatch);
    },
    _cancelOnMouseMoveTimeout() {
      if (this.interactions.onMouseMoveTimeout)
        clearTimeout(this.interactions.onMouseMoveTimeout);
    },
    _cancelUpdatePlaybackInterval() {
      if (this.playback.updatePlaybackInterval)
        clearInterval(this.playback.updatePlaybackInterval);
    },
    _cleareSlideChangeTimeout() {
      if (this.slideChangeTimeout) clearTimeout(this.slideChangeTimeout);
    },
    _didSwipe(direction /* difference */) {
      if (direction < 0) {
        this.showNextSlide();
      } else {
        this.showPreviousSlide();
      }
    },
    _initInteractions() {
      this.interactions.onMouseMoveThrottled = throttle(
        this.onMouseMove,
        MOUSE_MOVEMENT_INTERVAL / 2,
        {
          leading: true,
        }
      );
      window.addEventListener(
        "mousemove",
        this.interactions.onMouseMoveThrottled
      );
      window.addEventListener("resize", this.redirectIfMediaMismatch);
    },
    _onEndSlideshow() {
      if (this.isTransitioning) return;
      this.isTransitioning = true;
      this.slideChangeTimeout = setTimeout(() => {
        this.$router.replace({ path: this.baseUrl });
      }, 850);
    },
    _onTransitionSlide() {
      this.isTransitioning = true;
      this.playback.progress = 0;
      this.slideDuration =
        this.slide === "0" ? TITLE_SLIDE_INTERVAL : DEFAULT_SLIDE_INTERVAL;
      this.redirectIfMediaMismatch();
      this.slideChangeTimeout = setTimeout(() => {
        this.isTransitioning = false;
      }, 250);
    },
    _updatePlayback() {
      if (this.playback.isPlaying) {
        this.playback.progress += this.playbackUpdateInterval;
        if (this.playback.progress >= this.slideDuration) {
          if (this.slides[this.activeSlideIndex + 1]) {
            this.showNextSlide();
          } else {
            this._onEndSlideshow();
          }
        }
      } else {
        this._cancelUpdatePlaybackInterval();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/styleguide/_sizing.scss";
.PrivateViewingSlideshow {
  &__arrow-button {
    width: 80 * $desk-sizing-coefficient;
    height: 50vh;
    top: 25vh;
    img {
      max-width: 32 * $desk-sizing-coefficient;
      max-height: 32 * $desk-sizing-coefficient;
    }
    &:hover {
      opacity: 0.2;
    }
  }
  &__icon {
    width: $mobile-icon-lg;
    margin: $mobile-icon-lg;
    &:hover {
      opacity: 0.2;
    }
  }
  &__progress {
    left: calc(1 / 12 * 100vw);
    right: calc(1 / 12 * 100vw);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .PrivateViewingSlideshow {
    &__icon {
      width: $desk-icon-lg;
      margin: $desk-icon-lg;
    }
  }
}
</style>
