import Vue from "vue";
import Vuex from "vuex";

import Cms from "./cms";
import {
  getColorForTimeOfDay,
  darkenColor,
  setDocumentBackgroundColor,
} from "./utils/color";
import { makeSlides } from "./utils/exhibition";

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
export default (function () {
  Vue.use(Vuex);

  return new Vuex.Store({
    state: {
      artists: {
        order: [],
        list: {},
      },
      enquiriesEmailAddress: "",
      enquiriesInstructions: "",
      enquiriesDisclaimer: [],
      exhibitions: {},
      followLinks: [],
      mainNavIsOpen: false,
      mailchimpUserId: "",
      mailchimpListId: "",
      mainNavLinks: [],
      notFoundBody: [],
      notFoundImage: null,
      privacyPolicy: null,
      privateViewings: {},
      privateViewingSelection: [],
      tags: {
        order: [],
        list: {},
      },
      timelapseColor: "rgba(255, 255, 255)",
      venues: [],
    },
    getters: {
      artistsByLastName(state) {
        return state.artists.order.map((uid) => state.artists.list[uid]);
      },
      artistDetails: (state) => (uid) => {
        return state.artists.list[uid] || EMPTY_OBJECT;
      },
      enquiriesEmailAddress(state) {
        return state.enquiriesEmailAddress;
      },
      enquiriesInstructions(state) {
        return state.enquiriesInstructions;
      },
      enquiriesDisclaimer(state) {
        return state.enquiriesDisclaimer;
      },
      exhibition: (state) => (uid) => {
        if (state.exhibitions[uid]) return state.exhibitions[uid];
        return EMPTY_OBJECT;
      },
      exhibitionArtistNames: (state) => (uid) => {
        if (!state.exhibitions[uid]) {
          console.warn(
            `[getters.exhibitionArtistNames] Exhibition ${uid} not found!`
          );
          return "";
        }
        if (state.exhibitions[uid].artistsLabel)
          return state.exhibitions[uid].artistsLabel;
        if (state.exhibitions[uid].artists) {
          return state.exhibitions[uid].artists
            .reduce((arr, artist) => {
              if (
                artist.uid &&
                state.artists.list[artist.uid] &&
                state.artists.list[artist.uid].fullName
              ) {
                arr.push(state.artists.list[artist.uid].fullName);
              }
              return arr;
            }, [])
            .join(", ");
        }
        return "";
      },
      exhibitionBlocks: (state) => (uid) => {
        if (state.exhibitions[uid]) {
          return state.exhibitions[uid].blocks.order.map(
            (blockId) => state.exhibitions[uid].blocks.list[blockId]
          );
        }
        return EMPTY_ARRAY;
      },
      followLinks(state) {
        return state.followLinks;
      },
      filterableTags(state) {
        return state.tags.order
          .map((id) => state.tags.list[id])
          .filter((tag) => tag.isFilterable);
      },
      isSelected: (state) => (uid) => {
        return state.privateViewingSelection.includes(uid);
      },
      mailchimpCredentials(state) {
        return {
          u: state.mailchimpUserId,
          id: state.mailchimpListId,
        };
      },
      mainNavIsOpen(state) {
        return state.mainNavIsOpen;
      },
      mainNavLinks(state) {
        return state.mainNavLinks;
      },
      notFoundBody(state) {
        return state.notFoundBody;
      },
      notFoundImage(state) {
        return state.notFoundImage;
      },
      privacyPolicy(state) {
        return state.privacyPolicy;
      },
      privateViewing: (state) => (uid) => {
        if (state.privateViewings[uid]) return state.privateViewings[uid];
        return EMPTY_OBJECT;
      },
      privateViewingArtworks: (state) => (uid) => {
        if (state.privateViewings[uid]) {
          return state.privateViewings[uid].artworks.order.map(
            (artworkId) => state.privateViewings[uid].artworks.list[artworkId]
          );
        }
        return EMPTY_ARRAY;
      },
      privateViewingBlocks: (state) => (uid) => {
        if (state.privateViewings[uid]) {
          return state.privateViewings[uid].blocks.order.map(
            (blockId) => state.privateViewings[uid].blocks.list[blockId]
          );
        }
        return EMPTY_ARRAY;
      },
      privateViewingSelection(state) {
        return state.privateViewingSelection || EMPTY_ARRAY;
      },
      tagByName: (state) => (tagName) => {
        return Object.values(state.tags.list).find(
          (tag) => tag.title === tagName
        );
      },
      timelapseColor(state) {
        return state.timelapseColor;
      },
      timelapseColorDarkened(state) {
        return darkenColor(state.timelapseColor, 1);
      },
      timelapseColorDarkest(state) {
        return darkenColor(state.timelapseColor, 2);
      },
      venuesByTitle(state) {
        return state.venues;
      },
    },
    mutations: {
      resetSelection(state) {
        state.privateViewingSelection = [];
      },
      addToSelection(state, artworkUid) {
        if (state.privateViewingSelection.includes(artworkUid)) return;
        state.privateViewingSelection = state.privateViewingSelection.concat(
          artworkUid
        );
      },
      removeFromSelection(state, artworkUid) {
        state.privateViewingSelection = state.privateViewingSelection.filter(
          (uid) => uid !== artworkUid
        );
      },
      fetchAndPersistArtists(state, prismicClient) {
        Cms.fetchArtists(prismicClient, (data) => {
          const order = [];
          const list = data.reduce((obj, artist) => {
            order.push(artist.uid);
            obj[artist.uid] = {
              ...artist,
              fullName: `${artist.firstName} ${artist.lastName}`,
            };
            return obj;
          }, {});
          state.artists = {
            order,
            list,
          };
        });
      },
      fetchAndPersistGlobalSettings(state, prismicClient) {
        Cms.fetchGlobalSettings(prismicClient, (data) => {
          state.enquiriesEmailAddress = data.enquiriesEmailAddress;
          state.enquiriesInstructions = data.enquiriesInstructions;
          state.enquiriesDisclaimer = data.enquiriesDisclaimer;
          state.followLinks = data.followLinks;
          state.mailchimpUserId = data.mailchimpUserId;
          state.mailchimpListId = data.mailchimpListId;
          state.mainNavLinks = data.mainNavLinks;
          state.notFoundImage = data.notFoundImage;
          state.notFoundBody = data.notFoundBody;
          state.privacyPolicy = data.privacyPolicy;
        });
      },
      fetchAndPersistTags(state, prismicClient) {
        Cms.fetchTags(prismicClient, (data) => {
          const orderedTagIds = [];
          const unorderedTagIds = [];
          const list = data.reduce((obj, tag) => {
            if (Number.isInteger(tag.order)) {
              orderedTagIds.push(tag.id);
            } else {
              unorderedTagIds.push(tag.id);
            }
            obj[tag.id] = {
              id: tag.id,
              title: tag.title,
              isFilterable: tag.isFilterable,
              order: tag.order,
            };
            return obj;
          }, {});

          const finalOrder = orderedTagIds
            .sort((tagIdA, tagIdB) => {
              return list[tagIdA].order > list[tagIdB].order ? 1 : -1;
            })
            .concat(unorderedTagIds);

          state.tags = {
            order: finalOrder,
            list,
          };
        });
      },
      fetchAndPersistExhibition(state, { prismicClient, uid, didLoad }) {
        Cms.fetchExhibitionPageContent(prismicClient, uid, (data) => {
          const exhibition = data;
          state.exhibitions[uid] = {
            ...exhibition,
            blocks: exhibition.blocks.reduce(
              (blocks, block) => {
                if (block && block.id) {
                  blocks.order.push(block.id);
                  blocks.list[block.id] = block;
                }
                return blocks;
              },
              { order: [], list: {} }
            ),
            slides: makeSlides(exhibition),
          };
          didLoad(data);
        });
      },
      fetchAndPersistPrivateViewing(
        state,
        { prismicClient, uid, password, didLoad }
      ) {
        Cms.fetchPrivateViewing(prismicClient, uid, password, (data) => {
          const privateViewing = data;
          state.privateViewings[uid] = {
            ...privateViewing,
            artworks: privateViewing.artworks.reduce(
              (artworks, artwork) => {
                if (artwork && artwork.uid) {
                  artworks.order.push(artwork.uid);
                  artworks.list[artwork.uid] = artwork;
                }
                return artworks;
              },
              { order: [], list: {} }
            ),
            blocks: privateViewing.blocks.reduce(
              (blocks, block) => {
                if (block && block.id) {
                  blocks.order.push(block.id);
                  blocks.list[block.id] = block;
                }
                return blocks;
              },
              { order: [], list: {} }
            ),
            slides: makeSlides(privateViewing),
          };
          didLoad(data);
        });
      },
      fetchAndPersistVenues(state, prismicClient) {
        Cms.fetchVenues(prismicClient, (data) => {
          state.venues = data;
        });
      },
      setMainNavIsOpen(state, value) {
        state.mainNavIsOpen = value;
      },
      updateTimelapseColor(state) {
        try {
          const _timelapseColor = getColorForTimeOfDay(new Date());
          if (state.timelapseColor !== _timelapseColor) {
            state.timelapseColor = _timelapseColor;
            setDocumentBackgroundColor(_timelapseColor);
          }
        } catch (e) {
          console.error("[updateTimelapseColor] Error:", e);
        }
      },
    },
  });
})();
