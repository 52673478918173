<template>
  <div class="ViewingSelectionsTable flex flex-col md:flex-1" :style="cssVars">
    <div
      v-if="showSuccessMessage"
      class="flex flex-col flex-1 items-center spacer-top-1"
    >
      <p class="serif italic heading">Thank You</p>
      <p class="serif italic mt1 subheading">
        Someone will be in touch soon regarding your selection.
      </p>
    </div>
    <div v-else class="flex flex-col-reverse md:flex-row items-stretch flex-1">
      <div
        class="wrapper-3 flex flex-col md:items-start md:col-4 md:border-right"
      >
        <div v-if="$mq === 'desktop'" class="flex flex-col">
          <p class="ViewingSelectionsTable__title subheading sans-serif bold">
            Your Selection
          </p>
          <p class="subheading serif">
            Please submit your selection and we will be in touch soon.
          </p>
        </div>
        <div class="flex flex-col items-center md:items-start">
          <Button
            aria-label="submit selection"
            class="ViewingSelectionsTable__button Button--primary--black"
            v-bind:onPress="didSubmit"
            v-bind:disabled="isMakingRequest"
          >
            <span v-if="isMakingRequest">Submitting...</span>
            <span v-else>Submit Selection</span>
          </Button>
          <p class="Form__help-text mt1">{{ errorMessage }}</p>
          <p v-if="showFailureMessage" class="Form__help-text mt1">
            Something went wrong, please try again.
          </p>
        </div>
        <div class="flex flex-1 small items-end">
          <p class="spacer-top-1">&copy; PHILLIDA REID GALLERY</p>
        </div>
      </div>
      <div class="ViewingSelectionsTable__table md:col-8">
        <div
          v-for="chunk in chunkedArtworks"
          v-bind:key="chunk.id"
          class="border-bottom flex flex-row flex-wrap"
        >
          <SelectableArtworkCell
            v-for="artwork in chunk.items"
            v-bind:key="artwork.uid"
            v-bind:artwork="artwork"
            v-bind:isSelected="isSelected(artwork.uid)"
            v-bind:onPress="() => onSelect(artwork.uid)"
            class="ViewingSelectionsTable__cell transition-bg-color pointer"
            activeClassName="ViewingSelectionsTable__cell--active"
          />
        </div>
      </div>
      <div
        v-if="$mq === 'mobile'"
        class="flex flex-col wrapper-2 spacer-bottom-1 border-bottom"
      >
        <p class="ViewingSelectionsTable__title subheading sans-serif bold">
          Your Selection
        </p>
        <p class="subheading serif">
          Please submit your selection and we will be in touch soon.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../Button";
import SelectableArtworkCell from "./rows/SelectableArtworkCell";

import chunkArray from "../../utils/chunkArray";

export default {
  name: "ViewingSelectionsTable",
  components: {
    SelectableArtworkCell,
    Button,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  props: {
    isMakingRequest: Boolean,
    privateViewingUid: String,
    selection: Array,
    onSelect: Function,
    onSubmit: Function,
    showSuccessMessage: Boolean,
    showFailureMessage: Boolean,
  },
  computed: {
    chunkedArtworks() {
      return chunkArray(
        this.$store.getters.privateViewingArtworks(this.privateViewingUid),
        this.$mq === "desktop" ? 4 : 2
      );
    },
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
  },
  methods: {
    isSelected(artworkUid) {
      return this.selection.includes(artworkUid);
    },
    didSubmit() {
      if (this.selection.length > 0) {
        this.onSubmit();
      } else {
        this.errorMessage =
          "Please select the work(s) about which you'd like to enquire.";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.ViewingSelectionsTable {
  background-color: var(--timelapse-color);
  &__title {
    margin-top: 10 * $mobile-sizing-coefficient;
    margin-bottom: 30 * $mobile-sizing-coefficient;
  }
  &__button {
    margin-top: 30 * $mobile-sizing-coefficient;
  }
  &__cell--active {
    background-color: var(--timelapse-color-darkened);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ViewingSelectionsTable {
    min-height: 2 * $desk-artwork-cell-height;
    &__title {
      margin-top: 10 * $desk-sizing-coefficient;
      margin-bottom: 30 * $desk-sizing-coefficient;
    }
    &__button {
      margin-top: 30 * $desk-sizing-coefficient;
    }
    &__table {
      border-top: none !important;
    }
  }
}
</style>
