import AWS from "aws-sdk";
import get from "lodash/get";

const GALLERY_URL = "phillidareid.com";
export default class Emailer {
  constructor() {
    AWS.config.credentials = new AWS.Credentials(
      process.env.VUE_APP_AWS_ACCESS_KEY,
      process.env.VUE_APP_AWS_SECRET_KEY
    );
    AWS.config.update({ region: "us-east-1" });
    this.ses = new AWS.SES({ apiVersion: "2010-12-01" });
    // NOTE: for this to work both the DEV and INFO email addresses must be verified in
    // AWS SES, and must not be the same to avoid spam filters
    this.source = process.env.VUE_APP_DEV_EMAIL_ADDRESS;
    this.internalRecipient = process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    if (!this.source) {
      throw new Error(
        "[Emailer] Could not initialize emailer because Source is missing."
      );
    }
    if (!this.internalRecipient) {
      throw new Error(
        "[Emailer] Could not initialize emailer because Internal Recipient is missing."
      );
    }
    this.footerHTML = `<br/><br/><br/><p>/ / / / / / / / / / / / / / / / / / / / / /</p>`;
  }
  sendArtworkEnquiryEmail({
    artistFullName,
    artworkTitle,
    artworkUid,
    enquirerFirstName,
    enquirerLastName,
    enquirerEmail,
    enquirerPhoneNumber,
    origin,
    onSuccess,
    onError,
  }) {
    const subject = `Enquiry regarding ${artworkTitle} by ${artistFullName}`;
    const htmlBody = `
    <p>A new enquiry has been submitted via ${GALLERY_URL}</p><br/>

    <h3>Contact Details</h3>
    <p>[name]: ${enquirerLastName}, ${enquirerFirstName}</p>
    <p>[email]: ${enquirerEmail}</p>
    <p>[phone]: ${enquirerPhoneNumber}</p>.

    <h3>Artwork(s)</h3>
    <p>[title]: ${artworkTitle}</p>
    <p>[artist]: ${artistFullName}<p>
    <p>[details]: ${origin}/works/${artworkUid}</p>
    ${this.footerHTML}
  `;
    this._sendHtmlEmail(
      this.source,
      [this.internalRecipient],
      subject,
      htmlBody,
      onSuccess,
      onError
    );
  }
  sendPrivateViewingSelectionEmail({
    clientName,
    clientEmail,
    clientPhoneNumber,
    privateViewingUid,
    selectedArtworks,
    origin,
    onSuccess,
    onError,
  }) {
    const subject = `${clientName} has selected works from their Private Viewing`;
    const selectionListItems = selectedArtworks.reduce((memo, item) => {
      memo += `
        <li>
          ${get(item, "title", "[title_missing]")} by ${get(
        item,
        "artist.fullName",
        "[artist_missing]"
      )} (${origin}/works/${get(item, "uid")}).
        </li>
      `;
      return memo;
    }, "");
    const htmlBody = `
    <p>${clientName} has selected works from their Private Viewing on ${GALLERY_URL}.</p><br/>

    <h3>Contact Details</h3>
    <p>[name]: ${clientName}</p>
    <p>[email]: ${clientEmail || "N/A"}</p>
    <p>[phone]: ${clientPhoneNumber || "N/A"}</p>.

    <h3>Artwork(s)</h3>
    <ul>
      ${selectionListItems}
    </ul>
    <br/>
    <h4>Revisit this private viewing at ${origin}/private-viewings/${privateViewingUid}</h4>

    ${this.footerHTML}
  `;
    this._sendHtmlEmail(
      this.source,
      [this.internalRecipient],
      subject,
      htmlBody,
      onSuccess,
      onError
    );
  }
  // Private
  _sendHtmlEmail(
    senderEmail,
    recipientEmails,
    subject,
    body,
    onSuccess,
    onError
  ) {
    this.ses.sendEmail(
      {
        Source: senderEmail,
        Destination: { ToAddresses: recipientEmails },
        Message: {
          Subject: {
            Data: subject,
          },
          Body: {
            Html: {
              Data: body,
            },
          },
        },
      },
      function (e, data) {
        if (e) {
          if (typeof onError === "function") {
            onError(e);
          } else {
            throw e;
          }
        } else if (typeof onSuccess === "function") {
          onSuccess(data);
        }
      }
    );
  }
  _sendRawEmail(
    senderEmail,
    recipientEmails,
    subject,
    body,
    onSuccess,
    onError
  ) {
    this.ses.sendEmail(
      {
        Source: senderEmail,
        Destination: { ToAddresses: recipientEmails },
        Message: {
          Subject: {
            Data: subject,
          },
          Body: {
            Text: {
              Data: body,
            },
          },
        },
      },
      function (e, data) {
        if (e) {
          if (typeof onError === "function") {
            onError(e);
          } else {
            throw e;
          }
        } else if (typeof onSuccess === "function") {
          onSuccess(data);
        }
      }
    );
  }
}
