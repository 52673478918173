import Prismic from "prismic-javascript";

export default {
  visible: () => {
    return [
      Prismic.Predicates.any("document.type", ["venue"]),
      Prismic.Predicates.not("my.venue.is_hidden", true),
    ];
  },
  options: `{
    venue {
      title
      owner
      is_hidden
      is_closed
      closed_message
      address
      phone_number
      hours
      featured_exhibition_update {
        artists_label
        exhibition
        start_date
        end_date
        display_date
        title
        thumbnail
      }
    }
  }`,
};
