const _columnBlockFields = `{
  title
  images {
    image
    caption
    width
    artwork
  }
}`;
const _heroBlockFields = `{
  title
  subtitle
  body
  link
  image
  is_white_text
}`;
const _tableBlockFields = `{
  variant
  per_page
  is_infinite
}`;
const _textBlockFields = `{
  body
}`;
const _videoBlockFields = `{
  title
  sources
}`;

export default `{
  generic_page {
    uid
    title
    blocks {
      block {
        ...on single_column_block ${_columnBlockFields}
        ...on double_column_block ${_columnBlockFields}
        ...on triple_column_block ${_columnBlockFields}
        ...on hero_block ${_heroBlockFields}
        ...on table_block ${_tableBlockFields}
        ...on text_block ${_textBlockFields}
        ...on video_block ${_videoBlockFields}
      }
    }
  }
}`;
