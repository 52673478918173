<template>
  <router-link
    v-bind:to="url"
    class="Table__row--double transition-bg-color flex body--table justify-start items-start md:items-stretch color-black text-decoration-none"
  >
    <ImageLoader
      v-if="$mq === 'mobile'"
      class="Table__image flex-1"
      v-bind:image="thumbnail"
      v-bind:altFallback="`Thumbnail image for ${title}`"
      fullWidth
    />
    <div class="flex-2 md:flex">
      <div class="flex flex-col md:flex-row flex-1">
        <div class="flex flex-1 items-center wrapper-4">
          <p class="mb_5 md:mb0">{{ artistNames }}</p>
        </div>
        <div class="flex flex-1 items-center wrapper-4">
          <p class="mb_5 md:mb0">{{ title }}</p>
        </div>
      </div>
      <ImageLoader
        v-if="$mq === 'desktop'"
        class="Table__image"
        v-bind:image="thumbnail"
        v-bind:altFallback="`Thumbnail image for ${title}`"
        fullWidth
      />
      <div class="flex flex-col md:flex-row flex-1">
        <div class="flex flex-1 items-center wrapper-4">
          <p class="mb_5 md:mb0">{{ venueTitle }}</p>
        </div>
        <div class="flex flex-1 items-center wrapper-4 pr2">
          <p class="mb_5 md:mb0">{{ date }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import get from "lodash/get";
import ImageLoader from "../../loaders/ImageLoader";
import { formatRange } from "../../../utils/date";

export default {
  name: "ExhibitionUpdateRow",
  components: {
    ImageLoader,
  },
  props: {
    update: {
      artists: Array,
      title: String,
      exhibitionUid: String,
      startDate: String,
      endDate: String,
      displayDate: {
        type: String,
        default: "",
      },
      thumbnail: {
        /* prismic image field */
        url: String,
        alt: String,
        dimensions: {
          width: Number,
          height: Number,
        },
      },
    },
  },
  computed: {
    artistNames() {
      if (get(this.update, "artistsLabel")) return this.update.artistsLabel;
      return get(this.update, "artists", [])
        .map((artist) => {
          return `${get(artist, "firstName", "")} ${get(
            artist,
            "lastName",
            ""
          )}`;
        })
        .join(", ");
    },
    date() {
      if (get(this.update, "displayDate")) return this.update.displayDate;
      return formatRange(
        get(this.update, "startDate"),
        get(this.update, "endDate")
      );
    },
    url() {
      return `/exhibitions/${get(this.update, "exhibitionUid")}`;
    },
    title() {
      return get(this.update, "title", "");
    },
    venueTitle() {
      return get(this.update, "publisherOrVenue", "");
    },
    thumbnail() {
      return get(this.update, "thumbnail");
    },
  },
};
</script>
