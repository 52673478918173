const _columnBlockFields = `{
  images {
    artwork {
      title
      year
      details
      is_enquirable
      artist
      thumbnail
    }
    caption
    image
    width
  }
}`;
const _textBlockFields = `{
  body
}`;
const _videoBlockFields = `{
  title
  sources
  caption
  artwork {
    title
    year
    details
    artist
  }
}`;

export default {
  options: `{
    exhibition {
      title
      pdf
      artists {
        artist
      }
      external_artists {
        external_artist_thumbnail
        full_name
        link
      }
      artworks {
        artwork {
          title
          year
          details
          images
          is_enquirable
          artist
          thumbnail
        }
      }
      update {
        title
        start_date
        end_date
        display_date
        external_venue_url
        publisher_or_venue
        artists_label
        venue {
          title
          owner
        }
      }
      press_updates {
        press_update {
          ...on update {
            title
            thumbnail
            start_date
            end_date
            display_date
            external_venue_url
            publisher_or_venue
            link
            artists {
              artist {
                first_name
                last_name
              }
            }
          }
        }
      }
      blocks {
        block {
          ...on single_column_block ${_columnBlockFields}
          ...on double_column_block ${_columnBlockFields}
          ...on triple_column_block ${_columnBlockFields}
          ...on text_block ${_textBlockFields}
          ...on video_block ${_videoBlockFields}
        }
      }
    }
  }`,
};
