import Prismic from "prismic-javascript";

export default {
  query: {
    visible: () => {
      return [
        Prismic.Predicates.any("document.type", ["update"]),
        Prismic.Predicates.not("my.update.is_hidden", true),
      ];
    },
    fulltextSearch(query) {
      return [
        Prismic.Predicates.any("document.type", ["update"]),
        Prismic.Predicates.not("my.update.is_hidden", true),
        Prismic.Predicates.fulltext("document", query),
      ];
    },
    updateByArtist(id) {
      return [
        Prismic.Predicates.any("document.type", ["update"]),
        Prismic.Predicates.not("my.update.is_hidden", true),
        Prismic.Predicates.at("my.update.artists.artist", id),
      ];
    },
    updatesByArtistsByTag(artistIds, tagId) {
      return [
        Prismic.Predicates.any("document.type", ["update"]),
        Prismic.Predicates.not("my.update.is_hidden", true),
        Prismic.Predicates.any("my.update.artists.artist", artistIds),
        Prismic.Predicates.at("my.update.tags.tag", tagId),
      ];
    },
    updateByTag(id) {
      return [
        Prismic.Predicates.any("document.type", ["update"]),
        Prismic.Predicates.not("my.update.is_hidden", true),
        Prismic.Predicates.at("my.update.tags.tag", id),
      ];
    },
  },
  options: `{
    update {
      title
      tags {
        tag {
          title
        }
      }
      start_date
      end_date
      display_date
      thumbnail
      link
      publisher_or_venue
      external_venue_url
      exhibition
      artists_label
      artists {
        artist {
          first_name
          last_name
        }
      }
    }
  }`,
};
