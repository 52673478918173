<template>
  <GentleLoader
    v-bind:preloadContent="preloadContent"
    class="ArtworkBlock flex flex-col justify-center"
    v-bind:data-title="title"
  >
    <div class="flex flex-col">
      <router-link v-bind:to="artworkUrl">
        <ImageLoader
          v-bind:image="image"
          v-bind:altFallback="`A photo of the artwork titled ${title}.`"
          v-bind:isMobile="$mq === 'mobile'"
          fullWidth
          skipPreload
        />
      </router-link>
      <div v-if="hasCaption" v-bind:class="captionClass">
        <RichText :field="caption" class="serif mt1 md:mt1_5" />
      </div>
      <div class="flex flex-col md:flex-row md:justify-between mt1_25 md:mt1_5">
        <ArtworkPlaque
          v-bind:artistUid="artistUid"
          v-bind:baseUrl="baseUrl"
          v-bind:class="captionClass"
          v-bind:details="details"
          v-bind:title="title"
          v-bind:uid="uid"
          v-bind:year="year"
          v-bind:onSelect="onSelect"
        />
        <Button
          v-if="onSelect"
          class="ArtworkBlock__button Button--plus"
          activeClassName="Button--plus--active"
          v-bind:isActive="isSelected"
          v-bind:onPress="() => onSelect(uid)"
        >
          Select
        </Button>
      </div>
    </div>
  </GentleLoader>
</template>

<script>
import get from "lodash/get";

import ArtworkPlaque from "./ArtworkPlaque";
import Button from "./Button";
import ImageLoader from "./loaders/ImageLoader";
import GentleLoader from "./loaders/GentleLoader";
import RichText from "./RichText";

import hasRichText from "../utils/hasRichText";

export default {
  name: "ArtworkBlock",
  components: {
    ArtworkPlaque,
    Button,
    ImageLoader,
    GentleLoader,
    RichText,
  },
  props: {
    artistUid: {
      type: String,
      default: "",
    },
    baseUrl: {
      type: String,
      default: "",
    },
    caption: Array, // Prismic rich text
    captionClass: {
      type: String,
      default: "",
    },
    details: Array /* prismic rich text */,
    image: {
      /* prismic image field */
      url: String,
      alt: String,
      dimensions: {
        width: Number,
        height: Number,
      },
    },
    numberOfColumns: Number,
    title: String,
    uid: {
      type: String,
      default: "",
    },
    year: String,
    onSelect: Function,
  },
  computed: {
    artworkUrl() {
      return `${this.baseUrl}/works/${this.uid}`;
    },
    hasCaption() {
      return hasRichText(this.caption);
    },
    isSelected() {
      return this.$store.getters.isSelected(this.uid);
    },
  },
  methods: {
    preloadContent: function (didLoad) {
      if (get(this, "image.url")) {
        const loader = new window.Image();
        loader.src = "";
        loader.onload = didLoad;
        loader.src = get(this, "image.url");
      } else {
        console.error("[ArtworkBlock.preloadContent] Error:", this.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.ArtworkBlock__button {
  margin-top: $mobile-margin;
}
@media (min-width: map-get($breakpoints, "md")) {
  .ArtworkBlock__button {
    margin-top: $desk-margin;
  }
}
</style>
