<template>
  <div
    ref="artworkDetailPage"
    class="ArtworkDetailPage vh100 flex flex-col justify-stretch flex-1"
    :style="cssVars"
  >
    <!-- mobile -->
    <GentleLoader
      v-if="$mq === 'mobile'"
      v-bind:preloadContent="fetchPageContent"
      class="pb4"
      :style="cssVars"
    >
      <div class="flex">
        <RichText :field="label" className="subheading col-8 md:col-2 m1" />
        <div v-if="!isEnquiring" class="flex flex-1 justify-end pt_5 pr_5">
          <Button
            v-bind:onPress="goBack"
            aria-label="go back"
            class="flex flex-col"
          >
            <img
              class="ArtworkDetailPage__icon transition-opacity"
              src="../assets/icon/close-thin.svg"
            />
          </Button>
        </div>
      </div>
      <EnquiryForm
        v-if="isEnquiring"
        v-bind:artistFullName="artistFullName"
        v-bind:baseUrl="artworkUrl"
        v-bind:title="title"
        v-bind:label="label"
      />

      <ImageGallery
        v-else-if="title"
        v-bind:currentSlideIndex="currentSlideIndex"
        v-bind:slides="slides"
        v-bind:showPrevious="showPrevious"
        v-bind:showNext="showNext"
        v-bind:onClose="goBack"
        v-bind:containerRef="$refs.artworkDetailPage"
        class="flex-1 flex items-center"
      />
      <div
        v-else-if="!isLoading"
        class="flex flex-1 items-center justify-center"
      >
        <p>Artwork not found</p>
      </div>
      <div class="flex flex-col mt2 mb_5">
        <div
          v-if="!disableEnquiry && !isEnquiring && isEnquirable"
          class="flex justify-center"
        >
          <router-link
            class="Button--primary--filled"
            v-bind:to="`${artworkUrl}/enquire`"
            aria-label="Open enquiry request form"
          >
            Enquire
          </router-link>
        </div>
        <GalleryProgress
          v-if="!isEnquiring"
          v-bind:active="currentSlideIndex"
          v-bind:numberOfBars="slides.length"
          v-bind:onSelect="showSlide"
          v-bind:uid="uid"
          class="wrapper-1"
        />
      </div>
    </GentleLoader>

    <!-- Desktop -->
    <GentleLoader
      v-else
      v-bind:preloadContent="fetchPageContent"
      class="flex flex-col justify-stretch flex-1"
      :style="cssVars"
    >
      <div class="flex">
        <div
          class="ArtworkDetailPage__text-container wrapper-1 flex-1 flex flex-col t0 l0 z-2 md:col-3 body"
        >
          <div class="flex flex-col items-start">
            <RichText
              :field="label"
              className="subheading col-8 md:col-2 mb1"
            />

            <router-link
              v-if="!disableEnquiry && !isEnquiring && isEnquirable"
              class="Button--primary--filled mt2"
              v-bind:to="`${artworkUrl}/enquire`"
              aria-label="Open enquiry request form"
            >
              Enquire
            </router-link>
          </div>
        </div>
        <div
          v-if="!isEnquiring"
          class="absolute t0 r0 z-3 flex flex-1 justify-end"
        >
          <Button
            v-bind:onPress="goBack"
            aria-label="go back"
            class="flex flex-col"
          >
            <img
              class="ArtworkDetailPage__icon transition-opacity"
              src="../assets/icon/close-thin.svg"
            />
          </Button>
        </div>
      </div>

      <EnquiryForm
        v-if="isEnquiring"
        v-bind:artistFullName="artistFullName"
        v-bind:baseUrl="artworkUrl"
        v-bind:title="title"
        v-bind:label="label"
      />

      <ImageGallery
        v-else-if="title"
        v-bind:currentSlideIndex="currentSlideIndex"
        v-bind:slides="slides"
        v-bind:showPrevious="showPrevious"
        v-bind:showNext="showNext"
        v-bind:onClose="goBack"
        class="flex-1 flex items-center"
      />
      <div
        v-else-if="!isLoading"
        class="flex flex-1 items-center justify-center"
      >
        <p>Artwork not found</p>
      </div>

      <div class="flex flex-col my2 pb4">
        <div
          v-if="
            $mq === 'mobile' && !disableEnquiry && !isEnquiring && isEnquirable
          "
          class="flex justify-center"
        >
          <router-link
            class="Button--primary--filled"
            v-bind:to="`${artworkUrl}/enquire`"
            aria-label="Open enquiry request form"
          >
            Enquire
          </router-link>
        </div>
        <GalleryProgress
          v-if="!isEnquiring"
          v-bind:active="currentSlideIndex"
          v-bind:numberOfBars="slides.length"
          v-bind:onSelect="showSlide"
          v-bind:uid="uid"
          class="wrapper-1 absolute l0 b0"
        />
      </div>
    </GentleLoader>
  </div>
</template>

<script>
import get from "lodash/get";

import Cms from "../cms";
import Button from "../components/Button";
import EnquiryForm from "../components/forms/EnquiryForm";
import GalleryProgress from "../components/icons/GalleryProgress";
import GentleLoader from "../components/loaders/GentleLoader";
import ImageGallery from "../components/ImageGallery";
import RichText from "../components/RichText";
import { makeSlides } from "../utils/artwork";

export default {
  name: "ArtworkDetailPage",
  components: {
    Button,
    EnquiryForm,
    GalleryProgress,
    GentleLoader,
    ImageGallery,
    RichText,
  },
  props: {
    uid: String,
    baseUrl: {
      type: String,
      default: "",
    },
    disableEnquiry: Boolean,
    isEnquiring: Boolean,
  },
  data() {
    return {
      isLoading: true,
      artistUid: "",
      currentSlideIndex: 0,
      details: [],
      slides: [],
      isEnquirable: false,
      title: "",
      year: "",
    };
  },
  beforeMount() {
    this.$store.commit("setMainNavIsOpen", false);
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
    this.redirectIfNotEnquirable();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  watch: {
    uid() {
      this.redirectIfNotEnquirable();
    },
  },
  methods: {
    fetchPageContent(didLoad) {
      this.isLoading = true;
      Cms.fetchArtworkDetails(this.$prismic.client, this.uid, (data) => {
        this.artistUid = data.artistUid;
        this.details = data.details;
        this.isEnquirable = data.isEnquirable;
        this.title = data.title;
        this.year = data.year;
        this.isLoading = false;
        this.slides = makeSlides(data);
        didLoad();
      });
    },
    goBack() {
      if (this.baseUrl) {
        this.$router.replace(this.baseUrl);
        return;
      }
      this.$router.push("/");
    },
    showSlide(slideIndex) {
      if (slideIndex === this.currentSlideIndex) return;
      this.currentSlideIndex = slideIndex;
    },
    showPrevious() {
      const slideIndex =
        this.currentSlideIndex > 0
          ? this.currentSlideIndex - 1
          : this.slides.length - 1;
      this.showSlide(slideIndex);
    },
    showNext() {
      const slideIndex =
        this.currentSlideIndex + 1 < this.slides.length
          ? this.currentSlideIndex + 1
          : 0;
      this.showSlide(slideIndex);
    },
    redirectIfNotEnquirable() {
      if (this.isEnquiring && !this.isEnquirable) {
        this.$router.replace(this.artworkUrl);
      }
    },
  },
  computed: {
    artistFullName() {
      return get(
        this.$store.getters.artistDetails(this.artistUid),
        "fullName",
        ""
      );
    },
    artworkUrl() {
      return `${this.baseUrl}/works/${this.uid}`;
    },
    cssVars() {
      return {
        "--background-color":
          this.isEnquiring && this.$mq === "mobile"
            ? this.$store.getters.timelapseColor || "white"
            : "white",
      };
    },
    label() {
      return [
        {
          spans: [],
          text: this.artistFullName,
          type: "paragraph",
        },
        {
          spans: [],
          text: this.year ? `${this.title}, ${this.year}` : this.title,
          type: "paragraph",
        },
        ...get(this, "details", []),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.ArtworkDetailPage {
  background-color: var(--background-color);
  height: 100vh;
  overflow-y: scroll;

  &__icon {
    width: $mobile-icon-lg;
    margin: 11 * $mobile-sizing-coefficient 10 * $mobile-sizing-coefficient;
    &:hover {
      opacity: 0.2;
    }
  }
  &__image {
    max-height: 100%;
    max-width: 100%;
  }
  &__text-container {
    text-shadow: 1px 1px rgba(255, 255, 255, 0.9);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ArtworkDetailPage {
    position: relative;
    overflow-y: initial;
    &__icon {
      width: $desk-icon-lg;
      margin: $desk-icon-lg;
    }
    &__text-container {
      position: absolute;
      font-size: $desk-font-coefficient * map-get($fonts, "subheading");
    }
  }
}
</style>
