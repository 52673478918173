<template>
  <div class="flex flex-col small">
    <p>&copy; PHILLIDA REID GALLERY</p>
    <p>
      <router-link
        class="color-black text-decoration-none hover:opacity-0_4"
        to="/privacy-policy"
      >
        PRIVACY POLICY
      </router-link>
    </p>
    <p>
      WEBSITE BY
      <a
        class="color-black text-decoration-none hover:opacity-0_4"
        href="https://no-plans.com/"
        target="_blank"
        rel="noreferrer"
        >NO PLANS</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Byline",
};
</script>
