<template>
  <div class="PolicyPage displace-nav flex flex-col flex-1" :style="cssVars">
    <Navigation colorWhileCollapsed />
    <main class="PolicyPage__body flex-1 flex flex-col md:flex-row p1 md:p2">
      <RichText
        v-if="privacyPolicy"
        :field="privacyPolicy"
        className="PolicyPage__text wrapper-2 spacer-bottom-2 md:col-8"
      />
      <div class="flex-1 flex items-end">
        <Byline />
      </div>
    </main>
  </div>
</template>

<script>
import Byline from "../components/Byline";
import Navigation from "../components/Navigation";
import RichText from "../components/RichText";

export default {
  name: "PolicyPage",
  components: {
    Byline,
    Navigation,
    RichText,
  },
  computed: {
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
      };
    },
    privacyPolicy() {
      return this.$store.getters.privacyPolicy;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/styleguide/_sizing.scss";
.PolicyPage {
  background: var(--timelapse-color);
  min-height: 100vh;
}
</style>
