<template>
  <div class="MenuIcon flex flex-col">
    <img
      v-if="isOpen"
      class="MenuIcon__close"
      src="../../assets/icon/close-thin.svg"
    />
    <img v-else class="MenuIcon__menu" src="../../assets/icon/menu.svg" />
  </div>
</template>

<script>
export default {
  name: "MenuIcon",
  props: {
    isOpen: Boolean,
  },
};
</script>

<style lang="scss">
@import "../../styles/styleguide/_colors.scss";
@import "../../styles/styleguide/_sizing.scss";
.MenuIcon {
  width: $mobile-icon-xl;
}
@media (min-width: map-get($breakpoints, "md")) {
  .MenuIcon {
    width: $desk-icon-xl;
  }
}
</style>
