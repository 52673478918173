<template>
  <div
    class="TripleColumnBlock flex flex-col md:flex-row"
    v-bind:data-title="data.title"
  >
    <div class="flex flex-1 items-center justify-center">
      <ArtworkBlock
        v-if="columnOne.artworkUid"
        v-bind:artistUid="columnOne.artistUid"
        v-bind:baseUrl="baseUrl"
        v-bind:caption="columnOne.caption"
        v-bind:captionClass="columnOne.captionClass"
        v-bind:class="columnOne.colClass"
        v-bind:details="columnOne.details"
        v-bind:image="columnOne.image"
        v-bind:title="columnOne.title"
        v-bind:uid="columnOne.artworkUid"
        v-bind:year="columnOne.year"
        v-bind:onSelect="onSelectArtwork"
      />
      <ImageBlock
        v-else
        v-bind:image="columnOne.image"
        v-bind:caption="columnOne.caption"
        v-bind:class="columnOne.colClass"
      />
    </div>
    <div class="flex flex-1 items-center justify-center">
      <ArtworkBlock
        v-if="columnTwo.artworkUid"
        v-bind:artistUid="columnTwo.artistUid"
        v-bind:baseUrl="baseUrl"
        v-bind:caption="columnTwo.caption"
        v-bind:captionClass="columnTwo.captionClass"
        v-bind:class="columnTwo.colClass"
        v-bind:details="columnTwo.details"
        v-bind:image="columnTwo.image"
        v-bind:title="columnTwo.title"
        v-bind:uid="columnTwo.artworkUid"
        v-bind:year="columnTwo.year"
        v-bind:onSelect="onSelectArtwork"
      />
      <ImageBlock
        v-else
        v-bind:image="columnTwo.image"
        v-bind:caption="columnTwo.caption"
        v-bind:class="columnTwo.colClass"
      />
    </div>
    <div class="flex flex-1 items-center justify-center">
      <ArtworkBlock
        v-if="columnThree.artworkUid"
        v-bind:artistUid="columnThree.artistUid"
        v-bind:baseUrl="baseUrl"
        v-bind:caption="columnThree.caption"
        v-bind:captionClass="columnThree.captionClass"
        v-bind:class="columnThree.colClass"
        v-bind:details="columnThree.details"
        v-bind:image="columnThree.image"
        v-bind:title="columnThree.title"
        v-bind:uid="columnThree.artworkUid"
        v-bind:year="columnThree.year"
        v-bind:onSelect="onSelectArtwork"
      />
      <ImageBlock
        v-else
        v-bind:image="columnThree.image"
        v-bind:caption="columnThree.caption"
        v-bind:class="columnThree.colClass"
      />
    </div>
  </div>
</template>

<script>
import ArtworkBlock from "./ArtworkBlock";
import ImageBlock from "./ImageBlock";
import parseColumnData from "../utils/parseColumnData";

const DEFAULT_COLUMN_WIDTH = 4;
const COLUMN_SPACING_X = "wrapper-4";
const COLUMN_SPACING_Y = "spacer-bottom-2";

export default {
  name: "TripleColumnBlock",
  components: {
    ArtworkBlock,
    ImageBlock,
  },
  props: {
    data: {
      title: String,
      images: Array,
    },
    baseUrl: String,
    onSelectArtwork: Function,
  },
  computed: {
    columnOne() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        0
      );
    },
    columnTwo() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        1
      );
    },
    columnThree() {
      return parseColumnData(
        this.data,
        COLUMN_SPACING_X,
        COLUMN_SPACING_Y,
        DEFAULT_COLUMN_WIDTH,
        2
      );
    },
  },
};
</script>
