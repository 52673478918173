import { render, staticRenderFns } from "./GentleLoader.vue?vue&type=template&id=1395c223&scoped=true&"
import script from "./GentleLoader.vue?vue&type=script&lang=js&"
export * from "./GentleLoader.vue?vue&type=script&lang=js&"
import style0 from "./GentleLoader.vue?vue&type=style&index=0&id=1395c223&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1395c223",
  null
  
)

export default component.exports