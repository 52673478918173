const colorScale = {
  MIDNIGHT: {
    /* violet rgb(234, 224, 246) #eae0f6  */ red: 234,
    green: 224,
    blue: 246,
  },
  FOUR_AM: {
    /* blue rgb(223, 238, 251) #dfeefb */ red: 223,
    green: 238,
    blue: 251,
  },
  EIGHT_AM: {
    /* green rgb(230, 246, 231) #e6f6e7 */ red: 230,
    green: 246,
    blue: 231,
  },
  NOON: {
    /* yellow rgb(251, 246, 218) #fbf6da */ red: 251,
    green: 246,
    blue: 218,
  },
  FOUR_PM: {
    /* orange rgb(251, 238, 218) #fbeeda */ red: 251,
    green: 238,
    blue: 218,
  },
  EIGHT_PM: {
    /* pink rgb(250, 230, 229) #fae6e5 */ red: 250,
    green: 230,
    blue: 229,
  },
};

function getValueAtInterval(interval, start, finish) {
  const distance = finish - start;
  return Math.round(start + distance * interval);
}

function transitionColors(
  currentPosition,
  positionA,
  positionB,
  colorA,
  colorB
) {
  if (positionA > positionB)
    throw new Error("position A must be preceed position B");
  if (currentPosition < positionA || currentPosition > positionB)
    throw new Error("currentPosition must be between positions A and B");
  const interval = (currentPosition - positionA) / (positionB - positionA);
  return {
    red: getValueAtInterval(interval, colorA.red, colorB.red),
    green: getValueAtInterval(interval, colorA.green, colorB.green),
    blue: getValueAtInterval(interval, colorA.blue, colorB.blue),
  };
}

function printRgbColorString(r, g, b) {
  return `rgb(${r}, ${g}, ${b})`;
}

function printHslColorString(h, s, l) {
  return `hsl(${Math.round(h)}, ${Math.round(s * 100)}%, ${Math.round(
    l * 100
  )}%)`;
}

function parseRgbColorString(str) {
  return str.replace(/rgb\(|\)|\s/g, "").split(",");
}

export function getColorForTimeOfDay(currentTime /* js Date */) {
  const minutes = currentTime.getMinutes();
  let londonHours = currentTime.getUTCHours() + 1;
  if (londonHours > 23) londonHours = 0;

  const fractionalHours = londonHours + minutes / 60;
  const rounded = Math.round(fractionalHours * 100) / 100;

  let rgb;

  if (rounded === 0) {
    rgb = colorScale.MIDNIGHT;
  } else if (rounded < 4) {
    rgb = transitionColors(
      rounded,
      0,
      4,
      colorScale.MIDNIGHT,
      colorScale.FOUR_AM
    );
  } else if (rounded === 4) {
    rgb = colorScale.FOUR_AM;
  } else if (rounded < 8) {
    rgb = transitionColors(
      rounded,
      4,
      8,
      colorScale.FOUR_AM,
      colorScale.EIGHT_AM
    );
  } else if (rounded === 8) {
    rgb = colorScale.EIGHT_AM;
  } else if (rounded < 12) {
    rgb = transitionColors(
      rounded,
      8,
      12,
      colorScale.EIGHT_AM,
      colorScale.NOON
    );
  } else if (rounded === 12) {
    rgb = colorScale.NOON;
  } else if (rounded < 16) {
    rgb = transitionColors(
      rounded,
      12,
      16,
      colorScale.NOON,
      colorScale.FOUR_PM
    );
  } else if (rounded === 16) {
    rgb = colorScale.FOUR_PM;
  } else if (rounded < 20) {
    rgb = transitionColors(
      rounded,
      16,
      20,
      colorScale.FOUR_PM,
      colorScale.EIGHT_PM
    );
  } else if (rounded === 20) {
    rgb = colorScale.EIGHT_PM;
  } else {
    rgb = transitionColors(
      rounded,
      20,
      24,
      colorScale.EIGHT_PM,
      colorScale.MIDNIGHT
    );
  }

  return printRgbColorString(rgb.red, rgb.green, rgb.blue);
}

/* https://www.w3schools.com/lib/w3color.js */
function rgbToHsl(r, g, b) {
  var min,
    max,
    i,
    l,
    s,
    maxcolor,
    h,
    rgb = [];
  rgb[0] = r / 255;
  rgb[1] = g / 255;
  rgb[2] = b / 255;
  min = rgb[0];
  max = rgb[0];
  maxcolor = 0;
  for (i = 0; i < rgb.length - 1; i++) {
    if (rgb[i + 1] <= min) {
      min = rgb[i + 1];
    }
    if (rgb[i + 1] >= max) {
      max = rgb[i + 1];
      maxcolor = i + 1;
    }
  }
  if (maxcolor == 0) {
    h = (rgb[1] - rgb[2]) / (max - min);
  }
  if (maxcolor == 1) {
    h = 2 + (rgb[2] - rgb[0]) / (max - min);
  }
  if (maxcolor == 2) {
    h = 4 + (rgb[0] - rgb[1]) / (max - min);
  }
  if (isNaN(h)) {
    h = 0;
  }
  h = h * 60;
  if (h < 0) {
    h = h + 360;
  }
  l = (min + max) / 2;
  if (min == max) {
    s = 0;
  } else {
    if (l < 0.5) {
      s = (max - min) / (max + min);
    } else {
      s = (max - min) / (2 - max - min);
    }
  }

  return [h, s, l];
}

export function darkenColor(rgbColorString, k) {
  const rgb = parseRgbColorString(rgbColorString);
  const [h, s, l] = rgbToHsl(...rgb);
  const darkened = [h, s, l - k * 0.05];
  return printHslColorString(...darkened);
}

export function setDocumentBackgroundColor(backgroundColor) {
  if (document) {
    const html = document.getElementsByTagName("html")[0];

    if (html) {
      html.style.backgroundColor = backgroundColor;
    }
  }
}
