<template>
  <div class="HeroTestPage flex flex-1 flex-col container bg-color-pale-yellow">
    <div class="flex items-center p2">
      <h2 class="subheading">
        <strong>[REFERENCE ZONE]</strong> &mdash; Hero Test Kittens
      </h2>
    </div>

    <main class="HeroTestPage__body flex flex-1">
      <BlockSwitch v-if="blocks && blocks.length" v-bind:blocks="blocks" />
    </main>
  </div>
</template>

<script>
import { prismicDummyImage } from "../utils/placeholder";

function heroDummyBlock(id, width, height) {
  return {
    id: `hero-${id}`,
    type: "hero_block",
    data: {
      title: `Example ${id}`,
      subtitle: `${width}px x ${height}px`,
      image: prismicDummyImage(width, height),
    },
  };
}

export default {
  name: "HeroTestPage",
  data() {
    return {
      blocks: [
        heroDummyBlock(1, 900, 900),
        heroDummyBlock(2, 1200, 700),
        heroDummyBlock(3, 2500, 1200),
        heroDummyBlock(4, 900, 1600),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.HeroTestPage {
  min-height: 100vh;
  &__body {
    min-height: calc(100vh - 10vw);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .HeroTestPage__body {
    padding-bottom: 16rem;
  }
}
</style>
