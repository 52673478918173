<template>
  <prismic-rich-text
    v-if="Array.isArray(field)"
    :field="field"
    v-bind:class="className"
  />
</template>

<script>
export default {
  name: "RichText",
  props: {
    field: Array,
    className: {
      type: String,
      default: "RichText",
    },
  },
};
</script>

<style lang="scss">
@import "../styles/styleguide/_sizing.scss";
.RichText {
  h1 {
    @extend .title;
  }
  h2 {
    @extend .subtitle;
  }
  h3 {
    @extend .heading;
  }
  h4 {
    @extend .subheading;
  }
  h5,
  p,
  li {
    @extend .body;
  }
  h6 {
    @extend .detail;
  }
}
</style>
