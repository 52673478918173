import get from "lodash/get";

export function makeSlides(exhibition) {
  let id;
  return get(exhibition, "blocks", []).reduce(
    (slides, block) => {
      id = get(block, "id", "");
      slides.desk = slides.desk.concat({
        id,
        index: slides.desk.length,
        block,
      });
      if (block.type === "double_column_block") {
        slides.mobile = slides.mobile.concat([
          { id, block, index: slides.mobile.length, columnNumber: 1 },
          { id, block, index: slides.mobile.length + 1, columnNumber: 2 },
        ]);
      } else if (block.type === "triple_column_block") {
        slides.mobile = slides.mobile.concat([
          { id, block, index: slides.mobile.length, columnNumber: 1 },
          { id, block, index: slides.mobile.length + 1, columnNumber: 2 },
          { id, block, index: slides.mobile.length + 2, columnNumber: 3 },
        ]);
      } else {
        slides.mobile = slides.mobile.concat({
          id,
          index: slides.mobile.length,
          block,
        });
      }
      return slides;
    },
    {
      desk: [{ id: "title", index: 0 }],
      mobile: [{ id: "title", index: 0 }],
    }
  );
}
