<template>
  <div v-bind:class="link ? 'Table__row--linked flex' : 'Table__row flex'">
    <a
      v-if="link"
      v-bind:href="link"
      target="_blank"
      rel="noreferrer"
      class="flex justify-start items-stretch color-black text-decoration-none"
    >
      <ImageLoader
        class="Table__image"
        v-if="thumbnail && thumbnail.url"
        v-bind:image="thumbnail"
        v-bind:altFallback="`An example of ${fullName}'s work`"
      />
      <div class="Table__image Table__image-fallback" v-else />
      <div class="subtitle--table flex flex-1 items-center pl2">
        <p class="overflow-ellipsis">{{ fullName }}</p>
      </div>
      <div
        v-if="$mq === 'desktop'"
        class="subtitle--table flex items-center col-8 px2 relative"
      ></div>
    </a>
    <div
      v-else
      class="flex justify-start items-stretch color-black text-decoration-none"
    >
      <ImageLoader
        class="Table__image"
        v-if="thumbnail && thumbnail.url"
        v-bind:image="thumbnail"
        v-bind:altFallback="`An example of ${fullName}'s work`"
      />
      <div class="Table__image Table__image-fallback" v-else />
      <div class="subtitle--table flex flex-1 items-center pl2">
        <p class="overflow-ellipsis">{{ fullName }}</p>
      </div>
      <div
        v-if="$mq === 'desktop'"
        class="subtitle--table flex items-center col-8 px2 relative"
      ></div>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import ImageLoader from "../../loaders/ImageLoader";

export default {
  name: "ExternalArtistRow",
  components: {
    ImageLoader,
  },
  props: {
    artist: {
      fullName: String,
      link: String,
      thumbnail: /* prismic image field */ {
        url: String,
        alt: String,
        dimensions: {
          width: Number,
          height: Number,
        },
      },
    },
  },
  methods: {},
  computed: {
    fullName() {
      return get(this.artist, "fullName", "");
    },

    thumbnail() {
      return get(this.artist, "thumbnail");
    },
    link() {
      return get(this.artist, "link");
    },
  },
};
</script>
