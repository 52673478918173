<template>
  <div class="ArtistsSubnav" :style="cssVars">
    <div class="flex flex-col">
      <div
        class="ArtistsSubnav__row flex justify-center items-center border-bottom"
        v-for="group in groupedArtists"
        v-bind:key="group.id"
      >
        <router-link
          v-for="artist in group.items"
          v-bind:key="artist.uid"
          v-bind:to="artist.url"
          class="color-black serif body text-decoration-none hover:opacity-0_4 wrapper-1"
        >
          {{ artist.fullName }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import chunkArray from "../../utils/chunkArray";
const MAX_PER_LINE = 8;
const MAX_PER_LINE_MOBILE = 3;
export default {
  name: "ArtistsMiniDesktop",
  computed: {
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
      };
    },
    groupedDesktopArtists() {
      return chunkArray(this.$store.getters.artistsByLastName, MAX_PER_LINE);
    },
    groupedMobileArtists() {
      return chunkArray(
        this.$store.getters.artistsByLastName,
        MAX_PER_LINE_MOBILE
      );
    },
    groupedArtists() {
      return this.$mq === "desktop"
        ? this.groupedDesktopArtists
        : this.groupedMobileArtists;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.ArtistsSubnav {
  background-color: var(--timelapse-color);
  &__row {
    height: $mobile-nav-top-bar-height;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ArtistsSubnav {
    &__row {
      height: $desk-nav-top-bar-height;
      padding-top: 0;
    }
    &__title {
      height: 70 * $desk-sizing-coefficient;
    }
  }
}
</style>
