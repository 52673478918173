export default `{
  global_settings {
    uid
    enquiries_email_address
    enquiries_instructions
    enquiries_disclaimer
    mailchimp_user_id
    mailchimp_list_id
    main_nav_links {
      label
      to
      is_target_blank
    }
    follow_links {
      label
      link
    }
    not_found_image
    not_found_body
    privacy_policy
  }
}`;
