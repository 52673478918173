<template>
  <div
    class="Footer flex flex-col md:flex-row transition-bg-color"
    :style="cssVars"
    v-if="venues && venues.length > 0"
  >
    <div
      v-for="venue in venues"
      v-bind:key="venue.id"
      class="Footer__column flex flex-row md:flex-col md:border-right body border-top sans-serif md:serif"
    >
      <div class="Footer__venue wrapper-2 flex flex-col flex-1">
        <p>{{ venue.title }}</p>
        <div class="my1">
          <ImageLoader
            v-if="isDesktop && venue.featuredExhibitionUpdate"
            class="Footer__image--desktop"
            v-bind:image="venue.featuredExhibitionUpdate.thumbnail"
            v-bind:altFallback="`A photo from the ${venue.featuredExhibitionUpdate.title} exhibition at ${venue.title}.`"
            constrainHeight
          />
        </div>
        <RichText :field="venue.address" />
        <RichText
          v-bind:class="venue.isClosed ? 'color-black-0_6' : ''"
          :field="venue.hours"
        />
        <RichText
          v-if="venue.isClosed"
          class="body color-black-0_6"
          :field="venue.closedMessage"
        />
        <p class="mt1">{{ venue.phoneNumber }}</p>
      </div>

      <div class="flex-1 flex justify-end wrapper-2" v-if="!isDesktop">
        <ImageLoader
          v-if="venue.featuredExhibitionUpdate"
          class="Footer__image--mobile"
          v-bind:image="venue.featuredExhibitionUpdate.thumbnail"
          v-bind:altFallback="`A photo from the ${venue.featuredExhibitionUpdate.title} exhibition at ${venue.title}.`"
          constrainHeight
        />
      </div>
    </div>
    <div class="Footer__column flex flex-col body border-top">
      <div class="Footer__form flex flex-col">
        <p class="Form__row sans-serif md:serif">Mailing List</p>
        <NewsletterSignupForm />
      </div>
      <div class="Footer__contact">
        <a
          v-for="link in followLinks"
          v-bind:key="link.link.url"
          v-bind:href="link.link.url"
          target="_blank"
          rel="noreferrer"
          class="Form__row Footer__link Button--secondary sans-serif md:serif"
        >
          {{ link.label }}
        </a>
        <p
          v-if="enquiriesEmailAddress"
          class="Form__row Footer__link Button--secondary sans-serif md:serif"
        >
          {{ enquiriesEmailAddress }}
        </p>
      </div>
      <Byline class="Footer__byline wrapper-2 mt4" />
    </div>
  </div>
</template>

<script>
import Byline from "./Byline";
import ImageLoader from "./loaders/ImageLoader";
import NewsletterSignupForm from "./forms/NewsletterSignupForm";
import RichText from "./RichText";

export default {
  name: "Footer",
  components: {
    Byline,
    ImageLoader,
    NewsletterSignupForm,
    RichText,
  },
  computed: {
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
    enquiriesEmailAddress() {
      return this.$store.getters.enquiriesEmailAddress;
    },
    followLinks() {
      return this.$store.getters.followLinks;
    },
    venues() {
      return this.$store.getters.venuesByTitle;
    },
    isDesktop() {
      return this.$mq === "desktop";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/styleguide/_spacing.scss";
@import "../styles/styleguide/_sizing.scss";
.Footer {
  background-color: var(--timelapse-color);

  &__image {
    &--desktop {
      height: $desk-footer-image-height;
    }
    &--mobile {
      height: $mobile-footer-image-height;
    }
  }

  &__column {
    &:first-of-type {
      border-top: none;
    }
  }

  &__link:hover {
    background-color: var(--timelapse-color-darkened);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .Footer {
    &__column {
      border-top: none;
      flex: 1 1 0px;
      &:first-of-type {
        .Footer__venue {
          padding-left: 20 * $desk-sizing-coefficient;
        }
      }
    }
    &__contact {
      order: 0;
    }
    &__form {
      order: 1;
      border-bottom: none;
    }
    &__byline {
      order: 3;
    }
  }
}
</style>
