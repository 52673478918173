<template>
  <div
    class="TextSlide flex flex-col flex-1 justify-center items-center wrapper-2"
    :style="cssVars"
    @click="onClick"
  >
    <RichText className="TextSlide__text serif" :field="body" />
  </div>
</template>

<script>
import get from "lodash/get";
import RichText from "../../components/RichText";

export default {
  name: "TextSlide",
  components: {
    RichText,
  },
  props: {
    isPlaying: Boolean,
    data: {
      body: Array /* prismic rich text */,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    body() {
      return get(this.data, "body", []);
    },
    cssVars() {
      return {
        "--padding-x":
          !this.isPlaying && this.$mq === "desktop" ? "80px" : "2.76vw",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/styleguide/_sizing.scss";
.TextSlide {
  padding: 40 * $mobile-sizing-coefficient;
  text-indent: 3em;

  &__text {
    font-size: 16 * $mobile-font-coefficient;
    line-height: 22 * $mobile-font-coefficient;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .TextSlide {
    padding-left: var(--padding-x);
    padding-right: var(--padding-x);
    text-indent: 5em;

    &__text {
      font-size: $desk-font-coefficient * map-get($fonts, "heading");
      line-height: $desk-font-coefficient * map-get($line-heights, "heading");
    }
  }
}
</style>
