export default {
  query: '[any(document.type,["tag"])]',
  options: `{
    tag {
      title
      is_filterable
      order
    }
  }`,
};
