<template>
  <div class="ExhibitionPage flex flex-1 flex-col bg-color-white container">
    <Exhibition
      v-if="stage === 'REQUEST_EXHIBITION_SUCCESS'"
      v-bind:uid="uid"
      v-bind:playButtonDisabled="showSlideshow || showArtwork"
    />
    <div
      v-if="stage === 'REQUEST_EXHIBITION_SUCCESS' && showArtwork"
      class="overlay--fixed z3"
    >
      <ArtworkDetailPage
        v-bind:uid="artworkUid"
        v-bind:baseUrl="`/exhibitions/${this.uid}`"
        v-bind:isEnquiring="isEnquiring"
      />
    </div>
    <ExhibitionSlideShow
      v-else-if="stage === 'REQUEST_EXHIBITION_SUCCESS' && showSlideshow"
      v-bind:columnNumber="columnNumber"
      v-bind:mobile="mobile"
      v-bind:slide="slide"
      v-bind:uid="uid"
    />
    <div
      v-if="stage === 'REQUEST_EXHIBITION_FAILURE'"
      class="ExhibitionPage__body flex flex-1 flex-col displace-nav"
    >
      <Navigation isTransparent />
      <div class="flex flex-col flex-1 border-bottom">
        <p v-if="stage === 'REQUEST_EXHIBITION_FAILURE'">
          Something went wrong, please reload and try again
        </p>
        <p
          v-if="stage === 'REQUEST_EXHIBITION_PENDING'"
          class="text-center spacer-top-1 spacer-bottom-1"
        >
          Loading...
        </p>
        <NotFound
          isTransparent
          v-if="stage === 'REQUEST_EXHIBITION_NOT_FOUND'"
        />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Exhibition from "../components/Exhibition";
import ExhibitionSlideShow from "../components/ExhibitionSlideShow";

import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import NotFound from "../components/NotFound";

import ArtworkDetailPage from "./ArtworkDetailPage";

const Stages = {
  REQUEST_EXHIBITION_IDLE: "REQUEST_EXHIBITION_IDLE",
  REQUEST_EXHIBITION_PENDING: "REQUEST_EXHIBITION_PENDING",
  REQUEST_EXHIBITION_FAILURE: "REQUEST_EXHIBITION_FAILURE",
  REQUEST_EXHIBITION_SUCCESS: "REQUEST_EXHIBITION_SUCCESS",
  REQUEST_EXHIBITION_NOT_FOUND: "REQUEST_EXHIBITION_NOT_FOUND",
};

export default {
  name: "ExhibitionPage",
  components: {
    ArtworkDetailPage,
    Exhibition,
    ExhibitionSlideShow,
    Footer,
    Navigation,
    NotFound,
  },
  props: {
    artworkUid: String,
    columnNumber: String,
    mobile: String,
    isEnquiring: Boolean,
    showArtwork: Boolean,
    showSlideshow: Boolean,
    slide: String,
    uid: String,
  },
  data() {
    return {
      stage: Stages.REQUEST_EXHIBITION_IDLE,
    };
  },
  mounted() {
    this.$store.commit("setMainNavIsOpen", false);
    this.stage = this._getInitialStage();
    if (this.stage === Stages.REQUEST_EXHIBITION_IDLE) {
      try {
        this._fetchPageContent(this.uid);
      } catch (e) {
        this.stage = Stages.VALIDATION_FAILURE;
      }
    }
  },
  watch: {
    uid() {
      /* When the page slug changes */
      this.$store.commit("setMainNavIsOpen", false);
      this.scrollToTop();
      this.stage = this._getInitialStage();
      if (this.stage === Stages.REQUEST_EXHIBITION_IDLE) {
        try {
          this._fetchPageContent(this.uid);
        } catch (e) {
          this.stage = Stages.VALIDATION_FAILURE;
        }
      }
    },
  },
  methods: {
    fetchPageContentIfNeeded() {
      if (this.uid && this.$store.getters.exhibition(this.uid).id) {
        this._didLoad();
      } else if (this.uid) {
        this._fetchPageContent(this.uid);
      }
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    _didLoad(data) {
      this.stage =
        data && data.id
          ? Stages.REQUEST_EXHIBITION_SUCCESS
          : Stages.REQUEST_EXHIBITION_NOT_FOUND;
    },
    _fetchPageContent(uid) {
      this.stage = Stages.REQUEST_EXHIBITION_PENDING;
      this.$store.commit("fetchAndPersistExhibition", {
        prismicClient: this.$prismic.client,
        uid,
        didLoad: this._didLoad,
      });
    },
    _getInitialStage() {
      if (this.uid && this.$store.getters.exhibition(this.uid).id) {
        return Stages.REQUEST_EXHIBITION_SUCCESS;
      } else {
        return Stages.REQUEST_EXHIBITION_IDLE;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.ExhibitionPage {
  min-height: 100vh;
  &__body {
    min-height: 100vh;
  }
  &__button {
    bottom: $mobile-button-height;
    left: 50%;
    transform: translateX(-50%);
    position: var(--button-position);
  }
  &__text {
    margin-top: 20 * $mobile-sizing-coefficient;
    margin-bottom: 2 * $mobile-margin;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ExhibitionPage {
    &__button {
      bottom: $desk-button-height;
    }
    &__header {
      padding-top: 4 * $desk-margin;
    }
    &__text {
      margin-top: 20 * $desk-sizing-coefficient;
      margin-bottom: 2 * $desk-margin;
    }
  }
}
</style>
