<template>
  <div class="EnquiryForm absolute flex z-4 t0 b0 l0 r0" :style="cssVars">
    <div class="flex flex-col justify-center relative md:col-4 md:border-right">
      <div class="flex flex-1 justify-between md:justify-end">
        <RichText
          v-if="$mq === 'mobile'"
          class="EnquiryForm__label flex flex-col col-8"
          :field="label"
        />
        <router-link
          v-bind:to="backLink"
          aria-label="close enquiry request form"
          class="flex flex-col"
        >
          <img
            class="EnquiryForm__icon transition-opacity"
            src="../../assets/icon/close-thin.svg"
          />
        </router-link>
      </div>

      <form class="flex flex-col spacer-bottom-1">
        <h1 class="EnquiryForm__title subheading sans-serif color-black">
          Enquiry
        </h1>
        <label for="firstName" class="Form__field flex-col">
          <span class="sr-only">First Name</span>
          <input
            class="Form__input"
            v-model="firstName.value"
            placeholder="First Name"
            v-bind:disabled="showSuccessMessage"
          />
        </label>
        <p v-if="firstName.hasError" class="Form__error--inline">
          Please enter your first name.
        </p>

        <label for="lastName" class="Form__field flex-col">
          <span class="sr-only">Last Name</span>
          <input
            class="Form__input"
            v-model="lastName.value"
            placeholder="Last Name"
            v-bind:disabled="showSuccessMessage"
          />
        </label>
        <p v-if="lastName.hasError" class="Form__error--inline">
          Please enter your last name.
        </p>

        <label for="email" class="Form__field flex-col">
          <span class="sr-only">Email</span>
          <input
            class="Form__input"
            v-model="email.value"
            placeholder="Email"
            v-bind:disabled="showSuccessMessage"
          />
        </label>
        <p v-if="email.hasError" class="Form__error--inline">
          Please enter a valid email address.
        </p>

        <label for="phone" class="Form__field flex-col">
          <span class="sr-only">Phone</span>
          <input
            class="Form__input"
            v-model="phone.value"
            placeholder="Phone"
            v-bind:disabled="showSuccessMessage"
          />
        </label>

        <RichText
          className="Form__help-text wrapper-2"
          :field="enquiriesDisclaimer"
        />
      </form>
      <div
        class="flex-1 flex flex-col-reverse md:flex-col items-center md:items-start justify-end md:justify-start wrapper-2"
      >
        <Button
          class="Button--primary"
          v-bind:onPress="_onSubmit"
          role="submit"
          aria-label="submit"
          v-bind:disabled="showSuccessMessage || isMakingRequest"
        >
          <span v-if="isMakingRequest">Sending...</span>
          <span v-else-if="showSuccessMessage">Enquiry Sent</span>
          <span v-else>Send Enquiry</span>
        </Button>
        <div class="EnquiryForm__text-container flex-1">
          <p v-if="showSuccessMessage" class="Form__help-text">
            Thank you! You will be contacted via email or phone shortly.
          </p>
          <p v-else-if="showSubmissionError" class="Form__help-text">
            Something went wrong, please try again.
          </p>
          <p v-else-if="showValidationError" class="Form__help-text">
            Please fill out all required fields to submit your enquiry.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import Button from "../Button";
import RichText from "../RichText";

import Emailer from "../../utils/emailer";
import validate from "../../utils/validators";
const FieldNames = ["firstName", "lastName", "email", "phone"];

export default {
  name: "EnquiryForm",
  components: {
    Button,
    RichText,
  },
  props: {
    artistFullName: String,
    baseUrl: String,
    label: Array,
    title: String,
  },
  mounted() {
    this.emailer = new Emailer();
  },
  data() {
    return {
      emailer: null,
      isMakingRequest: false,
      showValidationError: false,
      showSubmissionError: false,
      showSuccessMessage: false,
      firstName: {
        value: "",
        hasError: false,
        validator: "string",
      },
      lastName: {
        value: "",
        hasError: false,
        validator: "string",
      },
      email: {
        value: "",
        hasError: false,
        validator: "email",
      },
      phone: {
        value: "",
        hasError: false,
      },
    };
  },
  computed: {
    backLink() {
      if (this.baseUrl) return this.baseUrl;
      return `/works/${this.uid}`;
    },
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
      };
    },
    enquiriesDisclaimer() {
      return this.$store.getters.enquiriesDisclaimer;
    },
    uid() {
      if (get(this, "$route.params.artworkUid")) {
        return this.$route.params.artworkUid;
      }
      return get(this, "$route.params.uid");
    },
  },
  methods: {
    _validateAndEvaluateFormFields() {
      const formData = {};
      let fieldName;
      let hasError = false;

      for (var i = 0; i < FieldNames.length; i++) {
        fieldName = FieldNames[i];
        if (validate(this[fieldName].value, this[fieldName].validator)) {
          formData[fieldName] = this[fieldName].value;
          if (this[fieldName].hasError) this[fieldName].hasError = false;
        } else {
          if (!hasError) hasError = true;
          if (!this[fieldName].hasError) this[fieldName].hasError = true;
        }
      }

      if (!hasError) {
        return formData;
      } else {
        return false;
      }
    },
    _onSubmit(e) {
      if (e) e.preventDefault();
      this.showSuccessMessage = false;
      this.showSubmissionError = false;

      const formData = this._validateAndEvaluateFormFields();
      this.showValidationError = formData === false;
      if (formData) {
        this.isMakingRequest = true;
        this.emailer.sendArtworkEnquiryEmail({
          enquirerFirstName: formData.firstName,
          enquirerLastName: formData.lastName,
          enquirerPhoneNumber: formData.phone,
          enquirerEmail: formData.email,
          artistFullName: this.artistFullName,
          artworkTitle: this.title,
          artworkUid: this.uid,
          origin: get(window, "location.origin", "unknown"),
          onSuccess: this._onSuccess,
          onError: this._onError,
        });
      }
    },
    _onError(error) {
      console.error("[EnquiryForm] Error:", error);
      this.showSubmissionError = true;
      this.isMakingRequest = false;
    },
    _onSuccess() {
      this.showSuccessMessage = true;
      this.isMakingRequest = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/styleguide/_sizing.scss";
.EnquiryForm {
  background-color: var(--timelapse-color);

  &__icon {
    width: $mobile-icon-lg;
    margin: $mobile-margin;
    &:hover {
      opacity: 0.2;
    }
  }

  &__label,
  &__title {
    margin: $mobile-margin;
  }

  &__text-container {
    padding: 2 * $mobile-margin $mobile-margin;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .EnquiryForm {
    top: 0;
    left: calc(1 / 3 * 100vw);

    &__title {
      margin: 0 $desk-margin 2 * $desk-margin $desk-margin;
    }

    &__icon {
      width: $desk-icon-lg;
      margin: $desk-icon-lg;
    }

    &__text-container {
      padding: 2 * $desk-margin 0;
    }
  }
}
</style>
