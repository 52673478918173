<template>
  <div
    class="ArtistsTable Table flex flex-col overflow-x-hidden spacer-bottom-2"
    :style="cssVars"
  >
    <div class="wrapper-3 border-bottom">
      <p class="detail sans-serif uppercase py_25">Artists</p>
    </div>
    <ArtistRow
      v-for="artist in artists"
      v-bind:key="artist.id"
      v-bind:artist="artist"
      class="transition-bg-color"
    />
    <ExternalArtistRow
      v-for="artist in externalArtists"
      v-bind:key="artist.fullName.replace(/\W/g, '')"
      v-bind:artist="artist"
      class="transition-bg-color"
    />
  </div>
</template>

<script>
import ArtistRow from "./rows/ArtistRow";
import ExternalArtistRow from "./rows/ExternalArtistRow";

export default {
  name: "ArtistsTable",
  components: {
    ArtistRow,
    ExternalArtistRow,
  },
  props: {
    artistUids: Array || null,
    externalArtists: Array,
    variant: String,
    perPage: Number,
    isInfinite: Boolean,
  },
  computed: {
    artists() {
      if (this.artistUids) {
        return this.artistUids
          .map((uid) => {
            return this.$store.getters.artistDetails(uid);
          })
          .sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
      }
      return this.$store.getters.artistsByLastName;
    },
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
        "--timelapse-color-darkest":
          this.$store.getters.timelapseColorDarkest || "#aaa",
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/styleguide/_sizing.scss";
.Table {
  background-color: var(--timelapse-color);
  &__row:hover {
    background-color: var(--timelapse-color-darkened);
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .ArtistsTable {
    padding-bottom: 400 * $desk-sizing-coefficient; // For overlays
  }
}
</style>
