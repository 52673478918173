export default {
  options: `{
    artwork {
      title
      year
      details
      description
      is_enquirable
      artist
      images
      video {
        title
        sources
      }
    }
  }`,
};
