<template>
  <GentleLoader
    v-bind:preloadContent="preloadContent"
    class="ImageBlock flex flex-col justify-center"
  >
    <div class="flex flex-col">
      <ImageLoader
        v-bind:image="image"
        v-bind:isMobile="$mq === 'mobile'"
        fullWidth
        skipPreload
      />
      <div v-bind:class="captionClass">
        <RichText v-if="caption" :field="caption" class="serif my1 md:my1_5" />
      </div>
    </div>
  </GentleLoader>
</template>

<script>
import get from "lodash/get";
import ImageLoader from "./loaders/ImageLoader";
import GentleLoader from "./loaders/GentleLoader";
import RichText from "./RichText";

export default {
  name: "ImageBlock",
  components: {
    ImageLoader,
    GentleLoader,
    RichText,
  },
  props: {
    image: {
      /* prismic image field */
      url: String,
      alt: String,
      dimensions: {
        width: Number,
        height: Number,
      },
    },
    caption: Array, // Prismic rich text
    captionClass: {
      type: String,
      default: "",
    },
    numberOfColumns: Number,
  },
  methods: {
    preloadContent: function (didLoad) {
      if (get(this, "image.url")) {
        const loader = new window.Image();
        loader.src = "";
        loader.onload = didLoad;
        loader.src = get(this, "image.url");
      } else {
        didLoad();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fullBleed {
  width: 100vw;
}
</style>
