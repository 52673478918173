<template>
  <div class="ArtworksTable flex flex-col spacer-bottom-2" :style="cssVars">
    <div
      v-for="chunk in chunkedArtworks"
      v-bind:key="chunk.id"
      class="border-bottom flex flex-row flex-wrap"
    >
      <ArtworkCell
        v-for="artwork in chunk.items"
        v-bind:key="artwork.uid"
        v-bind:artwork="artwork"
        v-bind:baseUrl="baseUrl"
        class="ArtworksTable__cell transition-bg-color pointer"
      />
    </div>
  </div>
</template>

<script>
import ArtworkCell from "./rows/ArtworkCell";
import chunkArray from "../../utils/chunkArray";
export default {
  name: "ArtworksTable",
  components: {
    ArtworkCell,
  },
  props: {
    artworks: Array,
    baseUrl: String,
  },
  computed: {
    chunkedArtworks() {
      return chunkArray(this.artworks, this.$mq === "desktop" ? 6 : 2);
    },
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/styleguide/_colors.scss";
@import "../../styles/styleguide/_sizing.scss";
.ArtworksTable {
  background-color: var(--timelapse-color);
  &__cell:hover {
    background-color: var(--timelapse-color-darkened);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ArtworksTable__row {
    border-bottom: 1px solid color("black-0_2");
  }
}
</style>
