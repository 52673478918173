var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExhibitionSlideSwitch flex flex-col flex-1",style:(_vm.cssVars)},[(_vm.activeSlide && _vm.activeSlide.id === 'title')?_c('TitleSlide',{staticClass:"transition-opacity-long ExhibitionSlideSwitch__slide",attrs:{"title":_vm.artistNames,"subtitle":_vm.exhibition.title,"copy":_vm.venueTitle,"detail":_vm.dateLabel,"onClick":_vm.onToggle}}):(
      !!_vm.activeSlide &&
      !!_vm.activeSlide.block &&
      _vm.activeSlide.block.type === 'text_block'
    )?_c('TextSlide',{staticClass:"transition-opacity-long ExhibitionSlideSwitch__slide",attrs:{"data":_vm.activeSlide.block.data,"isPlaying":_vm.isPlaying,"onClick":_vm.onToggle}}):(
      _vm.$mq === 'mobile' &&
      _vm.activeSlide &&
      _vm.activeSlide.block &&
      (_vm.activeSlide.block.type === 'single_column_block' ||
        _vm.activeSlide.block.type === 'double_column_block' ||
        _vm.activeSlide.block.type === 'triple_column_block')
    )?_c('MobileImageSlide',{staticClass:"transition-opacity-long ExhibitionSlideSwitch__slide",attrs:{"columnNumber":_vm.columnNumber,"data":_vm.activeSlide.block.data,"isPlaying":_vm.isPlaying,"type":_vm.activeSlide.block.type,"onClick":_vm.onToggle}}):(
      _vm.activeSlide &&
      _vm.activeSlide.block &&
      _vm.activeSlide.block.type === 'single_column_block'
    )?_c('ImageSlide',{staticClass:"transition-opacity-long ExhibitionSlideSwitch__slide",attrs:{"type":_vm.activeSlide.block.type,"data":_vm.activeSlide.block.data,"isPlaying":_vm.isPlaying,"onClick":_vm.onToggle}}):(
      _vm.activeSlide &&
      _vm.activeSlide.block &&
      _vm.activeSlide.block.type === 'double_column_block'
    )?_c('DoubleImageSlide',{staticClass:"transition-opacity-long ExhibitionSlideSwitch__slide",attrs:{"type":_vm.activeSlide.block.type,"data":_vm.activeSlide.block.data,"isPlaying":_vm.isPlaying,"onClick":_vm.onToggle}}):(
      _vm.activeSlide &&
      _vm.activeSlide.block &&
      _vm.activeSlide.block.type === 'triple_column_block'
    )?_c('TripleImageSlide',{staticClass:"transition-opacity-long ExhibitionSlideSwitch__slide",attrs:{"type":_vm.activeSlide.block.type,"data":_vm.activeSlide.block.data,"isPlaying":_vm.isPlaying,"onClick":_vm.onToggle}}):(
      _vm.activeSlide &&
      _vm.activeSlide.block &&
      _vm.activeSlide.block.type === 'video_block'
    )?_c('VideoSlide',{staticClass:"transition-opacity-long ExhibitionSlideSwitch__slide",attrs:{"type":_vm.activeSlide.block.type,"data":_vm.activeSlide.block.data,"isPlaying":_vm.isPlaying,"didReceiveDuration":_vm.setSlideDuration,"onClick":_vm.onToggle}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }