<template>
  <div class="TextBlock flex flex-col wrapper-3 spacer-bottom-3">
    <RichText :field="body" className="TextBlock__text subheading" />
  </div>
</template>

<script>
import get from "lodash/get";

import RichText from "./RichText";

export default {
  name: "TextBlock",
  components: {
    RichText,
  },
  props: {
    data: {
      body: Array /* prismic rich text */,
    },
  },
  computed: {
    body() {
      return get(this.data, "body", []);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.TextBlock {
  text-indent: 3em;
}
@media (min-width: map-get($breakpoints, "md")) {
  .TextBlock {
    &__text {
      font-size: $desk-font-coefficient * map-get($fonts, "subtitle");
      line-height: $desk-font-coefficient * map-get($line-heights, "subtitle");
      text-indent: 5em;
    }
  }
}
</style>
