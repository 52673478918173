<template>
  <div class="ViewingSelectionsWidget flex flex-row-reverse fixed z-3">
    <Button class="Button--primary--black" v-bind:onPress="onPress">
      Selection {{ selectionLength }}
    </Button>
    <ImageLoader
      v-for="artwork in selection"
      v-bind:key="artwork.uid"
      class="ViewingSelectionsWidget__image"
      v-bind:image="artwork.thumbnail"
      v-bind:altFallback="`Thumbnail for ${artwork.title}.`"
      constrainHeight
    />
  </div>
</template>

<script>
import get from "lodash/get";
import Button from "./Button";
import ImageLoader from "./loaders/ImageLoader";

export default {
  name: "ViewingSelectionsWidget",
  components: {
    Button,
    ImageLoader,
  },
  props: {
    selectedArtworks: Array,
    onPress: Function,
  },
  computed: {
    selection() {
      return this.selectedArtworks.map((artwork) => {
        return {
          ...artwork,
          thumbnail: artwork.thumbnail
            ? artwork.thumbnail
            : get(artwork, "images[0]"),
        };
      });
    },
    selectionLength() {
      if (!this.selectedArtworks.length) return "";
      return `— ${this.selectedArtworks.length}`;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/styleguide/_sizing.scss";
.ViewingSelectionsWidget {
  top: $desk-margin;
  right: $desk-margin;
  &__image {
    height: $desk-button-height;
  }
}
</style>
