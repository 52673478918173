<template>
  <div class="GalleryProgress flex">
    <Button
      v-for="bar in bars"
      v-bind:key="bar.index"
      :class="`GalleryProgress__bar-wrapper ${
        bar.index === active ? 'GalleryProgress__active' : ''
      }`"
      v-bind:onPress="() => onSelect(bar.index)"
    >
      <div class="GalleryProgress__bar transition-bg-color mt_5" />
    </Button>
  </div>
</template>

<script>
import Button from "../Button";
export default {
  name: "GalleryProgress",
  components: {
    Button,
  },
  props: {
    active: Number,
    numberOfBars: Number,
    onSelect: Function,
    uid: String,
  },
  computed: {
    bars() {
      let bars = new Array(this.numberOfBars);
      for (var i = 0; i < this.numberOfBars; i++) {
        bars[i] = {
          index: i,
          active: this.active === i,
        };
      }
      return bars;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/styleguide/_colors.scss";
@import "../../styles/styleguide/_sizing.scss";
.GalleryProgress {
  &__bar {
    height: 1 * $mobile-sizing-coefficient;
    width: 10 * $mobile-sizing-coefficient;
    background-color: color("black-0_2");
    &:hover {
      background-color: color("black");
    }
  }
  &__bar-wrapper {
    margin-right: 5 * $mobile-sizing-coefficient;
  }
  &__active .GalleryProgress__bar {
    background-color: color("black");
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .GalleryProgress {
    &__bar {
      height: 1 * $desk-sizing-coefficient;
      width: 10 * $desk-sizing-coefficient;
    }
    &__bar-wrapper {
      margin-right: 5 * $desk-sizing-coefficient;
    }
  }
}
</style>
