<template>
  <GentleLoader
    ref="wrapper"
    v-bind:preloadContent="preloadContent"
    classNames="Hero flex flex-1 border-box border-bottom relative"
  >
    <div class="Hero__content flex flex-1 flex-col" :style="cssVars">
      <div v-if="isPortrait" class="Hero__image--portrait overflow-hidden">
        <prismic-image :field="data.image" />
      </div>
      <div v-else class="Hero__image overflow-hidden relative">
        <div class="absolute-center">
          <prismic-image :field="data.image" />
        </div>
      </div>

      <a
        v-if="typeof data.link === 'string'"
        v-bind:href="data.link"
        class="text-decoration-none"
      >
        <div
          class="Hero__text flex flex-col items-start wrapper-3 md:spacer-top-1 spacer-bottom-1 l0 r0 z1"
          ref="textbox"
        >
          <div class="color-black hover:opacity-0_4 transition-opacity-medium">
            <h1 v-if="data.title" class="title">{{ data.title }}</h1>
            <h2 v-if="data.subtitle" class="title italic">
              {{ data.subtitle }}
            </h2>
            <RichText
              v-if="data.body"
              :field="data.body"
              className="subheading sans-serif mt1"
            />
          </div>
        </div>
      </a>
      <prismic-link
        v-else-if="typeof data.link === 'object'"
        :field="data.link"
        v-bind:target="data.link.link_type === 'Web' ? '_blank' : ''"
        class="text-decoration-none"
      >
        <div
          class="Hero__text flex flex-col items-start wrapper-3 md:spacer-top-1 spacer-bottom-1 l0 r0 z1"
          ref="textbox"
        >
          <div class="hover:opacity-0_4 transition-opacity-medium">
            <h1 v-if="data.title" class="title">{{ data.title }}</h1>
            <h2 v-if="data.subtitle" class="title italic">
              {{ data.subtitle }}
            </h2>
            <RichText
              v-if="hasBody"
              :field="data.body"
              className="subheading sans-serif mt1"
            />
          </div>
        </div>
      </prismic-link>
      <div
        v-else
        class="Hero__text flex flex-col items-start wrapper-3 md:spacer-top-1 spacer-bottom-1 l0 r0 z1"
        ref="textbox"
      >
        <h1 v-if="data.title" class="title">{{ data.title }}</h1>
        <h2 v-if="data.subtitle" class="title italic">{{ data.subtitle }}</h2>
        <RichText
          v-if="hasBody"
          :field="data.body"
          className="subheading sans-serif blend-difference mt1"
        />
      </div>
    </div>
  </GentleLoader>
</template>

<script>
import get from "lodash/get";
import GentleLoader from "./loaders/GentleLoader";
import RichText from "./RichText";

import hasRichText from "../utils/hasRichText";

const NAV_HEIGHT = 50;
const ScrollPhases = {
  BEFORE: "before",
  FOCUS: "focus",
  AFTER: "after",
};
export default {
  name: "Hero",
  components: {
    GentleLoader,
    RichText,
  },
  props: {
    data: {
      body: Array,
      image: {
        alt: String,
        url: String,
      },
      link: String || Object,
      subtitle: String,
      title: String,
    },
  },
  data() {
    /* Parallax state machine
      1. "before" 
          viewport scroll position above wrapper; 
          text positioned absolutely at top of block
      2. "focus"
          text fixed while rest of page scrolls
      3. "after" 
          textbox bottom coincides with wrapper bottom
          --> assume 'after' if on mount viewport position is below 
              bottom of textbox at initial place
    */
    return {
      scrollPhase: ScrollPhases.BEFORE,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScrollOrResize);
    window.addEventListener("resize", this.onScrollOrResize);
    this.scrollPhase = this.getScrollPhase();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollOrResize);
    window.removeEventListener("resize", this.onScrollOrResize);
  },
  methods: {
    onScrollOrResize() {
      if (this.$mq === "mobile") return;
      const scrollPhase = this.getScrollPhase();
      if (this.scrollPhase !== scrollPhase) this.scrollPhase = scrollPhase;
    },
    _isAboveFocus() {
      return this.$refs.wrapper.$el.getBoundingClientRect().y > NAV_HEIGHT;
    },
    _isBelowFocus() {
      const wrapper = this.$refs.wrapper.$el.getBoundingClientRect();
      const textbox = this.$refs.textbox.getBoundingClientRect();
      return wrapper.y + wrapper.height < textbox.height + NAV_HEIGHT;
    },
    getScrollPhase() {
      if (this._isAboveFocus()) return ScrollPhases.BEFORE;
      if (this._isBelowFocus()) return ScrollPhases.AFTER;
      return ScrollPhases.FOCUS;
    },
    preloadContent: function (didLoad) {
      if (get(this, "data.image.url")) {
        const loader = new window.Image();
        loader.src = "";
        loader.onload = didLoad;
        loader.src = get(this, "data.image.url");
      } else {
        console.error("err", this.data);
      }
    },
  },
  watch: {
    artists: function () {
      /* When the artists list changes, recalc textbox position */
      this.scrollPhase = this.getScrollPhase();
    },
  },
  computed: {
    artists() {
      return this.$store.getters.artistsByLastName;
    },
    cssVars() {
      return {
        "--text-color":
          get(this, "data.is_white_text") && this.$mq !== "mobile"
            ? "#fff"
            : "#000",
        "--textbox-position":
          this.scrollPhase == ScrollPhases.FOCUS ? "fixed" : "absolute",
        "--textbox-top":
          this.scrollPhase === ScrollPhases.AFTER
            ? "auto"
            : this.scrollPhase === ScrollPhases.FOCUS
            ? `${NAV_HEIGHT}px`
            : "0",
        "--textbox-bottom":
          this.scrollPhase === ScrollPhases.AFTER ? "0" : "auto",
      };
    },
    hasBody() {
      return hasRichText(this.data.body);
    },
    isPortrait() {
      const width = get(this, "data.image.dimensions.width", 0);
      const height = get(this, "data.image.dimensions.height", 0);
      return height > width;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.Hero {
  &__image {
    width: 100vw;
    height: $mobile-hero-min-height;
    img {
      min-width: 100vw;
      height: $mobile-hero-min-height;
    }
    &--portrait {
      width: 100vw;
      height: $mobile-hero-min-height;
      img {
        width: 100vw;
        min-height: $mobile-hero-min-height;
      }
    }
  }
  &__text {
    color: var(--text-color);
  }
}

@media (min-width: map-get($breakpoints, "md")) {
  .Hero {
    &__content {
      height: $desk-hero-min-height;
      max-height: $desk-hero-max-height;
    }
    &__text {
      position: var(--textbox-position);
      top: var(--textbox-top);
      bottom: var(--textbox-bottom);
    }
  }
}
</style>
