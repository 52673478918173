<template>
  <div class="ContactPage displace-nav flex flex-col flex-1" :style="cssVars">
    <Navigation colorWhileCollapsed />
    <main
      v-if="venues && venues.length > 0"
      class="flex flex-col md:flex-row flex-1"
    >
      <div
        v-for="venue in venues"
        v-bind:key="venue.id"
        class="ContactPage__column flex flex-1 flex-col body md:border-right"
      >
        <div
          class="ContactPage__venue-details flex wrapper-2 flex-col border-bottom"
        >
          <p class="ContactPage__section-title">{{ venue.title }}</p>
          <div class="ContactPage__item flex flex-col">
            <RichText class="mt1" :field="venue.address" />
            <p class="mt1 body">{{ venue.phoneNumber }}</p>
            <RichText
              v-bind:class="venue.isClosed ? 'mt1 color-black-0_6' : 'mt1'"
              :field="venue.hours"
            />
            <RichText
              v-if="venue.isClosed"
              class="body color-black-0_6"
              :field="venue.closedMessage"
            />
          </div>
        </div>
        <div
          v-if="venue.featuredExhibitionUpdate"
          class="flex border-bottom wrapper-2 ContactPage__link"
        >
          <router-link
            class="flex color-black text-decoration-none ContactPage__item"
            v-bind:to="`/exhibitions/${venue.featuredExhibitionUpdate.exhibitionUid}`"
          >
            <ImageLoader
              class="ContactPage__image"
              v-bind:image="venue.featuredExhibitionUpdate.thumbnail"
              v-bind:altFallback="`A photo from the ${venue.featuredExhibitionUpdate.title} exhibition at ${venue.title}.`"
              fullHeight
            />
            <div class="flex-1 body mx1">
              <p>{{ venue.featuredExhibitionUpdate.artistsLabel }}</p>
              <p class="italic">{{ venue.featuredExhibitionUpdate.title }}</p>
              <p>{{ venue.featuredExhibitionUpdate.dateLabel }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="ContactPage__column flex flex-1 flex-col subheading">
        <p class="ContactPage__section-title wrapper-2 border-bottom">
          Mailing List
        </p>
        <NewsletterSignupForm />
        <p class="ContactPage__section-title wrapper-2 border-bottom">Follow</p>
        <a
          v-for="link in followLinks"
          v-bind:key="link.link.url"
          v-bind:href="link.link.url"
          target="_blank"
          rel="noreferrer"
          class="Button--secondary Form__row"
        >
          {{ link.label }}
        </a>
        <p class="ContactPage__section-title wrapper-2 border-bottom">
          General Enquiries
        </p>
        <p class="Button--secondary Form__row">
          {{ enquiriesEmailAddress }}
        </p>
        <div class="wrapper-2 body border-bottom">
          <RichText
            v-if="enquiriesInstructions"
            :field="enquiriesInstructions"
            class="ContactPage__item"
          />
        </div>
        <div class="flex-1 flex items-end wrapper-2 spacer-top-2">
          <Byline />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Byline from "../components/Byline";
import ImageLoader from "../components/loaders/ImageLoader";
import Navigation from "../components/Navigation";
import NewsletterSignupForm from "../components/forms/NewsletterSignupForm";
import RichText from "../components/RichText";

export default {
  name: "ContactPage",
  components: {
    Byline,
    ImageLoader,
    Navigation,
    NewsletterSignupForm,
    RichText,
  },
  beforeMount() {
    this.$store.commit("setMainNavIsOpen", false);
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  computed: {
    cssVars() {
      return {
        "--timelapse-color": this.$store.getters.timelapseColor || "white",
        "--timelapse-color-darkened":
          this.$store.getters.timelapseColorDarkened || "#f2f2f2",
      };
    },
    enquiriesEmailAddress() {
      return this.$store.getters.enquiriesEmailAddress;
    },
    enquiriesInstructions() {
      return this.$store.getters.enquiriesInstructions;
    },
    followLinks() {
      return this.$store.getters.followLinks;
    },
    venues() {
      return this.$store.getters.venuesByTitle;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/styleguide/_colors.scss";
@import "../styles/styleguide/_sizing.scss";
.ContactPage {
  background: var(--timelapse-color);
  min-height: 100vh;
  &__image {
    height: $mobile-footer-image-height;
  }
  &__link:hover {
    background-color: var(--timelapse-color-darkened);
  }
  &__section-title {
    margin-top: $mobile-spacer;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ContactPage {
    &__venue-details {
      min-height: 287 * $desk-sizing-coefficient;
    }
    &__image {
      height: $desk-footer-image-height;
    }
    &__section-title {
      margin-top: $desk-margin;
    }
    &__column {
      font-size: $desk-font-coefficient * map-get($fonts, "subheading");
      line-height: $desk-font-coefficient * map-get($line-heights, "subheading");

      &:first-of-type {
        .ContactPage__section-title,
        .ContactPage__item {
          padding-left: 25 * $desk-sizing-coefficient;
        }
      }
      &:last-of-type {
        .ContactPage__section-title,
        .ContactPage__item {
          padding-right: 25 * $desk-sizing-coefficient;
        }
      }
    }
  }
}
</style>
