<template>
  <div class="PrivateViewing flex-1 flex flex-col" :style="cssVars">
    <Navigation isTransparent v-if="!playButtonDisabled" />
    <ViewingSelectionsWidget
      v-if="$mq === 'desktop' && !playButtonDisabled"
      v-bind:selectedArtworks="selectedArtworks"
      v-bind:onPress="scrollToTable"
    />
    <header
      v-if="this.privateViewing.id"
      class="PrivateViewing__header flex flex-col items-start spacer-bottom-2 wrapper-3 spacer-top-1"
    >
      <h2 class="title italic">{{ privateViewing.title }}</h2>
      <h1 v-if="privateViewing.clientName" class="subtitle mt1">
        For {{ privateViewing.clientName }}
      </h1>

      <a
        v-if="privateViewing.pdf"
        v-bind:href="privateViewing.pdf"
        target="_blank"
        rel="noreferrer"
        class="Button--primary md:mt2 mt1 py_25 px1_25 small sans-serif uppercase"
      >
        Download Pdf
      </a>
    </header>
    <main
      v-if="this.privateViewing.id"
      class="PrivateViewing__body flex flex-1 border-bottom"
    >
      <div class="flex flex-col relative">
        <div
          class="PrivateViewing__button flex flex-col items-center justify-center z-2"
        >
          <router-link
            v-if="!playButtonDisabled && blocks && blocks.length > 0"
            :to="{
              path: `/private-viewings/${uid}/slideshow`,
              query: {
                slide: '0',
                mobile: $mq === 'mobile',
              },
            }"
            class="Button--play"
          >
            Start Viewing
          </router-link>
        </div>
        <BlockSwitch
          v-bind:blocks="blocks"
          ref="blockSwitch"
          v-bind:onSelectArtwork="onSelect"
          v-bind:baseUrl="`/private-viewings/${uid}`"
        />
      </div>
    </main>

    <ViewingSelectionsTable
      v-if="this.privateViewing.id"
      ref="table"
      v-bind:privateViewingUid="uid"
      v-bind:isMakingRequest="isMakingRequest"
      v-bind:showSuccessMessage="showSuccessMessage"
      v-bind:showFailureMessage="showFailureMessage"
      v-bind:onSelect="onSelect"
      v-bind:onSubmit="onSubmit"
      v-bind:selection="selection"
    />
  </div>
</template>

<script>
import get from "lodash/get";
import Navigation from "./Navigation";
import ViewingSelectionsTable from "./table/ViewingSelectionsTable";
import ViewingSelectionsWidget from "./ViewingSelectionsWidget";

import Emailer from "../utils/emailer";

export default {
  name: "PrivateViewing",
  components: {
    Navigation,
    ViewingSelectionsTable,
    ViewingSelectionsWidget,
  },
  props: {
    uid: String,
    playButtonDisabled: Boolean,
  },
  data() {
    return {
      emailer: null,
      isMakingRequest: false,
      scrolledToFooter: false,
      showSuccessMessage: false,
      showFailureMessage: false,
    };
  },
  mounted() {
    this.$store.commit("setMainNavIsOpen", false);
    this.$store.commit("resetSelection");
    this.emailer = new Emailer();
    this._onScrollOrResize();
    window.addEventListener("scroll", this._onScrollOrResize);
    window.addEventListener("resize", this._onScrollOrResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this._onScrollOrResize);
    window.removeEventListener("resize", this._onScrollOrResize);
  },
  watch: {
    uid() {
      this.$store.commit("resetSelection");
    },
  },
  computed: {
    cssVars() {
      return {
        "--button-position": this.scrolledToFooter ? "absolute" : "fixed",
      };
    },
    blocks() {
      return this.$store.getters.privateViewingBlocks(this.uid);
    },
    privateViewing() {
      return this.$store.getters.privateViewing(this.uid);
    },
    selection() {
      return this.$store.getters.privateViewingSelection;
    },
    selectedArtworks() {
      let artwork;
      let artist;
      return this.selection.map((uid) => {
        artwork = this.privateViewing.artworks.list[uid];
        artist = this.$store.getters.artistDetails(artwork.artistUid);
        return {
          ...artwork,
          artist,
        };
      });
    },
  },
  methods: {
    onSelect(artworkUid) {
      if (this.selection.includes(artworkUid)) {
        this.$store.commit("removeFromSelection", artworkUid);
      } else {
        if (this.errorMessage) this.errorMessage = "";
        this.$store.commit("addToSelection", artworkUid);
      }
    },
    onSubmit() {
      if (this.isMakingRequest || this.showSuccessMessage) return;
      this.isMakingRequest = true;
      this.emailer.sendPrivateViewingSelectionEmail({
        clientName: this.privateViewing.clientName,
        clientEmail: this.privateViewing.clientEmail,
        clientPhoneNumber: this.privateViewing.clientPhoneNumber,
        privateViewingUid: this.uid,
        selectedArtworks: this.selectedArtworks,
        origin: get(window, "location.origin", "unknown"),
        onSuccess: this._onSuccess,
        onError: this._onFailure,
      });
    },
    scrollToTable() {
      if (!this.$refs.table) return;
      this.$refs.table.$el.scrollIntoView(true);
    },
    _onScrollOrResize() {
      if (!this.$refs.blockSwitch) return;
      const blockSwitch = this.$refs.blockSwitch.$el.getBoundingClientRect();
      this.scrolledToFooter = window.innerHeight >= blockSwitch.bottom;
    },
    _onSuccess() {
      this.isMakingRequest = false;
      this.showSuccessMessage = true;
    },
    _onFailure(error) {
      this.isMakingRequest = false;
      this.showFailureMessage = true;
      console.error("[PrivateViewing::onSubmit] Error:", error);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.PrivateViewing {
  min-height: 100vh;
  &__button {
    bottom: $mobile-button-height;
    left: 50%;
    transform: translateX(-50%);
    position: var(--button-position);
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .PrivateViewing {
    &__button {
      bottom: $desk-button-height;
    }
    &__header {
      padding-top: 4 * $desk-margin;
    }
  }
}
</style>
