import get from "lodash/get";

import artistPage from "./queries/artistPage";
import artists from "./queries/artists";
import artwork from "./queries/artwork";
import exhibitionPage from "./queries/exhibitionPage";
import genericPage from "./queries/genericPage";
import privateViewing from "./queries/privateViewing";
import globalSettings from "./queries/globalSettings";
import shopPage from "./queries/shopPage";
import tags from "./queries/tags";
import updates from "./queries/updates";
import venues from "./queries/venues";
import { formatDate, formatRange } from "../utils/date";

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const Cms = {
  fetchArtistPageContent(prismicClient, uid, onSuccess) {
    prismicClient
      .getByUID("artist", uid, {
        graphQuery: artistPage.options,
      })
      .then((document) => {
        let blocks;
        if (Array.isArray(get(document, "data.blocks"))) {
          blocks = get(document, "data.blocks").reduce((memo, blockData) => {
            if (get(blockData, "block.id")) {
              if (get(blockData, "block.isBroken")) {
                console.error("[BROKEN Block]", blockData);
              } else {
                memo.push({
                  id: get(blockData, "block.id"),
                  type: get(blockData, "block.type"),
                  data: get(blockData, "block.data", {}),
                });
              }
            }
            return memo;
          }, []);
        }
        onSuccess({
          id: get(document, "id"),
          fullName:
            get(document, "data.first_name", "") +
            " " +
            get(document, "data.last_name", ""),
          blocks: blocks || EMPTY_ARRAY,
        });
      });
  },
  fetchArtists(prismicClient, onSuccess) {
    prismicClient
      .query(artists.query, {
        orderings: "[my.artist.last_name]",
        graphQuery: artists.options,
      })
      .then((document) => {
        let response;
        if (Array.isArray(get(document, "results"))) {
          response = get(document, "results").map((artist) => ({
            id: get(artist, "id"),
            uid: get(artist, "uid"),
            url: `/artists/${get(artist, "uid")}`,
            firstName: get(artist, "data.first_name"),
            lastName: get(artist, "data.last_name"),
            shortBio: get(artist, "data.short_bio"),
            cv: get(artist, "data.cv.url"),
            thumbnail: get(artist, "data.thumbnail"),
          }));
        } else {
          response = EMPTY_ARRAY;
        }

        onSuccess(response);
      });
  },
  fetchArtworkDetails(prismicClient, uid, onSuccess) {
    prismicClient
      .getByUID("artwork", uid, {
        graphQuery: artwork.options,
      })
      .then((document) => {
        let video;
        if (get(document, "data.video")) {
          video = {
            id: get(document, "data.video.id"),
            title: get(document, "data.video.data.title"),
            sources: get(document, "data.video.data.sources", []),
          };
        }

        onSuccess({
          artistUid: get(document, "data.artist.uid"),
          details: get(document, "data.details"),
          description: get(document, "data.description"),
          images: get(document, "data.images", EMPTY_ARRAY).map((image) =>
            get(image, "image")
          ),
          isEnquirable: get(document, "data.is_enquirable", false),
          title: get(document, "data.title"),
          video,
          year: get(document, "data.year"),
        });
      });
  },
  fetchExhibitionPageContent(prismicClient, uid, onSuccess) {
    prismicClient
      .getByUID("exhibition", uid, {
        graphQuery: exhibitionPage.options,
      })
      .then((document) => {
        let artists;
        if (Array.isArray(get(document, "data.artists"))) {
          artists = document.data.artists.reduce((memo, artist) => {
            if (get(artist, "artist.uid")) {
              memo.push({
                id: get(artist, "artist.id"),
                uid: get(artist, "artist.uid"),
              });
            }
            return memo;
          }, []);
        }

        let externalArtists;
        if (Array.isArray(get(document, "data.external_artists"))) {
          externalArtists = document.data.external_artists.reduce(
            (memo, artist) => {
              if (get(artist, "full_name")) {
                memo.push({
                  fullName: get(artist, "full_name"),
                  link: get(artist, "link.url", ""),
                  thumbnail: get(artist, "external_artist_thumbnail"),
                });
              }
              return memo;
            },
            []
          );
        }

        let artworks;
        if (Array.isArray(get(document, "data.artworks"))) {
          artworks = document.data.artworks.reduce((arr, artwork) => {
            if (get(artwork, "artwork.uid")) {
              arr.push({
                uid: get(artwork, "artwork.uid"),
                artistUid: get(artwork, "artwork.data.artist.uid"),
                details: get(artwork, "artwork.data.details"),
                images: get(
                  artwork,
                  "artwork.data.images",
                  EMPTY_ARRAY
                ).map((image) => get(image, "image")),
                isEnquirable: get(artwork, "artwork.data.is_enquirable"),
                title: get(artwork, "artwork.data.title"),
                thumbnail: get(artwork, "artwork.data.thumbnail"),
                year: get(artwork, "artwork.data.year"),
              });
            }
            return arr;
          }, []);
        }

        let blocks;
        if (Array.isArray(get(document, "data.blocks"))) {
          blocks = get(document, "data.blocks").reduce((memo, blockData) => {
            if (get(blockData, "block.id")) {
              if (get(blockData, "block.isBroken")) {
                console.error("[BROKEN Block]", blockData);
              } else {
                memo.push({
                  id: get(blockData, "block.id"),
                  type: get(blockData, "block.type"),
                  data: get(blockData, "block.data", EMPTY_OBJECT),
                });
              }
            }
            return memo;
          }, []);
        }

        let pressUpdates;
        if (Array.isArray(get(document, "data.press_updates"))) {
          pressUpdates = document.data.press_updates.reduce((memo, item) => {
            if (!get(item, "press_update.id")) return memo;
            let artists;
            if (Array.isArray(get(item, "press_update.data.artists"))) {
              artists = item.press_update.data.artists.reduce(
                (memo, artist) => {
                  if (artist && get(artist, "artist.uid") && !artist.isBroken) {
                    memo.push({
                      uid: artist.artist.uid,
                      firstName: get(artist, "artist.data.first_name"),
                      lastName: get(artist, "artist.data.last_name"),
                    });
                  }
                  return memo;
                },
                []
              );
            }
            memo.push({
              id: get(item, "press_update.id"),
              type: get(item, "press_update.type"),
              artists: artists || EMPTY_ARRAY,
              displayDate: get(item, "press_update.data.display_date"),
              endDate: get(item, "press_update.data.end_date"),
              link: get(item, "press_update.data.link.url"),
              publisherOrVenue: get(
                item,
                "press_update.data.publisher_or_venue"
              ),
              startDate: get(item, "press_update.data.start_date"),
              title: get(item, "press_update.data.title"),
              thumbnail: get(item, "press_update.data.thumbnail"),
            });
            return memo;
          }, []);
        }

        onSuccess({
          id: get(document, "id"),
          artists: artists || EMPTY_ARRAY,
          artistsLabel: get(document, "data.update.data.artists_label", ""),
          artworks: artworks || EMPTY_ARRAY,
          blocks: blocks || EMPTY_ARRAY,
          description: get(document, "data.description", EMPTY_ARRAY),
          displayDate: get(document, "data.update.data.display_date", ""),
          endDate: get(document, "data.update.data.end_date", ""),
          externalArtists: externalArtists || EMPTY_ARRAY,
          externalVenueUrl: get(
            document,
            "data.update.data.external_venue_url.url"
          ),
          pdf: get(document, "data.pdf.url", ""),
          pressUpdates: pressUpdates || EMPTY_ARRAY,
          publisherOrVenue: get(
            document,
            "data.update.data.publisher_or_venue"
          ),
          startDate: get(document, "data.update.data.start_date", ""),
          title: get(document, "data.title", ""),
          venueOwner: get(document, "data.update.data.venue.data.owner", ""),
          venueTitle: get(document, "data.update.data.venue.data.title", ""),
        });
      });
  },
  fetchPrivateViewing(prismicClient, uid, password, onSuccess) {
    prismicClient
      .query(privateViewing.query(uid, password), {
        graphQuery: privateViewing.options,
      })
      .then((document) => {
        const privateViewing = get(document, "results[0]");
        let artworks;
        if (Array.isArray(get(privateViewing, "data.artworks"))) {
          artworks = privateViewing.data.artworks.reduce((arr, artwork) => {
            if (get(artwork, "artwork.uid")) {
              arr.push({
                uid: get(artwork, "artwork.uid"),
                artistUid: get(artwork, "artwork.data.artist.uid"),
                details: get(artwork, "artwork.data.details"),
                images: get(
                  artwork,
                  "artwork.data.images",
                  EMPTY_ARRAY
                ).map((image) => get(image, "image")),
                isEnquirable: get(artwork, "artwork.data.is_enquirable"),
                title: get(artwork, "artwork.data.title"),
                thumbnail: get(artwork, "artwork.data.thumbnail"),
                year: get(artwork, "artwork.data.year"),
              });
            }
            return arr;
          }, []);
        }

        let blocks;
        if (Array.isArray(get(privateViewing, "data.content_blocks"))) {
          blocks = get(privateViewing, "data.content_blocks").reduce(
            (memo, blockData) => {
              if (get(blockData, "content_block.id")) {
                if (get(blockData, "content_block.isBroken")) {
                  console.error("[BROKEN Block]", blockData);
                } else {
                  memo.push({
                    id: get(blockData, "content_block.id"),
                    type: get(blockData, "content_block.type"),
                    data: get(blockData, "content_block.data", EMPTY_OBJECT),
                  });
                }
              }
              return memo;
            },
            []
          );
        }

        onSuccess({
          id: get(privateViewing, "id"),
          artworks: artworks || EMPTY_ARRAY,
          blocks: blocks || EMPTY_ARRAY,
          clientName: get(privateViewing, "data.client_name", ""),
          clientEmail: get(privateViewing, "data.client_email", ""),
          clientPhoneNumber: get(
            privateViewing,
            "data.client_phone_number",
            ""
          ),
          pdf: get(privateViewing, "data.pdf.url", ""),
          title: get(privateViewing, "data.title", ""),
        });
      });
  },
  fetchUpdates(
    prismicClient,
    sortBy,
    sortDirection,
    page,
    pageSize,
    onSuccess
  ) {
    prismicClient
      .query(updates.query.visible(), {
        graphQuery: updates.options,
        orderings: `[
          my.update.${sortBy}${sortDirection < 0 ? " desc" : ""},
        ]`,
        page,
        pageSize,
      })
      .then((document) => {
        const response = {
          page: document.page,
          perPage: document.results_per_page,
          hasMore: document.page < document.total_pages,
          results: this._parseUpdatesResults(document.results),
        };
        onSuccess(response);
      });
  },
  fetchUpdatesByTag(
    prismicClient,
    id,
    sortBy,
    sortDirection,
    page,
    pageSize,
    onSuccess
  ) {
    prismicClient
      .query(updates.query.updateByTag(id), {
        graphQuery: updates.options,
        orderings: `[
          my.update.${sortBy}${sortDirection < 0 ? " desc" : ""},
        ]`,
        page,
        pageSize,
      })
      .then((document) => {
        const response = {
          page: document.page,
          perPage: document.results_per_page,
          hasMore: document.page < document.total_pages,
          results: this._parseUpdatesResults(document.results),
        };
        onSuccess(response);
      });
  },
  fetchUpdatesByArtist(
    prismicClient,
    uid,
    sortBy,
    sortDirection,
    page,
    pageSize,
    onSuccess
  ) {
    prismicClient
      .query(updates.query.updateByArtist(uid), {
        graphQuery: updates.options,
        orderings: `[
          my.update.${sortBy}${sortDirection < 0 ? " desc" : ""},
        ]`,
        page,
        pageSize,
      })
      .then((document) => {
        const response = {
          page: document.page,
          perPage: document.results_per_page,
          hasMore: document.page < document.total_pages,
          results: this._parseUpdatesResults(document.results),
        };
        onSuccess(response);
      });
  },
  fetchUpdatesByTagForArtists(
    prismicClient,
    artistIds,
    tagId,
    sortBy,
    sortDirection,
    page,
    pageSize,
    onSuccess
  ) {
    prismicClient
      .query(updates.query.updatesByArtistsByTag(artistIds, tagId), {
        graphQuery: updates.options,
        orderings: `[
          my.update.${sortBy}${sortDirection < 0 ? " desc" : ""},
        ]`,
        page,
        pageSize,
      })
      .then((document) => {
        const response = {
          page: document.page,
          perPage: document.results_per_page,
          hasMore: document.page < document.total_pages,
          results: this._parseUpdatesResults(document.results),
        };
        onSuccess(response);
      });
  },
  searchUpdates(
    prismicClient,
    query,
    sortBy,
    sortDirection,
    page,
    pageSize,
    onSuccess
  ) {
    prismicClient
      .query(updates.query.fulltextSearch(query), {
        graphQuery: updates.options,
        orderings: `[
          my.update.${sortBy}${sortDirection < 0 ? " desc" : ""},
        ]`,
        page,
        pageSize,
      })
      .then((document) => {
        const response = {
          page: document.page,
          perPage: document.results_per_page,
          hasMore: document.page < document.total_pages,
          results: this._parseUpdatesResults(document.results),
        };
        onSuccess(response);
      });
  },
  _parseUpdatesResults(results) {
    if (!Array.isArray(results)) return EMPTY_ARRAY;
    return results.reduce((arr, item) => {
      let artists;
      if (Array.isArray(get(item, "data.artists"))) {
        artists = item.data.artists.reduce((memo, artist) => {
          if (artist && get(artist, "artist.uid") && !artist.isBroken) {
            memo.push({
              uid: artist.artist.uid,
              firstName: get(artist, "artist.data.first_name"),
              lastName: get(artist, "artist.data.last_name"),
            });
          }
          return memo;
        }, []);
      }

      return arr.concat({
        type: get(item, "type"),
        tags: get(item, "data.tags"),
        id: get(item, "id"),
        title: get(item, "data.title"),
        startDate: get(item, "data.start_date"),
        endDate: get(item, "data.end_date"),
        displayDate: get(item, "data.display_date"),
        exhibitionUid: get(item, "data.exhibition.uid"),
        publisherOrVenue: get(item, "data.publisher_or_venue"),
        externalVenueUrl: get(item, "data.external_venue_url.url"),
        link: get(item, "data.link.url"),
        thumbnail: get(item, "data.thumbnail"),
        artists: artists || EMPTY_ARRAY,
        artistsLabel: get(item, "data.artists_label"),
      });
    }, []);
  },
  fetchGlobalSettings(prismicClient, onSuccess) {
    prismicClient
      .getByUID("global_settings", "global_settings", {
        graphQuery: globalSettings,
      })
      .then((document) => {
        let mainNavLinks;
        let followLinks;

        if (Array.isArray(get(document, "data.main_nav_links"))) {
          mainNavLinks = get(document, "data.main_nav_links").reduce(
            (memo, link) => {
              if (get(link, "to")) {
                memo.push({
                  label: get(link, "label"),
                  to: get(link, "to"),
                  isTargetBlank: get(link, "is_target_blank"),
                });
              }
              return memo;
            },
            []
          );
        }

        if (Array.isArray(get(document, "data.follow_links"))) {
          followLinks = get(document, "data.follow_links").reduce(
            (memo, link) => {
              if (get(link, "link")) {
                memo.push({
                  label: get(link, "label"),
                  link: get(link, "link"),
                });
              }
              return memo;
            },
            []
          );
        }

        onSuccess({
          enquiriesEmailAddress: get(
            document,
            "data.enquiries_email_address",
            ""
          ),
          enquiriesInstructions: get(
            document,
            "data.enquiries_instructions",
            ""
          ),
          enquiriesDisclaimer: get(
            document,
            "data.enquiries_disclaimer",
            EMPTY_ARRAY
          ),
          followLinks: followLinks || EMPTY_ARRAY,
          mailchimpUserId: get(document, "data.mailchimp_user_id"),
          mailchimpListId: get(document, "data.mailchimp_list_id"),
          mainNavLinks: mainNavLinks || EMPTY_ARRAY,
          notFoundImage: get(document, "data.not_found_image"),
          notFoundBody: get(document, "data.not_found_body"),
          privacyPolicy: get(document, "data.privacy_policy"),
        });
      })
      .catch((e) => {
        console.error("[fetchGlobalSettings] Error:", e);
      });
  },
  fetchPageContent(prismicClient, slug, onSuccess) {
    prismicClient
      .getByUID("generic_page", slug, {
        graphQuery: genericPage,
      })
      .then((document) => {
        let blocks;
        if (Array.isArray(get(document, "data.blocks"))) {
          blocks = get(document, "data.blocks").reduce((memo, blockData) => {
            if (get(blockData, "block.id")) {
              if (get(blockData, "block.isBroken")) {
                console.error("[BROKEN Block]", blockData);
              } else {
                memo.push({
                  id: get(blockData, "block.id"),
                  type: get(blockData, "block.type"),
                  data: get(blockData, "block.data", EMPTY_OBJECT),
                });
              }
            }
            return memo;
          }, []);
        }

        onSuccess({
          id: get(document, "id"),
          title: get(document, "data.title"),
          blocks: blocks || EMPTY_ARRAY,
        });
      })
      .catch((e) => {
        console.error("[fetchPageContent] Error:", e);
      });
  },
  fetchShopPage(prismicClient, slug, onSuccess) {
    prismicClient
      .getByUID("shop_page", slug, {
        graphQuery: shopPage,
      })
      .then((document) => {
        let blocks;
        if (Array.isArray(get(document, "data.artworks"))) {
          blocks = document.data.artworks.reduce((arr, artwork) => {
            if (get(artwork, "artwork.uid")) {
              arr.push({
                uid: get(artwork, "artwork.uid"),
                type: "single_column_block",
                data: {
                  title: `Artwork as single_column_block ${get(
                    artwork,
                    "artwork.data.title"
                  )}`,
                  images: [
                    {
                      image: get(
                        artwork,
                        "artwork.data.thumnbnail",
                        get(artwork, "artwork.data.images[0].image")
                      ),
                      artwork: get(artwork, "artwork"),
                      caption: get(artwork, "caption"),
                      width: get(artwork, "width", "6"),
                    },
                  ],
                },
              });
            }
            return arr;
          }, []);
        }

        onSuccess({
          id: get(document, "id"),
          title: get(document, "data.title"),
          blocks: blocks || EMPTY_ARRAY,
        });
      })
      .catch((e) => {
        console.error("[fetchShopPage] Error:", e);
      });
  },
  fetchTags(prismicClient, onSuccess) {
    prismicClient
      .query(tags.query, { graphQuery: tags.options })
      .then((document) => {
        const response = get(document, "results", []).reduce((memo, tag) => {
          if (get(tag, "data.title")) {
            memo.push({
              id: get(tag, "id"),
              title: get(tag, "data.title"),
              isFilterable: get(tag, "data.is_filterable", false),
              order: get(tag, "data.order", null),
            });
          }
          return memo;
        }, []);
        onSuccess(response);
      });
  },
  fetchVenues(prismicClient, onSuccess) {
    prismicClient
      .query(venues.visible(), {
        graphQuery: venues.options,
        orderings: "[my.venue.order]",
      })
      .then((document) => {
        let response;
        if (Array.isArray(get(document, "results"))) {
          response = get(document, "results").map((venue) => {
            let dateLabel = "";
            if (
              get(venue, "data.featured_exhibition_update.data.display_date")
            ) {
              dateLabel =
                venue.data.featured_exhibition_update.data.display_date;
            } else if (
              get(venue, "data.featured_exhibition_update.data.start_date") &&
              get(venue, "data.featured_exhibition_update.data.end_date")
            ) {
              dateLabel = formatRange(
                venue.data.featured_exhibition_update.data.start_date,
                venue.data.featured_exhibition_update.data.end_date
              );
            } else if (
              get(venue, "data.featured_exhibition_update.data.start_date")
            ) {
              dateLabel = formatDate(
                venue.data.featured_exhibition_update.data.start_date
              );
            }
            return {
              id: get(venue, "id"),
              title: get(venue, "data.title"),
              owner: get(venue, "data.owner"),
              isHidden: get(venue, "data.is_hidden", false),
              isClosed: get(venue, "data.is_closed", false),
              closedMessage: get(venue, "data.closed_message"),
              phoneNumber: get(venue, "data.phone_number"),
              address: get(venue, "data.address"),
              hours: get(venue, "data.hours"),
              featuredExhibitionUpdate: {
                artistsLabel: get(
                  venue,
                  "data.featured_exhibition_update.data.artists_label"
                ),
                dateLabel,
                exhibitionUid: get(
                  venue,
                  "data.featured_exhibition_update.data.exhibition.uid"
                ),
                title: get(venue, "data.featured_exhibition_update.data.title"),
                thumbnail: get(
                  venue,
                  "data.featured_exhibition_update.data.thumbnail"
                ),
              },
            };
          });
        } else {
          response = EMPTY_ARRAY;
        }

        onSuccess(response);
      });
  },
};

export default Cms;
