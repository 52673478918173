/**
 * To learn more about HTML Serializer check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/html-serializer
 */

import prismicDOM from "prismic-dom";
import linkResolver from "./link-resolver";

const Elements = prismicDOM.RichText.Elements;

export default function (type, element, content, children) {
  switch (type) {
    case Elements.hyperlink: {
      // Generate links to Prismic Documents as <router-link> components
      // Present by default, it is recommended to keep this
      let result = "";
      const url = prismicDOM.Link.url(element.data, linkResolver);

      if (element.data.link_type === "Document") {
        return `<a class="Button--link" href="${url}">${content}</a>`;
      } else {
        const target = element.data.target
          ? `target="'${element.data.target}'" rel="noopener"`
          : "";
        result = `<a href="${url}" ${target}>${content}</a>`;
      }

      return result;
    }
    case Elements.image: {
      // If the image is also a link to a Prismic Document, it will return a <router-link> component
      // Present by default, it is recommended to keep this
      let result = `<img src="${element.url}" alt="${
        element.alt || ""
      }" copyright="${element.copyright || ""}">`;

      if (element.linkTo) {
        const url = prismicDOM.Link.url(element.linkTo, linkResolver);

        if (element.linkTo.link_type === "Document") {
          result = `<router-link to="${url}">${result}</router-link>`;
        } else {
          const target = element.linkTo.target
            ? `target="${element.linkTo.target}" rel="noopener"`
            : "";
          result = `<a href="${url}" ${target}>${result}</a>`;
        }
      }
      const wrapperClassList = [element.label || "", "block-img"];
      result = `<p class="${wrapperClassList.join(" ")}">${result}</p>`;
      return result;
    }
    case Elements.heading1: {
      const content = element.text === "" ? "&nbsp;" : children.join("");
      return `<h1>${content}</h1>`;
    }
    case Elements.heading2: {
      const content = element.text === "" ? "&nbsp;" : children.join("");
      return `<h2>${content}</h2>`;
    }
    case Elements.heading3: {
      const content = element.text === "" ? "&nbsp;" : children.join("");
      return `<h3>${content}</h3>`;
    }
    case Elements.heading4: {
      const content = element.text === "" ? "&nbsp;" : children.join("");
      return `<h4>${content}</h4>`;
    }
    case Elements.paragraph: {
      const content = element.text === "" ? "&nbsp;" : children.join("");
      return `<p>${content}</p>`;
    }
    default: {
      // Return null to stick with the default behavior for everything else
      return null;
    }
  }
}
