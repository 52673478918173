<template>
  <div class="Exhibition displace-nav flex-1 flex flex-col" :style="cssVars">
    <Navigation isTransparent v-if="!playButtonDisabled" />
    <header
      v-if="loaded"
      class="Exhibition__header flex flex-col items-start spacer-bottom-2 wrapper-3 spacer-top-1"
    >
      <h1 class="title">{{ artistNames }}</h1>
      <h2 class="title italic">{{ exhibition.title }}</h2>
      <div class="Exhibition__text flex flex-col">
        <h3 v-if="exhibition.venueTitle" class="subheading sans-serif">
          <span v-if="exhibition.venueOwner"
            >{{ exhibition.venueOwner }},
          </span>
          {{ exhibition.venueTitle }}
        </h3>
        <a
          v-else-if="exhibition.externalVenueUrl && exhibition.publisherOrVenue"
          v-bind:href="exhibition.externalVenueUrl"
          target="_blank"
          rel="noreferrer"
          class="Button--link flex items-center"
        >
          <h3 class="subheading sans-serif">
            {{ exhibition.publisherOrVenue }}
          </h3>
          <img
            alt="arrow symbol"
            class="Exhibition__icon rotate-45"
            src="../assets/icon/arrow.svg"
          />
        </a>
        <h3
          v-else-if="exhibition.publisherOrVenue"
          class="subheading sans-serif"
        >
          {{ exhibition.publisherOrVenue }}
        </h3>

        <h3 class="subheading sans-serif">{{ dateLabel }}</h3>
      </div>
      <a
        v-if="exhibition.pdf"
        v-bind:href="exhibition.pdf"
        target="_blank"
        rel="noreferrer"
        class="Button--primary mt1 py_25 px1_25 small sans-serif uppercase"
      >
        Download Pdf
      </a>
    </header>
    <main v-if="loaded" class="Exhibition__body flex flex-1 border-bottom">
      <div class="flex flex-col relative">
        <div
          class="Exhibition__button flex flex-col items-center justify-center z2"
        >
          <router-link
            v-if="!playButtonDisabled && blocks && blocks.length > 0"
            :to="{
              path: `/exhibitions/${uid}/slideshow`,
              query: {
                slide: '0',
                mobile: $mq === 'mobile',
              },
            }"
            class="Button--play"
          >
            Start Viewing
          </router-link>
        </div>
        <BlockSwitch
          v-bind:blocks="blocks"
          v-bind:baseUrl="`/exhibitions/${this.uid}`"
          ref="exhibitionBlockSwitch"
        />
      </div>
    </main>
    <ArtworksTable
      v-if="exhibition.artworks && exhibition.artworks.length > 0"
      v-bind:artworks="exhibition.artworks"
      v-bind:baseUrl="`/exhibitions/${this.uid}`"
    />
    <ArtistsTable
      v-id="artistUids && artistUids.length > 0"
      v-bind:artistUids="artistUids"
      v-bind:externalArtists="exhibition.externalArtists"
    />
    <PressUpdatesTable
      v-bind:isLoading="!exhibition"
      v-bind:updates="exhibition.pressUpdates"
    />
    <Footer />
  </div>
</template>

<script>
import get from "lodash/get";

import ArtistsTable from "../components/table/ArtistsTable";
import ArtworksTable from "../components/table/ArtworksTable";
import PressUpdatesTable from "../components/table/PressUpdatesTable";
import Footer from "./Footer";
import Navigation from "./Navigation";

import { formatDate, formatRange } from "../utils/date";

export default {
  name: "Exhibition",
  components: {
    ArtistsTable,
    ArtworksTable,
    PressUpdatesTable,
    Footer,
    Navigation,
  },
  props: {
    uid: String,
    playButtonDisabled: Boolean,
  },
  data() {
    return {
      scrolledToFooter: false,
    };
  },
  mounted() {
    this.$store.commit("setMainNavIsOpen", false);
    this._onScrollOrResize();
    window.addEventListener("scroll", this._onScrollOrResize);
    window.addEventListener("resize", this._onScrollOrResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this._onScrollOrResize);
    window.removeEventListener("resize", this._onScrollOrResize);
  },
  methods: {
    _onScrollOrResize() {
      if (!this.$refs.exhibitionBlockSwitch) return;
      const blockSwitch = this.$refs.exhibitionBlockSwitch.$el.getBoundingClientRect();
      this.scrolledToFooter = window.innerHeight >= blockSwitch.bottom;
    },
  },
  computed: {
    artistNames() {
      return this.$store.getters.exhibitionArtistNames(this.uid);
    },
    artistIds() {
      return get(
        this.$store.getters.exhibition(this.uid),
        "artists",
        []
      ).map((artist) => get(artist, "id"));
    },
    artistUids() {
      return get(
        this.$store.getters.exhibition(this.uid),
        "artists",
        []
      ).map((artist) => get(artist, "uid"));
    },
    blocks() {
      return this.$store.getters.exhibitionBlocks(this.uid);
    },
    cssVars() {
      return {
        "--button-position": this.scrolledToFooter ? "absolute" : "fixed",
      };
    },
    dateLabel() {
      if (this.exhibition.displayDate) {
        return this.exhibition.displayDate;
      }
      if (this.exhibition.startDate && this.exhibition.endDate) {
        return formatRange(this.exhibition.startDate, this.exhibition.endDate);
      }
      if (this.exhibition.startDate)
        return formatDate(this.exhibition.startDate);
      if (this.exhibition.endDate) return formatDate(this.exhibition.endDate);
      return "";
    },
    exhibition() {
      return this.$store.getters.exhibition(this.uid);
    },
    loaded() {
      return !!this.exhibition && !!this.exhibition.id;
    },
    tagId() {
      return get(this.$store.getters.tagByName("Press"), "id");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.Exhibition {
  min-height: 100vh;
  &__button {
    bottom: $mobile-button-height;
    left: 50%;
    transform: translateX(-50%);
    position: var(--button-position);
  }
  &__icon {
    width: $mobile-icon-sm;
    margin-left: 1.5 * $mobile-icon-xs;
    margin-top: $mobile-icon-xs;
  }
  &__text {
    margin-top: 30 * $mobile-sizing-coefficient;
    margin-bottom: 2 * $mobile-margin;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .Exhibition {
    &__button {
      bottom: $desk-button-height;
    }
    &__header {
      padding-top: 4 * $desk-margin;
    }
    &__icon {
      width: $desk-icon-sm;
      margin-left: 1.5 * $desk-icon-xs;
      margin-top: $desk-icon-xs;
    }
    &__text {
      margin-top: 30 * $desk-sizing-coefficient;
      margin-bottom: 1 * $desk-margin;
    }
  }
}
</style>
