const _columnBlockFields = `{
  title
  images {
    artwork {
      uid
      title
      year
      details
    }
    caption
    image
    width
  }
}`;
const _videoBlockFields = `{
  title
  sources
  caption
  artwork {
    title
    year
    details
    artist
  }
}`;

export default {
  options: `{
    artist {
      first_name
      last_name
      blocks {
        block {
          ...on single_column_block ${_columnBlockFields}
          ...on double_column_block ${_columnBlockFields}
          ...on triple_column_block ${_columnBlockFields}
          ...on video_block ${_videoBlockFields}
        }
      }
    }
  }`,
};
