<template>
  <div class="SlideshowControls flex flex-col flex-1" :style="cssVars">
    <div
      v-if="$mq === 'desktop'"
      class="SlideshowControls__buttons flex justify-center transition-opacity"
    >
      <Button
        v-if="isPlaying"
        class="Button--pause"
        v-bind:onPress="onPause"
        v-bind:disabled="!showButtons"
      >
        Pause
      </Button>
      <Button
        v-else
        class="Button--play mr_5"
        v-bind:onPress="onPlay"
        v-bind:disabled="!showButtons"
      >
        Resume
      </Button>
      <router-link v-if="!isPlaying" class="Button--x ml_5" v-bind:to="baseUrl">
        Exit
      </router-link>
    </div>
    <div class="flex flex-1">
      <router-link
        v-for="slide in slides"
        v-bind:key="`${slide.id}__${slide.columnNumber}`"
        :to="{
          path: `${baseUrl}/slideshow`,
          query: {
            slide: slide.index || 0,
            mobile: $mq === 'mobile',
          },
        }"
        class="flex-1 mr_5"
      >
        <div class="SlideshowControls__bar bg-color-white-0_5 my_5 relative">
          <div
            v-if="slide && activeSlide && slide.index < activeSlide.index"
            class="SlideshowControls__bar--fill bg-color-white absolute z-3 t0 b0 l0 r0"
          />
          <div
            v-if="slide && activeSlide && slide.index === activeSlide.index"
            class="SlideshowControls__progress-bar transition-right bg-color-white absolute z-3 t0 b0 l0"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "./Button";
export default {
  name: "SlideshowControls",
  components: {
    Button,
  },
  props: {
    activeSlide: {
      id: String,
      index: Number,
    },
    baseUrl: String,
    isPlaying: Boolean,
    progress: Number /* between 0 and 1 */,
    onPause: Function,
    onPlay: Function,
    slides: Array,
    showButtons: Boolean,
  },
  computed: {
    cssVars() {
      return {
        "--button-opacity": this.showButtons ? 1 : 0,
        "--progress-bar-right": `${this.progressPercent}%`,
      };
    },
    progressPercent() {
      if (this.$mq === "mobile") return 33;
      const progress = 100 - this.progress;
      return Math.max(progress, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.SlideshowControls {
  &__bar {
    height: 1 * $desk-sizing-coefficient;
    min-width: 10 * $desk-sizing-coefficient;

    &__fill {
      height: 1 * $desk-sizing-coefficient;
      min-width: 10 * $desk-sizing-coefficient;
    }
  }
  &__progress-bar {
    right: var(--progress-bar-right);
  }

  &__buttons {
    opacity: var(--button-opacity);
  }
}
</style>
