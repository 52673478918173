<template>
  <div class="ExhibitionSlideSwitch flex flex-col flex-1" :style="cssVars">
    <TitleSlide
      v-if="activeSlide && activeSlide.id === 'title'"
      v-bind:title="artistNames"
      v-bind:subtitle="exhibition.title"
      v-bind:copy="venueTitle"
      v-bind:detail="dateLabel"
      v-bind:onClick="onToggle"
      class="transition-opacity-long ExhibitionSlideSwitch__slide"
    />
    <TextSlide
      v-else-if="
        !!activeSlide &&
        !!activeSlide.block &&
        activeSlide.block.type === 'text_block'
      "
      v-bind:data="activeSlide.block.data"
      v-bind:isPlaying="isPlaying"
      v-bind:onClick="onToggle"
      class="transition-opacity-long ExhibitionSlideSwitch__slide"
    />
    <MobileImageSlide
      v-else-if="
        $mq === 'mobile' &&
        activeSlide &&
        activeSlide.block &&
        (activeSlide.block.type === 'single_column_block' ||
          activeSlide.block.type === 'double_column_block' ||
          activeSlide.block.type === 'triple_column_block')
      "
      v-bind:columnNumber="columnNumber"
      v-bind:data="activeSlide.block.data"
      v-bind:isPlaying="isPlaying"
      v-bind:type="activeSlide.block.type"
      v-bind:onClick="onToggle"
      class="transition-opacity-long ExhibitionSlideSwitch__slide"
    />
    <ImageSlide
      v-else-if="
        activeSlide &&
        activeSlide.block &&
        activeSlide.block.type === 'single_column_block'
      "
      v-bind:type="activeSlide.block.type"
      v-bind:data="activeSlide.block.data"
      v-bind:isPlaying="isPlaying"
      v-bind:onClick="onToggle"
      class="transition-opacity-long ExhibitionSlideSwitch__slide"
    />
    <DoubleImageSlide
      v-else-if="
        activeSlide &&
        activeSlide.block &&
        activeSlide.block.type === 'double_column_block'
      "
      v-bind:type="activeSlide.block.type"
      v-bind:data="activeSlide.block.data"
      v-bind:isPlaying="isPlaying"
      v-bind:onClick="onToggle"
      class="transition-opacity-long ExhibitionSlideSwitch__slide"
    />
    <TripleImageSlide
      v-else-if="
        activeSlide &&
        activeSlide.block &&
        activeSlide.block.type === 'triple_column_block'
      "
      v-bind:type="activeSlide.block.type"
      v-bind:data="activeSlide.block.data"
      v-bind:isPlaying="isPlaying"
      v-bind:onClick="onToggle"
      class="transition-opacity-long ExhibitionSlideSwitch__slide"
    />
    <VideoSlide
      v-else-if="
        activeSlide &&
        activeSlide.block &&
        activeSlide.block.type === 'video_block'
      "
      v-bind:type="activeSlide.block.type"
      v-bind:data="activeSlide.block.data"
      v-bind:isPlaying="isPlaying"
      v-bind:didReceiveDuration="setSlideDuration"
      v-bind:onClick="onToggle"
      class="transition-opacity-long ExhibitionSlideSwitch__slide"
    />
  </div>
</template>

<script>
import ImageSlide from "./ImageSlide";
import DoubleImageSlide from "./DoubleImageSlide";
import TripleImageSlide from "./TripleImageSlide";
import MobileImageSlide from "./MobileImageSlide";
import TextSlide from "./TextSlide";
import TitleSlide from "./TitleSlide";
import VideoSlide from "./VideoSlide";

import { formatRange } from "../../utils/date";

export default {
  name: "ExhibitionSlideSwitch",
  components: {
    ImageSlide,
    DoubleImageSlide,
    TripleImageSlide,
    MobileImageSlide,
    TextSlide,
    TitleSlide,
    VideoSlide,
  },
  props: {
    activeSlide: {
      id: String,
      index: Number,
      block: {
        id: String,
        type: String,
        data: Object,
      },
    },
    columnNumber: Number,
    exhibition: Object,
    isLoading: Boolean,
    isPlaying: Boolean,
    isTransitioning: Boolean,
    onToggle: Function,
    setSlideDuration: Function,
    uid: String,
  },
  computed: {
    artistNames() {
      if (this.isLoading) return "";
      return this.$store.getters.exhibitionArtistNames(this.uid);
    },
    cssVars() {
      return {
        "--slide-opacity": this.isTransitioning ? 0 : 1,
      };
    },
    dateLabel() {
      if (this.isLoading) return "";
      return formatRange(this.exhibition.startDate, this.exhibition.endDate);
    },
    venueTitle() {
      if (this.isLoading) return "";
      if (!this.exhibition.venueTitle) return "";
      return `${
        this.exhibition.venueOwner ? `${this.exhibition.venueOwner} ` : ""
      }${this.exhibition.venueTitle}`;
    },
  },
};
</script>

<style lang="scss">
.ExhibitionSlideSwitch {
  &__slide {
    opacity: var(--slide-opacity);
  }
}
</style>
