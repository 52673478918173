<template>
  <div class="BlockSwitch vw100 flex flex-1 flex-col">
    <div
      class="flex flex-col justify-start"
      v-for="block in blocks"
      v-bind:key="block.id"
    >
      <Hero v-if="block.type === 'hero_block'" v-bind:data="block.data" />
      <SingleColumnBlock
        v-else-if="block.type === 'single_column_block'"
        v-bind:data="block.data"
        v-bind:baseUrl="_baseUrl"
        v-bind:onSelectArtwork="onSelectArtwork"
      />
      <DoubleColumnBlock
        v-else-if="block.type === 'double_column_block'"
        v-bind:data="block.data"
        v-bind:baseUrl="_baseUrl"
        v-bind:onSelectArtwork="onSelectArtwork"
      />
      <TripleColumnBlock
        v-else-if="block.type === 'triple_column_block'"
        v-bind:data="block.data"
        v-bind:baseUrl="_baseUrl"
        v-bind:onSelectArtwork="onSelectArtwork"
      />
      <TextBlock
        v-else-if="block.type === 'text_block'"
        v-bind:data="block.data"
      />
      <Table
        v-else-if="block.type === 'table_block'"
        v-bind:data="block.data"
      />
      <VideoBlock
        v-else-if="block.type === 'video_block'"
        v-bind:data="block.data"
        v-bind:baseUrl="_baseUrl"
        v-bind:onSelectArtwork="onSelectArtwork"
        class="spacer-bottom-3"
      />
      <div v-else>{{ block.type }}</div>
    </div>
  </div>
</template>

<script>
import Hero from "./Hero";
import SingleColumnBlock from "./SingleColumnBlock";
import DoubleColumnBlock from "./DoubleColumnBlock";
import TripleColumnBlock from "./TripleColumnBlock";
import TextBlock from "./TextBlock";
import Table from "./table";
import VideoBlock from "./VideoBlock";
export default {
  name: "BlockSwitch",
  components: {
    Hero,
    SingleColumnBlock,
    DoubleColumnBlock,
    TripleColumnBlock,
    TextBlock,
    Table,
    VideoBlock,
  },
  props: {
    blocks: Array,
    baseUrl: String,
    onSelectArtwork: Function,
  },
  computed: {
    _baseUrl() {
      if (this.baseUrl) return this.baseUrl;
      return "";
    },
  },
};
</script>
