function validateString(str) {
  return !!str;
}

const emailRexexp = RegExp(/\S+@\S+\.\S+/);
function validateEmail(str) {
  return emailRexexp.test(str);
}

function validate(val, type) {
  switch (type) {
    case "email":
      return validateEmail(val);
    case "string": {
      return validateString(val);
    }
    case "boolean": {
      return !!val;
    }
    default:
      // If no validator, assume any value is fine
      return true;
  }
}

export default validate;
