var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"page",staticClass:"PrivateViewingSlideshow overlay--fixed z3 overflow-hidden color-white bg-color-black flex flex-col flex-1"},[(!_vm.playback.isPlaying || _vm.interactions.mouseRecentlyMoved)?_c('router-link',{staticClass:"flex flex-col absolute t0 r0 z4",attrs:{"to":_vm.baseUrl,"aria-label":"end slideshow"}},[_c('img',{staticClass:"PrivateViewingSlideshow__icon transition-opacity",attrs:{"src":require("../assets/icon/close-thin-white.svg")}})]):_vm._e(),_c('PrivateViewingSlideSwitch',{attrs:{"activeSlide":_vm.activeSlide,"columnNumber":_vm.parsedColumnNumber,"privateViewing":_vm.privateViewing,"isPlaying":_vm.playback.isPlaying,"isTransitioning":_vm.isTransitioning,"onToggle":_vm.onToggle,"setSlideDuration":_vm.setSlideDuration,"uid":_vm.uid}}),_c('SlideshowControls',{staticClass:"PrivateViewingSlideshow__progress absolute b0 z4",attrs:{"slides":_vm.slides,"activeSlide":_vm.activeSlide,"showButtons":_vm.interactions.mouseRecentlyMoved,"baseUrl":_vm.baseUrl,"onPause":_vm.onPause,"onPlay":_vm.onPlay,"isPlaying":_vm.playback.isPlaying,"progress":_vm.playbackProgress}}),(_vm.$mq === 'desktop' && !_vm.playback.isPlaying && _vm.activeSlideIndex > 0)?_c('router-link',{staticClass:"PrivateViewingSlideshow__arrow-button transition-opacity flex flex-col absolute l0 justify-center items-start p1_5",attrs:{"to":{
      path: (_vm.baseUrl + "/slideshow"),
      query: { slide: _vm.activeSlideIndex - 1 },
    }}},[_c('img',{staticClass:"rotate-270",attrs:{"src":require("../assets/icon/arrow-long-white.svg")}})]):_vm._e(),(
      _vm.$mq === 'desktop' &&
      !_vm.playback.isPlaying &&
      _vm.activeSlideIndex + 1 < _vm.slides.length
    )?_c('router-link',{staticClass:"PrivateViewingSlideshow__arrow-button flex flex-col absolute r0 justify-center items-end p1_5",attrs:{"to":{
      path: (_vm.baseUrl + "/slideshow"),
      query: { slide: _vm.activeSlideIndex + 1 },
    }}},[_c('img',{staticClass:"rotate-90",attrs:{"src":require("../assets/icon/arrow-long-white.svg")}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }