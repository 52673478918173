<template>
  <div class="PrivateViewingPage flex flex-1 flex-col container">
    <PrivateViewingPasswordForm
      v-if="
        stage === 'REQUEST_VIEWING_IDLE' ||
        stage === 'REQUEST_VIEWING_PENDING' ||
        stage === 'REQUEST_VIEWING_FAILURE'
      "
      v-bind:isMakingRequest="stage === 'REQUEST_VIEWING_PENDING'"
      v-bind:onSubmit="onSubmit"
      v-bind:uid="uid"
      v-bind:hasError="stage === 'REQUEST_VIEWING_FAILURE'"
    />
    <PrivateViewing
      v-if="stage === 'REQUEST_VIEWING_SUCCESS'"
      v-bind:uid="uid"
      v-bind:playButtonDisabled="showSlideshow || showArtwork"
    />
    <div
      v-if="stage === 'REQUEST_VIEWING_SUCCESS' && showArtwork"
      class="overlay--fixed z3"
    >
      <ArtworkDetailPage
        v-bind:uid="artworkUid"
        v-bind:disableEnquiry="true"
        v-bind:baseUrl="`/private-viewings/${this.uid}`"
      />
    </div>
    <PrivateViewingSlideshow
      v-else-if="stage === 'REQUEST_VIEWING_SUCCESS' && showSlideshow"
      v-bind:columnNumber="columnNumber"
      v-bind:slide="slide"
      v-bind:uid="uid"
    />
  </div>
</template>

<script>
import PrivateViewing from "../components/PrivateViewing";
import PrivateViewingPasswordForm from "../components/forms/PrivateViewingPasswordForm";
import PrivateViewingSlideshow from "../components/PrivateViewingSlideshow";
import ArtworkDetailPage from "./ArtworkDetailPage";
const Stages = {
  REQUEST_VIEWING_IDLE: "REQUEST_VIEWING_IDLE",
  REQUEST_VIEWING_PENDING: "REQUEST_VIEWING_PENDING",
  REQUEST_VIEWING_FAILURE: "REQUEST_VIEWING_FAILURE",
  REQUEST_VIEWING_SUCCESS: "REQUEST_VIEWING_SUCCESS",
};

export default {
  name: "PrivateViewingPage",
  components: {
    ArtworkDetailPage,
    PrivateViewing,
    PrivateViewingPasswordForm,
    PrivateViewingSlideshow,
  },
  props: {
    artworkUid: String,
    columnNumber: String,
    showArtwork: Boolean,
    showSlideshow: Boolean,
    slide: String,
    uid: String,
  },
  data() {
    return {
      stage: Stages.REQUEST_VIEWING_IDLE,
    };
  },
  watch: {
    uid() {
      /* When the page slug changes */
      this.$store.commit("setMainNavIsOpen", false);
      this.scrollToTop();
      this.stage = this._getInitialStage();
    },
  },
  mounted() {
    this.$store.commit("setMainNavIsOpen", false);
    this.scrollToTop();
    this.stage = this._getInitialStage();
  },
  methods: {
    onSubmit(password) {
      if (this.stage === Stages.REQUEST_VIEWING_PENDING) return;
      try {
        this._fetchPageContent(this.uid, password);
      } catch (e) {
        this.stage = Stages.VALIDATION_FAILURE;
      }
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    _didLoad(data) {
      this.stage =
        data && data.id
          ? Stages.REQUEST_VIEWING_SUCCESS
          : Stages.REQUEST_VIEWING_FAILURE;
    },
    _fetchPageContent(uid, password) {
      this.stage = Stages.REQUEST_VIEWING_PENDING;
      this.$store.commit("fetchAndPersistPrivateViewing", {
        prismicClient: this.$prismic.client,
        uid,
        password,
        didLoad: this._didLoad,
      });
    },
    _getInitialStage() {
      if (this.uid && this.$store.getters.privateViewing(this.uid).id) {
        return Stages.REQUEST_VIEWING_SUCCESS;
      } else {
        return Stages.REQUEST_VIEWING_IDLE;
      }
    },
  },
};
</script>

<style lang="scss">
.PrivateViewingPage {
  min-height: 100vh;
}
</style>
