<template>
  <div class="ImageGallery" ref="gallery">
    <GentleLoader
      v-if="$mq === 'mobile' && currentSlide"
      class="ImageGallery--mobile flex flex-col items-center justify-center flex-1"
      v-bind:preloadContent="preloadCurrentMobileSlide"
      v-bind:key="currentSlideIndex"
    >
      <prismic-image
        v-if="currentSlide && currentSlide.type === 'image'"
        class="ImageGallery__image"
        :field="currentSlide.mobileData"
      />
      <RichText
        v-else-if="currentSlide && currentSlide.type === 'text'"
        className="subheading serif wrapper-1 text-center"
        :field="currentSlide.data"
      />
      <VideoBlock
        v-else-if="currentSlide && currentSlide.type === 'video'"
        v-bind:data="currentSlide.data"
      />
    </GentleLoader>
    <GentleLoader
      v-if="$mq === 'desktop' && currentSlide"
      v-bind:preloadContent="preloadCurrentSlide"
      class="ImageGallery__image-container flex flex-col justify-center items-center absolute"
      v-bind:key="currentSlideIndex"
    >
      <prismic-image
        v-if="currentSlide && currentSlide.type === 'image'"
        class="ImageGallery__image"
        :field="currentSlide.data"
      />
      <RichText
        v-else-if="currentSlide && currentSlide.type === 'text'"
        className="subheading serif col-6"
        :field="currentSlide.data"
      />
      <div
        v-else-if="currentSlide && currentSlide.type === 'video'"
        class="bg-color-black flex-1 flex flex-col justify-center"
      >
        <VideoBlock
          v-bind:data="currentSlide.data"
          class="ImageGallery__video-block"
        />
      </div>
    </GentleLoader>
    <Button
      v-if="$mq === 'desktop' && hasMultipleSlides"
      v-bind:onPress="showPrevious"
      class="ImageGallery__arrow-button transition-opacity flex flex-col absolute l0 justify-center items-start p1_5"
    >
      <img class="rotate-270" src="../assets/icon/arrow-long.svg" />
    </Button>
    <Button
      v-if="$mq === 'desktop' && hasMultipleSlides"
      v-bind:onPress="showNext"
      class="ImageGallery__arrow-button transition-opacity flex flex-col absolute r0 justify-center items-end p1_5"
    >
      <img class="rotate-90" src="../assets/icon/arrow-long.svg" />
    </Button>
    <div
      class="ImageGallery__loader flex items-center justify-center"
      v-if="isNoticeable && isLoading"
    >
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import Swipeable from "../utils/swipeable";

import Button from "./Button";
import GentleLoader from "./loaders/GentleLoader";
import RichText from "./RichText";
import VideoBlock from "./VideoBlock";

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const ESCAPE = 27;
const NOTICEABILITY_THRESHOLD = 750;

export default {
  name: "ImageGallery",
  components: {
    Button,
    GentleLoader,
    RichText,
    VideoBlock,
  },
  data() {
    return {
      isLoading: true,
      isNoticeable: false,
      swipeable: null,
    };
  },
  mounted() {
    window.addEventListener("keydown", this._didPressKey);
    this.swipeable = new Swipeable(
      this.$refs.gallery,
      this._didSwipe,
      this._didScrollY
    );
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this._didPressKey);
    this.swipeable.teardown();
  },
  props: {
    containerRef: HTMLDivElement || undefined,
    currentSlideIndex: {
      type: Number,
      default: 0,
    },
    slides: Array,
    onClose: Function,
    showPrevious: Function,
    showNext: Function,
  },
  computed: {
    currentSlide() {
      return this.slides[this.currentSlideIndex];
    },
    hasMultipleSlides() {
      return this.slides && this.slides.length > 1;
    },
  },
  methods: {
    _didPressKey(event) {
      if (event.keyCode === LEFT_ARROW) this.showPrevious();
      if (event.keyCode === RIGHT_ARROW) this.showNext();
      if (event.keyCode === ESCAPE) this.onClose();
    },
    _didSwipe(direction /* difference */) {
      if (direction < 0) {
        this.showNext();
      } else {
        this.showPrevious();
      }
    },
    _animateScroll(element, to, duration) {
      if (!element) return;
      let start = element.scrollTop;
      let change = to - start;
      let currentTime = 0;
      let increment = 20;

      function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }

      function animateScroll() {
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      }
      animateScroll();
    },
    _didScrollY(difference) {
      if (!this.containerRef) return;
      if (difference > 0) {
        this._animateScroll(
          this.containerRef,
          this.containerRef.offsetHeight,
          350
        );
      } else {
        this._animateScroll(this.containerRef, 0, 350);
      }
    },
    _resetLoadingState() {
      this.isNoticeable = false;
      this.isLoading = true;
      setTimeout(() => {
        this.isNoticeable = true;
      }, NOTICEABILITY_THRESHOLD);
    },
    preloadCurrentSlide(_didLoad) {
      const didLoad = () => {
        this.isLoading = false;
        _didLoad();
      };
      if (
        get(this.currentSlide, "type") === "image" &&
        get(this.currentSlide, "data.url")
      ) {
        this._resetLoadingState();
        const loader = new window.Image();
        loader.src = "";
        loader.onload = didLoad;
        loader.src = get(this.currentSlide, "data.url");
      } else {
        didLoad();
      }
    },
    preloadCurrentMobileSlide(_didLoad) {
      const didLoad = () => {
        this.isLoading = false;
        _didLoad();
      };
      if (
        get(this.currentSlide, "type") === "image" &&
        get(this.currentSlide, "mobileData.url")
      ) {
        this._resetLoadingState();
        const loader = new window.Image();
        loader.src = "";
        loader.onload = didLoad;
        loader.src = get(this.currentSlide, "mobileData.url");
      } else {
        didLoad();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styleguide/_sizing.scss";
.ImageGallery {
  &--mobile {
    overflow: hidden;
  }
  &__image {
    max-height: 100%;
    max-width: 100%;
  }
  &__loader {
    height: 60vh;
    width: 100vw;
  }
}
@media (min-width: map-get($breakpoints, "md")) {
  .ImageGallery {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    &__arrow-button {
      width: 80 * $desk-sizing-coefficient;
      height: 50vh;
      top: 25vh;
      img {
        max-width: 32 * $desk-sizing-coefficient;
        max-height: 32 * $desk-sizing-coefficient;
        height: 32 * $desk-sizing-coefficient;
        width: 32 * $desk-sizing-coefficient;
      }
      &:hover {
        opacity: 0.2;
      }
    }
    &__image {
      max-height: 100vh;
    }
    &__loader {
      height: 100vh;
    }
    &__image-container {
      height: 100vh;
      width: calc(9.5 / 12 * 100vw);
      left: calc(1.25 / 12 * 100vw);
      right: calc(1.25 / 12 * 100vw);
    }

    &__video-block {
      flex: 0;
    }
  }
}
</style>
